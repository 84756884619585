<template>
  <div class="profile-reviews">
    <div class="profile-reviews__content" v-if="!isEmpty">
      <h2 class="profile-reviews__h2">Отзывы</h2>
      <q-btn @click="createReview"
             v-if="($auth.canAny(['fisherman', 'guest']) && !isFisherman) || isOwner"
             class="profile-reviews__btn btn btn-s btn-secondary" label="Написать отзыв" :ripple="false"/>

      <div class="profile-reviews__grades" v-if="isGuide">
        <review-grade name="Улов" :grade="stats.catch_rating" />
        <review-grade name="Атмосфера" :grade="stats.atmosphere_rating" />
        <review-grade name="Еда" :grade="stats.meal_rating" />
        <review-grade name="Снасти" :grade="stats.gear_rating" />
        <review-grade name="Техника" :grade="stats.technic_rating" />
      </div>

      <masonry-wall v-if="reviews.length" :items="reviews" :ssr-columns="1" :column-width="365" :gap="20">
        <template #default="{ item }">
          <review-card
              :name="item.author.full_name"
              :date="item.created_at_iso"
              :title="item.title"
              :text="item.text"
              :cover="item.preview.medium ? item.preview.medium : null"
              :avatar="item.author.avatar"
              :initial="item.author.name ? item.author.name.charAt(0).toUpperCase() : ''"
              :rating="item.total_rating_int"
              :ratio="476/363"
          />
        </template>
      </masonry-wall>
      <div v-if="false" class="column reviews-grid">
        <div class="review-cell" tabindex="0" v-for="review in reviews" :key="review.id">
          <review-card
              :name="review.author.full_name"
              :date="review.created_at_iso"
              :title="review.title"
              :text="review.text"
              :cover="review.preview.medium ? review.preview.medium : null"
              :avatar="review.author.avatar"
              :initial="review.author.name ? review.author.name.charAt(0).toUpperCase() : ''"
              :rating="review.total_rating_int"
              :ratio="476/363"
          />
        </div>
      </div>
    </div>

    <div class="reviews-empty" v-if="isEmpty">
      <img class="reviews-empty__img" src="../assets/images/icons/review.svg" alt="" />
      <h2 class="reviews-empty__title">Отзывы</h2>
      <div class="reviews-empty__text">
        <span v-if="!isEdit">Напишите отзыв о рыбалке c гидом, поделитесь впечатлениями</span>
        <span v-if="isEdit">У Вас еще нет отзывов</span>
      </div>
      <q-btn @click="createReview" class="btn btn-primary btn-m" v-if="$auth.canAny(['fisherman'])">
        <q-icon name="add" />
        <span>Написать</span>
      </q-btn>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import MasonryWall from '@yeger/vue-masonry-wall';
import ReviewCard from "components/ReviewCard";
import ReviewGrade from "components/ReviewGrade";

export default defineComponent({
  name: "ProfileReviews",
  components: {
    ReviewCard,
    ReviewGrade,
    MasonryWall,
  },
  props: [
      'isEmpty',
      'isEdit',
      'isGuide',
      'reviews',
      'stats',
      'isFisherman',
      'isOwner',
      'guideId'
  ],
  methods: {
    createReview() {
      this.sendYM('target7-1')
      this.$router.push({name: !this.isFisherman ? 'review-form' : 'review-form-fisherman', params: !this.isFisherman ? {guideId: this.$route.params.idOrSlug} : {}})
    },
    sendYM(name) {
      if (typeof ym !== undefined) {
        ym(45019072, 'reachGoal', name)
      }
    },
    setContainerHeight: function () {
      let height = 0;
      const cells = document.querySelectorAll('.review-cell');
      const container = document.querySelector('.reviews-grid');
      let maxCellHeight = 0;

      if (cells && container) {
        cells.forEach(cell => {
          if (cell.offsetHeight > maxCellHeight) {
            maxCellHeight = cell.offsetHeight;
          }

          height += cell.offsetHeight;
        })

        if (window.innerWidth >= 600 && cells.length >= 2) {
          container.style.height = height / 1.8 > maxCellHeight ? height / 1.8 + 'px' : maxCellHeight + 'px';
        } else {
          container.style.height = null;
        }
      }
    }
  },
  // watch: {
  //   stats(to, from) {
  //     this.setContainerHeight();
  //     window.addEventListener('resize', this.setContainerHeight);
  //   },
  // },
  async mounted() {
    // this.setContainerHeight();
    // window.addEventListener('resize', this.setContainerHeight);
  }
});
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.reviews-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: $border;
  border-radius: $border-radius;
  padding: 20px;

  @media #{$desktop-widths} {
    padding: 32px 32px 48px;
  }
}

.reviews-empty__img {
  margin-bottom: 16px;
}

.reviews-empty__title {
  margin-bottom: 8px;
}

.reviews-empty__text {
  margin-bottom: 24px;
  text-align: center;
  color: $text-color-secondary;
  @include font(16px, 24px);

  @media #{$tablet-widths} {
    @include font(18px, 26px);
  }

  @media #{$desktop-widths} {
    @include font(20px, 28px);
  }
}

.profile-reviews {
  margin-bottom: 40px;
  position: relative;

  @media #{$tablet-widths} {
    margin-bottom: 48px;
  }

  @media #{$desktop-widths} {
    margin-bottom: 80px;
  }
}

.profile-reviews__grades {
  margin-bottom: 24px;

  @media #{$desktop-l-widths} {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .review-grade {
    @media #{$desktop-l-widths} {
      width: calc(50% - 22px);
      margin-bottom: 20px;
    }

    & + .review-grade {
      margin-top: 12px;

      @media #{$desktop-l-widths} {
        margin-top: 0;
      }
    }
  }
}

.profile-reviews__h2 {
  margin-bottom: 24px;

  @media #{$tablet-widths} {
    margin-bottom: 32px;
  }
}

.profile-reviews__btn {
  position: absolute;
  right: 0;
  top: 0;

  @media #{$tablet-widths} {
    height: 56px;
  }
}

.reviews-grid {
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;
  overflow: hidden;

  @media #{$tablet-widths} {
    width: calc(100% + 20px);
    margin-right: -20px;
  }
}

/* Force new columns */
.reviews-grid::before,
.reviews-grid::after {
  content: "";
  flex-basis: 100%;
  width: 0;
  order: 2;
}

.review-cell {
  width: 100%;
  padding-bottom: 20px;

  @media #{$tablet-widths} {
    width: calc(100% / 2);
    padding-right: 20px;
  }
}

/* Re-order items into rows */
@media #{$tablet-widths} {
  .review-cell:nth-child(2n+1) { order: 1; }
  .review-cell:nth-child(2n+2) { order: 2; }
}
</style>
