import { defineStore } from 'pinia'

export const useProfile = defineStore('profile', {
  state: () => ({
    _profile: {},
  }),
  getters: {
    profile() {
      return this._profile;
    }
  },
  actions: {
    setProfile(profile) {
      this._profile = profile;
    },
    canAny(permissions) {
      let allow = false
      if(this._profile.premium_abilities) {
        permissions.forEach((permission) => {
          if(this._profile.premium_abilities.indexOf(permission) !== -1) allow = true;
        })
      }
      return allow;
    }
  },
})
