<template>
  <div class="review-card">
    <q-img class="review-card__cover" :src="cover" v-bind:style="{ aspectRatio: ratio }" alt="" v-if="cover" />
    <div class="review-card__info">
      <div class="review-card__title">{{title}}</div>
      <div class="review-card__text">
        <collapse :text="text" :length="205" />
      </div>
      <div class="review-card__author">
        <div v-if="rating > 0" class="review-card__author-rating">
          <q-icon name="star" v-for="n in rating" :key="n" />
          <q-icon name="o_star_border" v-for="n in 5-rating" :key="n" />
        </div>
        <div class="review-card__author-info">
          <div class="review-card__author-avatar">
            <img v-if="avatar" :src="avatar" alt="" >
            <span v-if="!avatar">{{initial}}</span>
          </div>
          <div>
            <router-link to="#" class="review-card__author-name">{{name}}</router-link>
            <div class="review-card__date">{{date}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Collapse from "components/Collapse";

export default defineComponent({
  name: "ReviewCard",
  components: {
    Collapse
  },
  props: [
    'name',
    'avatar',
    'date',
    'rating',
    'title',
    'text',
    'cover',
    'ratio',
    'initial'
  ],
})
</script>

<style lang="scss">
  @import "src/css/variables";
  @import "src/css/mixins";

  .review-card {
    border-radius: $border-radius;
    overflow: hidden;
    border: $border;
  }

  .review-card__cover {
    width: calc(100% + 2px);
    margin: -1px -1px 0 -1px;
  }

  .review-card__info {
    padding: 16px;
  }

  .review-card__title {
    @include font(20px, 28px);
    font-weight: 600;
    margin-bottom: 12px;
    color: $text-color-primary;
  }

  .review-card__text {
    padding-bottom: 16px;

    .collapsed-text {
      @include font(16px, 24px);
      color: $text-color-secondary;
    }

    .btn {
      padding: 0;
      background: transparent;
      border: none;
      color: $color-primary-500;
      margin-top: 12px;
      height: auto;
      min-height: auto;
      font-weight: 400;

      &:hover {
        background: #fff;

        .q-focus-helper {
          display: none;
        }
      }
    }
  }

  .review-card__author {
    border-top: $border;
    padding-top: 16px;

    @media #{$desktop-l-widths} {
      display: flex;
      align-items: center;
    }
  }

  .review-card__author-rating {
    margin-bottom: 16px;

    @media #{$desktop-l-widths} {
      order: 2;
      margin-left: auto;
      margin-bottom: 0;
    }

    i {
      @include font(21px);
      color: $color-primary-500;
    }
  }

  .review-card__author-info {
    display: flex;
    align-items: center;
  }

  .review-card__author-avatar {
    background: $color-orange;
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    @include font(16px, 24px);
    @include size(40px);
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;

    img {
      @include size(100%);
      object-fit: cover;
    }
  }

  .review-card__author-name {
    @include font(16px, 24px);
    color: $text-color-primary;
    font-weight: 600;
    text-decoration: none;
  }

  .review-card__date {
    @include font(12px, 16px);
    color: $text-color-secondary;
  }
</style>