<template>
  <header class="header" :class="{ dark: isDark }">
    <q-no-ssr>
      <header-banners/>
    </q-no-ssr>
    <div class="header-wrapper">
      <div class="header-menu__btn" v-on:click="isOpen = !isOpen">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div class="header-logo">
        <router-link to="/">
          <img class="header-logo__light-image" src="../assets/images/header-logo.svg" alt="">
          <img class="header-logo__dark-image" src="../assets/images/header-logo-dark.svg" alt="">
        </router-link>
      </div>

      <div class="header-menu" :class="{ open: isOpen }">
        <div class="header-menu__overlay" v-on:click="isOpen = !isOpen"></div>
        <div class="header-menu__wrapper">
          <div class="header-menu__top">
            <div class="header-menu__close" v-on:click="isOpen = !isOpen">
              <span></span>
              <span></span>
            </div>

            <div class="header-menu__logo">
              <img src="../assets/images/header-logo-dark.svg" alt="">
            </div>
          </div>

          <ul class="header-menu__links">
            <li class="header-menu__item" @click="isOpen = !isOpen">
              <router-link :to="{name: 'guides-search'}">Гиды</router-link>
            </li>
            <li class="header-menu__item" @click="isOpen = !isOpen">
              <router-link to="/reports">Отчеты</router-link>
            </li>
            <li class="header-menu__item" @click="isOpen = !isOpen">
              <router-link :to="{name: 'tours-search'}">Туры</router-link>
            </li>
            <li class="header-menu__item" @click="isOpen = !isOpen">
              <router-link :to="{name: 'hot-tours-search'}">Горящие туры</router-link>
            </li>
            <li v-if="false" class="header-menu__item" @click="isOpen = !isOpen">
              <router-link :to="{name: 'sharing-tours-search'}">Рыбшеринг</router-link>
            </li>
            <li class="header-menu__item" @click="isOpen = !isOpen">
              <router-link :to="{name: 'tournament-page'}">
                <svg style="margin: -4px 0;" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13 16.1116V17.9204H18V19.6748H6V17.9204H11V16.1116C9.0667 15.8977 7.28882 15.0723 6 13.7903C4.71119 12.5082 4.00003 10.8576 4 9.14828V3.88501H20V9.14828C20 10.8576 19.2888 12.5082 18 13.7903C16.7112 15.0723 14.9333 15.8977 13 16.1116ZM6 5.63943V9.14828C6 10.5442 6.63214 11.8829 7.75736 12.87C8.88258 13.857 10.4087 14.4116 12 14.4116C13.5913 14.4116 15.1174 13.857 16.2426 12.87C17.3679 11.8829 18 10.5442 18 9.14828V5.63943H6ZM1 5.63943H3V9.14828H1V5.63943ZM21 5.63943H23V9.14828H21V5.63943Z"/>
                </svg>

                Турнир
              </router-link>
            </li>
          </ul>
          <q-no-ssr>
            <router-link
              v-if="$auth.isGuest()"
              to="/login"
              class="header-menu__login"
            >
              <q-icon name="o_lock"/>
              <span>Войти</span>
            </router-link>
            <router-link
              v-if="$auth.isAuthenticated()"
              :to="{name: $auth.canAny(['guide']) ? 'profile' : 'client-profile', params: {idOrSlug: $auth.user().username ?? $auth.user().id}}"
              class="header-menu__profile"
            >
              <div class="header-menu__profile-avatar ">
                <img :src="$auth.user().avatar" alt="" v-if="$auth.user().avatar">
                <span v-else>{{ $auth.user().full_name.charAt(0).toUpperCase() }}</span>
              </div>
              <span>{{ $auth.user().full_name }}</span>
            </router-link>
            <div
              v-if="$auth.isAuthenticated()"
              @click="logout"
              class="header-menu__login logout"
            >
              <q-icon name="o_logout"/>
              <span>Выйти</span>
            </div>
          </q-no-ssr>
        </div>
      </div>
      <q-no-ssr>
        <div class="header-interactive" v-if="$auth.isAuthenticated()">
          <router-link :to="{name: 'chats'}" class="header-interactive__item">
            <q-icon class="header-interactive__item-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.455 15.0004L1 18.5004V3.0004C1 2.73518 1.10536 2.48083 1.29289 2.29329C1.48043 2.10575 1.73478 2.0004 2 2.0004H17C17.2652 2.0004 17.5196 2.10575 17.7071 2.29329C17.8946 2.48083 18 2.73518 18 3.0004V15.0004H5.455ZM4.763 13.0004H16V4.0004H3V14.3854L4.763 13.0004ZM8 17.0004H18.237L20 18.3854V8.0004H21C21.2652 8.0004 21.5196 8.10575 21.7071 8.29329C21.8946 8.48083 22 8.73518 22 9.0004V22.5004L17.545 19.0004H9C8.73478 19.0004 8.48043 18.895 8.29289 18.7075C8.10536 18.52 8 18.2656 8 18.0004V17.0004Z"
                  fill="inherit"/>
                <circle v-show="userStore.user.unread_messages_count > 0" cx="18" cy="6" r="4" fill="#E11F13"/>
              </svg>
            </q-icon>
          </router-link>
          <router-link :to="{name: 'notifications'}" class="header-interactive__item">
            <q-icon class="header-interactive__item-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20 17.0004H22V19.0004H2V17.0004H4V10.0004C4 7.87866 4.84285 5.84383 6.34315 4.34354C7.84344 2.84325 9.87827 2.0004 12 2.0004C14.1217 2.0004 16.1566 2.84325 17.6569 4.34354C19.1571 5.84383 20 7.87866 20 10.0004V17.0004ZM18 17.0004V10.0004C18 8.4091 17.3679 6.88297 16.2426 5.75776C15.1174 4.63254 13.5913 4.0004 12 4.0004C10.4087 4.0004 8.88258 4.63254 7.75736 5.75776C6.63214 6.88297 6 8.4091 6 10.0004V17.0004H18ZM9 21.0004H15V23.0004H9V21.0004Z"
                  fill="inherit"/>
                <circle v-show="userStore.user.unread_notifications_count > 0" cx="18" cy="6" r="4" fill="#E11F13"/>
              </svg>
            </q-icon>
          </router-link>
        </div>
      </q-no-ssr>
      <router-link
        v-if="$auth.isGuest()"
        to="/login"
        class="header-login"
      >
        <q-icon name="o_lock"/>
        <span>Войти</span>
      </router-link>
      <q-no-ssr>
        <div class="avatar__wrapper" v-if="userStore.isAuthenticated">
          <q-avatar
            class="avatar"
            @click="$router.push({name: $auth.canAny(['guide']) ? 'profile' : 'client-profile', params: {idOrSlug: userStore._user.username ?? userStore._user.id}})"
          >
            <img style="height: 100%; width: auto; max-width: unset;" :src="userStore._user.avatar" alt=""
                 v-if="userStore._user.avatar">
            <span v-else>{{ userStore._user.full_name.charAt(0).toUpperCase() }}</span>
          </q-avatar>

          <div class="avatar__actions-wrapper">
            <ul class="avatar__actions">
              <li>
                <router-link class="avatar__action"
                             :to="{name: $auth.canAny(['guide']) ? 'profile' : 'client-profile', params: {idOrSlug: $auth.user().username ?? $auth.user().id}}">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20 22H18V20C18 19.2044 17.6839 18.4413 17.1213 17.8787C16.5587 17.3161 15.7956 17 15 17H9C8.20435 17 7.44129 17.3161 6.87868 17.8787C6.31607 18.4413 6 19.2044 6 20V22H4V20C4 18.6739 4.52678 17.4021 5.46447 16.4645C6.40215 15.5268 7.67392 15 9 15H15C16.3261 15 17.5979 15.5268 18.5355 16.4645C19.4732 17.4021 20 18.6739 20 20V22ZM12 13C11.2121 13 10.4319 12.8448 9.7039 12.5433C8.97595 12.2417 8.31451 11.7998 7.75736 11.2426C7.20021 10.6855 6.75825 10.0241 6.45672 9.2961C6.15519 8.56815 6 7.78793 6 7C6 6.21207 6.15519 5.43185 6.45672 4.7039C6.75825 3.97595 7.20021 3.31451 7.75736 2.75736C8.31451 2.20021 8.97595 1.75825 9.7039 1.45672C10.4319 1.15519 11.2121 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7C18 8.5913 17.3679 10.1174 16.2426 11.2426C15.1174 12.3679 13.5913 13 12 13ZM12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11Z"/>
                  </svg>
                  <h5>Профиль</h5>
                </router-link>
              </li>
              <li>
                <router-link class="avatar__action"
                             :to="{name: $auth.canAny(['guide']) ? 'account-subscriptions' : 'account-info'}">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12 1L21.5 6.5V17.5L12 23L2.5 17.5V6.5L12 1ZM12 3.311L4.5 7.653V16.347L12 20.689L19.5 16.347V7.653L12 3.311ZM12 16C10.9391 16 9.92172 15.5786 9.17157 14.8284C8.42143 14.0783 8 13.0609 8 12C8 10.9391 8.42143 9.92172 9.17157 9.17157C9.92172 8.42143 10.9391 8 12 8C13.0609 8 14.0783 8.42143 14.8284 9.17157C15.5786 9.92172 16 10.9391 16 12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16ZM12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14Z"/>
                  </svg>
                  <h5>Настройки</h5>
                </router-link>
              </li>
              <li>
                <router-link class="avatar__action" :to="{name: 'booking'}">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM15 5H9V7H7V5H4V9H20V5H17V7H15V5ZM20 11H4V19H20V11Z"
                      fill="#191927"/>
                  </svg>
                  <h5>История бронирований</h5>
                </router-link>
              </li>
              <li @click="logout">
                <div class="avatar__action">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5 22C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V6H18V4H6V20H18V18H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5ZM18 16V13H11V11H18V8L23 12L18 16Z"/>
                  </svg>

                  <h5>Выход</h5>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </q-no-ssr>
    </div>
  </header>
</template>

<script>
import {defineComponent} from "vue";
import Echo from "laravel-echo";
import {useUser} from "src/stores/user";
import {cookies} from "boot/cookies";
import HeaderBanners from "components/HeaderBanners.vue";

export default defineComponent({
  name: "AppHeader",
  components: {HeaderBanners},
  props: ['isAuth', 'isDark'],
  setup() {
    const userStore = useUser();
    return {
      userStore
    }
  },
  data() {
    return {
      isOpen: false,
      notify: false,
      chat: false,
    };
  },
  mounted() {
    if (this.$auth.isAuthenticated()) {
      window.Pusher = require('pusher-js')
      window.Echo = new Echo({
        broadcaster: 'pusher',
        authEndpoint: process.env.WEBSOCKETS_ENDPOINT,
        key: process.env.WEBSOCKETS_KEY,
        cluster: process.env.WEBSOCKETS_CLUSTER,
        wsHost: process.env.WEBSOCKETS_SERVER,
        wssHost: process.env.WEBSOCKETS_SERVER,
        wsPort: process.env.WEBSOCKETS_PORT,
        wssPort: process.env.WEBSOCKETS_PORT,
        enabledTransports: ['ws', 'wss'],
        forceTLS: false,
        auth: {
          headers: {
            Authorization: `Bearer ${cookies.get('auth_token')}`,
          },
        },
      })

      window.Echo.join(`messenger.user.${this.$auth.user().id}`)
        .listen('.messenger.message.created', (event) => {
          this.userStore.increaseUnreadMessages();
        })
        .listen('.messenger.unread_messages.updated', (event) => {
          this.userStore.updateUnreadMessages(event.count);
        })
        .listen('.messenger.unread_notifications.updated', (event) => {
          this.userStore.updateUnreadNotifications(event.count);
        })
    }
  },
  methods: {
    logout() {
      if (this.$auth.hasAdminToken()) {
        this.$auth.returnToAdminPanel()
      } else {
        this.$auth.logout()
      }
    },
  }
});
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.avatar__wrapper {
  display: none;

  @media #{$tablet-widths} {
    margin-left: 32px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $color-white;
    @include font(16px, 24px);
    padding: 8px;
  }

  @media #{$desktop-widths} {
    margin-left: 0;
    padding: 16px;
    background: transparent;
    @include transition(background);

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  position: relative;
  z-index: 1;

  &:hover {
    .avatar__actions-wrapper {
      opacity: 1;
      visibility: visible;
      pointer-events: unset;
    }
  }
}

.avatar {
  position: relative;
  z-index: 3;
  cursor: pointer;
  @include size(40px);
  color: $color-white;
  background: $color-green;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .q-avatar__content {
    @include font(16px, 24px);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.avatar__actions-wrapper {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: $transition;

  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;

  width: 270px;
  @media #{$tablet-widths} {
    top: calc(40px + 16px); // avatarHeight + paddings for correct hover
  }

  @media #{$desktop-widths} {
    top: calc(40px + 32px); // avatarHeight + paddings for correct hover
  }

  &:hover {
    opacity: 1;
    visibility: visible;
    pointer-events: unset;
  }
}

.avatar__actions {
  padding: 0 20px;
  background: #FFFFFF;
  box-shadow: 0 12px 32px rgba(17, 21, 99, 0.24);
  border-radius: 12px;

  > li {
    cursor: pointer;
    padding: 12px 0;
  }
}

.avatar__action {
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    > svg, h5 {
      fill: #2329C7;
      color: #2329C7;
    }
  }

  > svg {
    margin-right: 16px;
    fill: #191927;
  }

  > h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #191927;
  }
}

.header {
  border-bottom: $border-dark;
  position: relative;
  width: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;

  &.dark {
    border-bottom-color: $color-border;

    .header-logo__dark-image {
      display: block;
    }

    .header-logo__light-image {
      display: none;
    }

    .header-menu__btn {
      span {
        background: $text-color-primary;
      }
    }

    .header-login {
      color: $color-primary-500;

      @media #{$desktop-widths} {
        &:hover {
          background: $color-surface-grey;
          color: $color-primary-400;
        }
      }
    }

    .header-location {
      color: $text-color-secondary;

      @media #{$desktop-widths} {
        &:hover {
          background: $color-surface-grey;
          color: $color-primary-400;
        }
      }
    }

    .header-menu__item {
      a {
        color: $text-color-primary;
        fill: $text-color-primary;

        @media #{$desktop-widths} {
          &:hover, &.router-link-active {
            color: $color-primary-500;
            fill: $color-primary-500;
          }
        }
      }
    }

    .header-profile {
      @media #{$desktop-widths} {
        &:hover {
          background: $color-surface-grey;
        }
      }
    }

    .header-interactive__item {
      @media #{$desktop-widths} {
        &:hover {
          background: $color-surface-grey;

          svg {
            fill: $color-primary-500;
          }
        }
      }

      i {
        color: $text-color-primary;
      }
    }

    .header-interactive__item-icon svg {
      fill: $text-color-primary;
    }
  }
}

.header-wrapper {
  @include wrapper();
  position: absolute;
  width: 100%;
  top: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 48px;
}

.header-logo {
  @media #{$desktop-widths} {
    margin-right: 10px;
  }

  img {
    width: 85px;

    @media #{$tablet-widths} {
      width: 114px;
    }

    @media #{$desktop-widths} {
      width: 142px;
    }
  }
}

.header-logo__dark-image {
  display: none;
}

.header-menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  transform: translateX(-100%);
  @include transition(transform);

  &.open {
    transform: translateX(0);

    .header-menu__overlay {
      opacity: 1;
    }
  }

  @media #{$desktop-widths} {
    flex: 1 0 auto;
    position: static;
    display: block;
    background: none;
    transform: none;
  }
}

.header-menu__overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 1, 41, 0.5);
  opacity: 0;
  transition: opacity .5s linear;

  @media #{$desktop-widths} {
    display: none;
  }
}

.header-menu__wrapper {
  background: $color-white;
  box-shadow: 0 4px 32px rgba(17, 21, 99, 0.24);
  border-radius: 0 16px 16px 0;
  @include size(300px, 100%);
  position: relative;
  z-index: 1;

  @media #{$tablet-widths} {
    width: 374px;
  }

  @media #{$desktop-widths} {
    @include size(auto);
    box-shadow: none;
    background: none;
    border-radius: 0;
    display: flex;
    align-items: center;
  }
}

.header-menu__top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 16px;
  border-bottom: $border;

  @media #{$desktop-widths} {
    display: none;
  }
}

.header-menu__close {
  display: flex;
  align-items: center;
  justify-content: center;
  @include size(24px);
  margin-right: 32px;
  position: relative;

  span {
    @include size(16px, 2px);
    background: $text-color-primary;
    position: absolute;

    &:first-child {
      transform: rotate(45deg);
    }

    &:last-child {
      transform: rotate(-45deg);
    }
  }
}

.header-menu__links {
  padding: 32px 16px;
  border-bottom: $border;

  @media #{$desktop-widths} {
    border-bottom: none;
    display: flex;
    align-items: center;
    order: 1;
    margin-left: auto;
    padding: 0 16px;
  }
}

.header-menu__item {
  font-family: $font-heading;
  @include font(32px, 38px);
  letter-spacing: 1px;
  text-transform: uppercase;

  @media #{$desktop-widths} {
    text-transform: none;
  }

  & + & {
    margin-top: 16px;

    @media #{$desktop-widths} {
      margin-left: 24px;
      margin-top: 0;
    }
  }

  @media #{$desktop-widths} {
    font-family: $font;
    font-weight: 600;
    @include font(16px);
    letter-spacing: 0;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    color: $text-color-primary;
    text-decoration: none;

    svg {
      fill: inherit;
      @include transition();
    }

    @media #{$desktop-widths} {
      color: $color-white;
      fill: $color-white;
      padding: 28px 0;
      @include transition(color);

      &:hover {
        color: $text-color-dark-secondary;
        fill: $text-color-dark-secondary;
      }
    }
  }
}

.header-menu__login {
  padding: 20px 16px;
  border-bottom: $border;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $text-color-primary;
  font-weight: 600;
  @include font(16px);

  @media #{$tablet-widths} {
    display: none;
  }

  &.logout {
    display: flex;

    @media #{$tablet-widths} {
      display: flex;
    }

    @media #{$desktop-widths} {
      display: none;
    }
  }

  i {
    color: inherit;
    font-size: 24px;
    margin-right: 12px;
  }
}

.header-menu__profile {
  padding: 16px;
  border-bottom: $border;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $text-color-primary;

  @media #{$tablet-widths} {
    display: none;
  }

  span {
    @include font(16px, 24px);
  }
}


.header-login {
  display: none;

  i {
    color: inherit;
    font-size: 24px;
    margin-right: 12px;
  }

  @media #{$tablet-widths} {
    margin-left: auto;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $color-white;
    font-weight: 600;
    @include font(16px, 24px);
  }

  @media #{$desktop-widths} {
    margin-left: 44px;
    padding: 24px 12px;
    background: transparent;
    @include transition(background);

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

.header-profile {
  display: none;

  @media #{$tablet-widths} {
    margin-left: 32px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $color-white;
    @include font(16px, 24px);
    padding: 8px;
  }

  @media #{$desktop-widths} {
    margin-left: 0;
    padding: 16px;
    background: transparent;
    @include transition(background);

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

.header-profile__avatar {
  @include size(40px);
  @include font(16px, 24px);
  color: $color-white;
  background: $color-green;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.header-menu__profile-avatar {
  @include size(40px);
  margin-right: 16px;
  @include font(16px, 24px);
  color: $color-white;
  background: $color-green;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.header-location {
  padding: 20px 16px 20px 20px;
  border-bottom: $border;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $text-color-primary;
  font-weight: 400;
  @include font(16px, 24px);

  @media #{$desktop-widths} {
    order: 0;
    border-bottom: 0;
    padding: 24px 12px;
    background: transparent;
    margin-left: auto;
    color: $color-white;
    opacity: 0.7;
    cursor: pointer;
    @include transition();

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      opacity: 1;
    }
  }

  @media #{$desktop-l-widths} {
    margin-left: 48px;
  }

  i {
    color: inherit;
    font-size: 24px;
    margin-right: 8px;
  }
}

.header-menu__btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @include size(24px, 24px);
  padding: 4px 3px;
  margin-right: 16px;

  @media #{$desktop-widths} {
    display: none;
  }

  span {
    @include size(100%, 2px);
    background: $color-white;
  }
}

.header-interactive {
  display: flex;
  align-items: center;
  margin-left: auto;

  @media #{$desktop-widths} {
    margin-left: 8px;
  }

  @media #{$desktop-l-widths} {
    margin-left: 40px;
  }
}

.header-interactive__item {
  padding: 8px 0;

  @media #{$desktop-widths} {
    cursor: pointer;
    padding: 24px 16px;
    background: transparent;
    @include transition(background);

    &:hover {
      background: rgba(255, 255, 255, 0.1);

      //i {
      //  color: $color-primary-500 !important;
      //}
      //
      //svg {
      //  fill: $color-primary-500;
      //}
    }
  }

  & + & {
    margin-left: 16px;

    @media #{$tablet-widths} {
      margin-left: 32px;
    }

    @media #{$desktop-widths} {
      margin-left: 0;
    }
  }
}

.header-interactive__item-icon {
  @include font(24px);
  color: $color-white;
  @include transition(color);

  svg {
    fill: $color-white;
    @include size(24px);
    @include transition(fill)
  }
}
</style>
