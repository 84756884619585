<template>
  <div class="author">
    <div class="author__top">
      <img v-if="authorInfo.avatar" :src="authorInfo.avatar" alt="" class="author__photo">
      <span v-else
            style=" color: #FFFFFF; background: #1FC53A; display: flex; align-items: center; justify-content: center;"
            class="author__photo"> {{ authorInfo.full_name.slice(0, 1) }} </span>
      <router-link
        :to="{name: 'profile', params: {idOrSlug: guideInfo.username ?? guideInfo.id}}"
        class="author__name"> {{ authorInfo.full_name }}</router-link>
      <div class="author__rating" v-if="isGuide">
        <q-icon v-for="(star, idx) in authorInfo.guide_profile.stats.star_rating" :key="idx" name="star"/>
        <q-icon v-for="(star, idx) in 5 - authorInfo.guide_profile.stats.star_rating" :key="idx" name="o_star_border"/>
      </div>
    </div>
    <div v-if="date" class="author__info">
      <div class="author__info-item">
        <div class="author__info-item-title">Дата:</div>
        <div class="author__info-item-value">{{date}}</div>
      </div>
      <div class="author__info-item" v-if="fishes.length > 0">
        <div class="author__info-item-title">Рыба:</div>
        <div  class="author__info-item-value">
          <router-link v-for="(fish, idx) in fishes" :key="idx" to="/fish">{{fish.name}}</router-link>
        </div>

      </div>
      <div class="author__info-item" v-if="!isGuide">
        <div class="author__info-item-title">Гид:</div>
        <router-link
            :to="{name: 'profile', params: {idOrSlug: guideInfo.username ?? guideInfo.id}}"
            class="author__info-item-value">{{ guideInfo.full_name }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'AuthorCard',
  props: {
    date: {
      type: String,
    },
    isGuide: {},
    withGuide: {},
    authorInfo: {
      type: Object
    },
    guideInfo: {
      type: Object
    },
    fishes: {
      type: Array,
      default: () => []
    }
  },
})
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.author {
  border: $border;
  border-radius: 16px;
  padding: 28px 20px 8px;

  @media #{$desktop-widths} {
    width: 316px;
  }
}

.author__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.author__photo {
  @include size(80px);
  border-radius: 50%;
  margin-bottom: 16px;
}

.author__name {
  @include font(20px, 28px);
  font-weight: 600;
  color: $text-color-primary;
  @include transition(color);
  text-decoration: none;
  margin-bottom: 8px;

  &:hover {
    color: $color-primary-500;
  }
}

.author__rating {
  display: flex;

  i {
    color: $color-primary-500;
    @include font(21px);

    & + i {
      margin-left: 4px;
    }
  }
}

.author__info-item {
  padding: 12px 0;
  border-top: $border;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include font(16px, 24px);
}

.author__info-item-title {
  color: $text-color-primary;
  font-weight: 600;
  padding-right: 20px;
}

.author__info-item-value {
  color: $text-color-primary;
  @include transition(color);
}

.author__info-item-value a {
  display: block;
  text-align: right;
}

a.author__info-item-value, .author__info-item-value a {
  text-decoration: none;
  color: $color-primary-500;

  &:hover {
    color: $color-primary-400;
  }
}
</style>
