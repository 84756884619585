<template>
  <div class="profile-card">
    <div class="profile-card__top">
      <q-circular-progress
          show-value
          :value='progress'
          size="132px"
          :thickness="0.05"
          color="primary"
          center-color="transparent"
          :track-color="isEdit && isGuide ? 'secondary' : 'transparent'"
          class="profile-card__avatar"
      >
        <q-avatar size="120px" v-bind:class="{'empty' : !profile.user.avatar}">
          <img style="height: 100%; width: auto; max-width: unset;" :src="profile.user.avatar" alt=""
               v-if="profile.user.avatar">
          <span v-else>{{ profile.user.full_name.charAt(0).toUpperCase() }}</span>
        </q-avatar>
      </q-circular-progress>

      <q-checkbox
          v-if="$auth.canAny(['fisherman', 'guide']) && !isGuide"
          v-model="bookmark"
          class="bookmark profile-card__bookmark"
          checked-icon="bookmark"
          unchecked-icon="o_bookmark_border"
          @click="toggleFavorite"
      >
        <q-tooltip class="tooltip" anchor="top middle" self="bottom middle" :offset="[0, 4]">
          {{ bookmark ? 'Убрать из избранного' : 'Добавить в избранное' }}
        </q-tooltip>
      </q-checkbox>

      <div class="profile-card__fullness" v-if="isEdit && isGuide">Профиль заполнен на {{ progress }}%</div>

      <div class="profile-card__name"><span class="lastname">{{ profile.user.last_name }}</span> <span>{{ profile.user.name }}</span></div>

      <div class="profile-card__rating" v-if="profile.stats.rating">
        <q-icon name="star" v-for="n in profile.stats.star_rating" :key="n"/>
        <q-icon name="o_star_border" v-for="n in 5 - profile.stats.star_rating" :key="n"/>
      </div>
    </div>

    <div class="profile-card__content">
      <div class="profile-card__checked" v-if="isPro && profile.premium_abilities.verified">
        <span>Проверенный</span>
        <q-icon name="check"/>
      </div>

      <div class="profile-card__btns">
        <q-btn class="btn btn-primary btn-s" :ripple=false label="Забронировать"
               v-if="$auth.canAny(['fisherman']) && !isGuide && profile.user.type === 'guide' && profile.waters.length"
               @click="$router.push({name: 'guide-booking', params: {idOrSlug: $route.params.idOrSlug}})"/>
        <q-btn class="btn btn-s btn-primary"
               to="/account/info"
               :ripple=false
               label="Редактировать"
               v-if="isGuide"/>
        <q-btn class="btn btn-s" @click="openChat"
               v-bind:class="[{'btn-secondary' : isGuide}, {'btn-primary' : !isGuide}]" :ripple=false
               label="Чат" v-if="$auth.isAuthenticated() && !isGuide"/>
        <q-btn @click.stop="$router.push({name: 'fav-users'})" class="btn btn-secondary btn-s" :ripple=false
               label="Избранные" v-if="isGuide"/>
        <q-btn class="btn btn-secondary btn-s" @click="$router.push({name: 'booking'})" :ripple=false
               label="История бронирований" v-if="isGuide"/>

        <template v-if="$auth.isAuthenticated()">
          <q-btn
            class="btn btn-white btn-s" :ripple=false
            label="Показать телефон"
            v-if="!showPhone && profile.user.phone && !isGuide && profile.premium_abilities.indexOf('pro_account') !== -1"
            v-on:click="showPhoneFn()"
          />
          <a v-bind:href="'tel:' + profile.user.phone" class="profile-card__phone"
             v-if="showPhone" @click.prevent="sendYM('target5-2')">
            <q-icon name="o_phone"/>
            <span>{{ profile.user.phone_international_format }}</span>
          </a>
        </template>


        <template v-if="!$auth.isAuthenticated()">
          <q-btn
            v-if="profile.user.type === 'guide'"
            class="btn btn-primary btn-s" :ripple=false
            label="Забронировать"
            @click="showPoppup('booking')"
          />

          <q-btn
            v-if="!showPhone && profile.user.phone && profile.premium_abilities.indexOf('pro_account') !== -1"
            class="btn btn-white btn-s" :ripple=false
            label="Показать телефон"
            @click="showPhoneFn()"
          />
          <a
            v-else-if="showPhone"
            v-bind:href="'tel:' + profile.user.phone" class="profile-card__phone"
            @click.prevent="sendYM('target5-2')"
          >
            <q-icon name="o_phone"/>
            <span>{{ profile.user.phone_international_format }}</span>
          </a>
          <q-btn
            v-else
            class="btn btn-white btn-s" :ripple=false
            label="Показать телефон"
            @click="showPoppup('phone')"
          />
        </template>

      </div>
    </div>
    <div class="profile-card__bottom" v-bind:class="{'center' : isEdit}">
      <div class="profile-card__term">{{ profile.stats.registered_time }}</div>
      <div class="profile-card__complaint" v-if="$auth.isAuthenticated() && !isEdit && $auth.isGuest()" @click="complaintPopup = true">
        Пожаловаться
      </div>
      <div class="profile-card__complaint"
           v-if="!isEdit && $auth.isAuthenticated()"
           @click="$router.push({name: 'private-chat', params: {userId: '1', type: profile.user.type, id: profile.user.id, name: profile.user.full_name}})">
        Пожаловаться
      </div>
    </div>

    <q-dialog v-model="complaintPopup">
      <div class="popup popup-complaint">
        <div class="popup-close">
          <q-icon name="close" v-close-popup/>
        </div>
        <div class="popup-content">
          <h3 class="popup-complaint__name">Пожаловаться</h3>
          <div class="popup-complaint__author">
            <div class="popup-complaint__author-avatar">
              <span v-if="!profile.user.avatar">{{ profile.user.full_name.charAt(0) }}</span>
              <q-img v-else :src="profile.user.avatar"></q-img>
            </div>
            <div class="popup-complaint__author-name">{{ profile.user.full_name }}</div>
          </div>
          <q-form
              class="form"
              autocorrect="off"
              autocapitalize="off"
              autocomplete="off"
              spellcheck="false"
          >
            <div class="form__item">
              <q-input
                  :error="!!errors.message"
                  :error-message="errors.message"
                  type="textarea" v-model="report.message" label="Комментарий" class="input textarea"/>
            </div>
            <div class="form__bottom space-between popup-complaint__bottom">
              <q-btn class="btn btn-secondary btn-m" :ripple="false" @click="complaintPopup = false" label="Отменить"/>
              <q-btn class="btn btn-primary btn-m" :ripple="false" @click="sendReport" label="Отправить"/>
            </div>
          </q-form>
        </div>
      </div>
    </q-dialog>
    <booking-guide-modal ref="booking"></booking-guide-modal>
    <q-dialog v-model="showRegPoppup">
      <div class="popup popup-subscription">
        <div class="popup-close">
          <q-icon name="close" v-close-popup/>
        </div>
        <div class="popup-content">
          <div class="popup-subscription__icon error">
            <q-icon :name="'o_person'"/>
          </div>
          <h3 class="popup-subscription__text">Авторизуйтесь</h3>
          <div class="popup-subscription__small-text">{{regPoppupText}}</div>
          <q-btn :ripple="false" class="btn btn-primary btn-m" label="Регистрация"
                 @click="$router.push({name: 'registration-page'})"/>
        </div>
      </div>
    </q-dialog>
    <alert-modal ref="alert"></alert-modal>
  </div>
</template>

<script>
import {defineComponent, ref} from "vue";
import BookingGuideModal from "components/BookingGuideModal";
import AlertModal from "components/AlertModal";

export default defineComponent({
  name: "ProfileCard",
  components: {AlertModal, BookingGuideModal},
  emits: ['toggleEdit'],
  props: [
    'photo',
    'name',
    'isGuide',
    'isPro',
    'isEdit',
    'phone',
    'profile',
    'progress'
  ],
  data() {
    return {
      showRegPoppup: false,
      regPoppupText: '',
      showPhone: false,
      bookmark: false,
      errors: {},
      report: {
        message: '',
        user_id: null
      }
    };
  },
  mounted() {
    this.bookmark = this.profile.user.favorite
  },
  setup() {
    return {
      complaintPopup: ref(false),
      complaint: ref(''),
      date: ref(''),
      options: ref([]),
    }
  },
  methods: {
    openChat() {
      this.$api.post('actions/click', {entity: 'click_chat_button', guide_id: this.profile.user.id})
      this.$router.push({name: 'private-chat', params: {userId: this.profile.user.id}})
      this.sendYM('target4-1')
    },
    openBookingModal() {
      this.$refs.booking.open({profile: this.profile})
      this.sendYM('target3-1')
    },
    sendYM(name) {
      if (typeof ym !== undefined) {
        ym(45019072, 'reachGoal', name)
      }
    },
    showPoppup(btnType) {
      if (btnType === 'phone') {
        this.sendYM('target5-1')
        this.showRegPoppup = true;
        this.regPoppupText = `Для просмотра контактов ${this.profile.user.type === 'guide' ? 'гида' : 'рыбака'} нужно зарегистрироваться на сайте`
      } else if (btnType === 'booking') {
        this.regPoppupText = 'Для бронирования тура нужно зарегистрироваться на сайте'
        this.showRegPoppup = true;
      }
    },
    toggleEdit() {
      this.$emit('toggleEdit')
    },
    sendReport() {
      this.report.user_id = this.profile.user.id;
      this.report['g-recaptcha-response'] = 'g-recaptcha-response';
      this.$api.post('report', this.report).then(res => {
        this.complaintPopup = false;
        this.$refs.alert.open('success', 'Жалоба отправлена', ['Жалоба отправленна на рассмотрение администраторам'])
      }).catch(e => {
        this.errors = e.response.data.errors
      })
    },
    showPhoneFn() {
      if (!this.showPhone) {
        this.sendYM('target5-1')
      }

      this.showPhone = true;
      this.$api.post('actions/click', {entity: 'phone', guide_id: this.profile.user.id})
    },
    toggleFavorite() {
      if (this.$auth.canAny(['fisherman'])) {
        this.$api.post(`client/favorite/${this.profile.user.id}`)
      }
      if (this.$auth.canAny(['guide'])) {
        this.$api.post(`guide/favorite/${this.profile.user.id}`)
      }

      // this.bookmark = !this.bookmark;
    }
  }
});
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.profile-card {
  padding: 24px 20px 20px;
  position: relative;
  background: $color-white;
  border-radius: 16px 16px 0 0;

  @media #{$tablet-widths} {
    padding: 14px 26px 20px;
  }

  @media #{$desktop-widths} {
    width: 296px;
    border-radius: 16px;
    border: $border;
  }
}

.profile-card__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: $border;
}

.profile-card__bookmark {
  position: absolute;
  z-index: 2;
  top: 12px;
  right: 20px;
}

.profile-card__avatar {
  margin-bottom: 10px;

  .empty {
    background: $color-yellow;
    border-radius: 50%;
    color: $color-white;
    @include size(120px);

    span {
      font-size: 20px !important;
      line-height: 28px !important;
    }
  }
}

.profile-card__fullness {
  color: $text-color-tertiary;
  @include font(16px, 24px);
  margin-bottom: 4px;
}

.profile-card__name {
  color: $text-color-primary;
  @include font(20px, 28px);
  font-weight: 600;
}

.profile-card__rating {
  margin-top: 8px;
  display: flex;

  i {
    color: $color-primary-500;
    @include font(21px);
  }
}

.profile-card__checked {
  border-bottom: $border;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;

  span {
    @include font(16px, 24px);
    color: $text-color-primary;
  }

  i {
    @include font(24px);
    color: $color-primary-500;
  }
}

.profile-card__btns {
  display: flex;
  flex-direction: column;
  padding: 24px 0 20px;

  @media #{$desktop-widths} {
    border-bottom: $border;
  }

  .btn + .btn {
    margin-top: 8px;
  }

  .btn + .profile-card__phone {
    margin-top: 8px;
  }
}

.profile-card__phone {
  padding: 12px 0 5px;
  color: $text-color-primary;
  @include transition(color);
  display: flex;
  justify-content: center;
  text-decoration: none;

  @media #{$desktop-widths} {
    &:hover {
      color: $color-primary-500;
    }
  }

  span {
    font-weight: 600;
    @include font(16px, 24px);
  }

  i {
    @include font(24px);
    margin-right: 8px;
  }
}

.profile-card__bottom {
  padding-top: 9px;
  display: flex;
  justify-content: space-between;
  @include font(12px, 16px);
  color: $text-color-tertiary;

  @media #{$desktop-widths} {
    padding-top: 12px;
  }

  &.center {
    justify-content: center;
  }
}

.profile-card__term {
  padding-right: 20px;
}

.profile-card__complaint {
  color: inherit;
  cursor: pointer;
  @include transition(color);
  text-decoration: none;

  @media #{$desktop-widths} {
    &:hover {
      color: $color-error;
    }
  }
}

.popup-complaint {
  width: 428px;
  max-width: 100%;
}

.popup-complaint__name {
  margin-bottom: 20px;
}

.popup-complaint__author {
  display: flex;
  align-items: center;
}

.popup-complaint__author-avatar {
  @include size(40px);
  border-radius: 50%;
  overflow: hidden;
  background: $color-green;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-white;
  @include font(16px, 14px);
  margin-right: 12px;

  img {
    @include size(100%);
    object-fit: cover;
  }
}

.popup-complaint__author-name {
  @include font(16px, 24px);
  font-weight: 600;
}

.popup-complaint__bottom {
  display: flex;

  .btn {
    flex-grow: 1;

    & + .btn {
      margin-left: 16px;
    }
  }
}

.popup-booking {
  width: 428px;
  max-width: 100%;
}

.popup-booking__name {
  margin-bottom: 20px;
}

.popup-booking__guide {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.popup-booking__guide-avatar {
  @include size(56px);
  border-radius: 50%;
  margin-right: 16px;
}

.popup-booking__guide-name {
  @include font(16px, 24px);
  margin-bottom: 4px;
  font-weight: 600;
  color: $text-color-primary;
}

.popup-booking__guide-rating {
  i {
    @include font(21px);
    color: $color-primary-500;
  }
}

.booking-form__options {
  padding-top: 20px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;

  .checkbox + .checkbox {
    margin-top: 16px;
  }
}

.booking-form__bottom {
  width: calc(100% + 64px);
  margin: 0 -32px;
  padding: 32px 32px 0;
  border-top: $border;
  display: flex;
  align-items: center;

  .btn {
    flex-grow: 1;
    padding: 20px;

    & + .btn {
      margin-left: 16px;
    }
  }
}
</style>
