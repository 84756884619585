<template>
  <div class="report-image">
    <img :src="image" alt="" class="report-image__bg" loading="lazy">
    <img :src="image" alt="" class="report-image__pic" loading="lazy">
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent( {
  name: "ReportImage",
  props: {
    image: {
      type: String,
    },

  }
})
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.report-image {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  @media #{$tablet-widths} {
    border-radius: 12px;
  }
}

.report-image__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(25px);
}

.report-image__pic {
  position: relative;
}
</style>
