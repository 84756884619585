<template>
  <section class="comments">
    <div class="wrapper">
      <h3 class="comments__h3">Комментарии</h3>
<!--      <div class="comments-auth" v-if="!isAuth">-->
      <div class="comments-auth">
        <div class="comments-auth__text">
          Общайся с интересными гидами вживую!<br />
          Просматривай отчеты, комментируй, задавай вопросы, делись впечатлениями!
        </div>
        <div v-if="!$auth.isAuthenticated()" class="comments-auth__link">
          <router-link to="/login" class="btn btn-primary btn-m">
            <q-icon name="o_person" />
            <span>Войти</span>
          </router-link>
        </div>
      </div>
      <div class="comments__content" v-if="$auth.isAuthenticated()" >
        <q-form @submit="emitComment" class="comments-form">
          <div class="comments-form__avatar">
            <img :src="$auth.user().avatar" alt="" v-if="$auth.user().avatar">
            <span v-else>{{ $auth.user().full_name.charAt(0).toUpperCase() }}</span>
          </div>
          <q-input
              ref="commentInput"
              v-model="comment"
              label="Ваш комментарий"
              class="input comments-form__input"
          >
            <template v-slot:after>
              <q-btn @click="emitComment" round dense flat icon="send" />
            </template>
          </q-input>
        </q-form>

        <div v-for="(comment, idx) in comments" :key="idx" class="comments-item">
          <div class="comments-item__avatar">
            <img v-if="comment.author.avatar" :src="comment.author.avatar">
            <span v-else>{{comment.author.fio.slice(0, 1)}}</span>
          </div>
          <div class="comments-item__content">
            <router-link :to="{name: comment.author.type === 'fisherman' ? 'client-profile' : 'profile', params: {idOrSlug: comment.author.id}}" class="comments-item__name">{{comment.author.fio}}</router-link>
            <div class="comments-item__date">{{comment.date}}</div>
            <div class="comments-item__text">{{comment.text}}</div>
            <q-form @submit="emitReply" class="comments-form">
              <q-input
                  @blur="closeReplyForm"
                  ref="replyInput"
                  v-model="replyComment"
                  label="Ваш комментарий"
                  class="input comments-form__input reply-input"
              />
            </q-form>
            <q-btn v-if="!showReplyInput" @click="showReplyForm" class="btn btn-s btn-secondary" :ripple=false label="Ответить"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {defineComponent, ref} from "vue";

export default defineComponent( {
  name: "Comments",
  emits: ['emitComment'],
  props: {
    isAuth: {
      type: Boolean,
      default: false,
    },
    comments: {
      type: Array,
    },
  },
  data: () => ({
    comment: '',
    replyComment: '',
    showReplyInput: false,
  }),
  methods: {
    showReplyForm(event) {
      const commentContent = event.target.closest('.comments-item__content');
      const replyInput = commentContent.getElementsByClassName('reply-input')[0];
      replyInput.style.display = 'block';
      this.showReplyInput = true;
      replyInput.focus();

      const fullName = commentContent.getElementsByClassName('comments-item__name')[0].textContent;
      const name = fullName.split(' ')[0];
      this.replyComment = name + ', ';
    },
    closeReplyForm(event) {
      const commentContent = event.target.closest('.comments-item__content');
      const replyInput = commentContent.getElementsByClassName('reply-input')[0];
      if (replyInput) {
        replyInput.style.display = 'none';
      }
      this.showReplyInput = false;
      this.replyComment = '';
    },
    emitComment() {
      this.$emit('emitComment', this.comment)
      this.comment = '';
    },
    emitReply(event) {
      const commentContent = event.target.closest('.comments-item__content');
      const replyInput = commentContent.getElementsByClassName('reply-input')[0];
      if (replyInput) {
        replyInput.style.display = 'none';
      }
      this.showReplyInput = false;

      this.$emit('emitComment', this.replyComment)
      this.replyComment = '';
    }
  }
})
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.comments-item__content .comments-form {
  margin-bottom: 0!important;
  width: 100%;
}

.reply-input {
  display: none;

  &_active {
    display: block;
  }
}

.comments {
  padding: 32px 0 40px;
  border-bottom: $border;
}

.comments__h3 {
  margin-bottom: 28px;
}

.comments-auth + .comments__content {
  margin-top: 40px;
}

.comments-auth__text {
  @include font(20px, 28px);
  color: $text-color-tertiary;
  margin-bottom: 28px;
}

.comments__content {
  @media #{$desktop-widths} {
    width: calc(100% - 356px);
  }

  @media #{$desktop-l-widths} {
    width: calc(100% - 448px);
  }
}

.comments-form {
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  @media #{$tablet-widths} {
    margin-bottom: 40px;
  }

  @media #{$desktop-widths} {
    margin-bottom: 48px;
  }
}

.comments-form__avatar {
  @include size(40px);
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
  background: $color-green;
  color: $color-white;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @include font(16px, 24px);

  @media #{$tablet-widths} {
    @include size(56px);
    margin-right: 24px;
  }

  img {
    @include size(100%);
    object-fit: cover;
  }
}

.comments-form__input {
  flex-grow: 1;
}

.comments-item {
  display: flex;
  align-items: flex-start;

  & + & {
    margin-top: 32px;

    @media #{$desktop-widths} {
      margin-top: 40px;
    }
  }
}

.comments-item__avatar {
  @include size(40px);
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
  flex-shrink: 0;
  background: $color-green;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  @include font(16px, 24px);

  @media #{$tablet-widths} {
    @include size(56px);
    margin-right: 24px;
  }

  img {
    @include size(100%);
    object-fit: cover;
  }
}

.comments-item__content {
  @media #{$desktop-widths} {
    display: flex;
    flex-wrap: wrap;
  }
}

.comments-item__name {
  color: $text-color-primary;
  font-weight: 600;
  @include font(16px, 24px);
  text-decoration: none;

  @media #{$desktop-widths} {
    @include transition(color);
    margin: 0 12px 12px 0;

    &:hover {
      color: $color-primary-500;
    }
  }
}

.comments-item__date {
  @include font(16px, 24px);
  color: $text-color-tertiary;
  margin-bottom: 8px;

  @media #{$desktop-widths} {
    margin-bottom: 12px;
  }
}

.comments-item__text {
  @include font(16px, 24px);
  color: $text-color-primary;
  margin-bottom: 12px;
  width: 100%;
}
</style>
