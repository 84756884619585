<template>
  <router-link :to="{name: 'fish-page', params: {slug: fish.slug}}"  class="fish-card">
    <div class="fish-card__img">
      <img :src="fish.preview_uploaded.medium" v-if="fish.preview_uploaded.medium" alt="" loading="lazy">
      <img v-else src="../assets/images/fish-card/fish-card.webp" alt="default image">
    </div>
    <div class="fish-card__info">
      <div class="fish-card__title">{{fish.name}}</div>
      <div class="fish-card__text">
        <div class="fish-card__text-item">{{ fish.waters_count.text }} обитания</div>
        <div class="fish-card__text-item">{{ fish.guides_count.text }}</div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FishCard",
  props: ['fish']
});
</script>

<style lang="scss">
  @import "src/css/variables";
  @import "src/css/mixins";

  .fish-card {
    text-decoration: none;
    border-radius: $border-radius;
    border: $border;
    overflow: hidden;
    display: block;
    width: 170px;

    @media #{$tablet-widths} {
      display: flex;
      width: 440px;
    }

    @media #{$tablet-widths} {
      width: 428px;
      box-shadow: transparent;
      @include transition(box-shadow);

      &:hover {
        box-shadow: $shadow;

        .fish-card__title {
          color: $color-primary-500;
        }
      }
    }
  }

  .fish-card__img {
    height: 170px;
    width: 220px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .fish-card__info {
    padding: 12px 16px 16px;

    @media #{$tablet-widths} {
      width: calc(100% - 220px);
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .fish-card__title {
    @include title();
    color: $text-color-primary;
    margin-bottom: 44px;
    @include font(22px, 28px);
    @include transition(color);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media #{$tablet-widths} {
      @include font(26px, 32px);
      margin-bottom: 20px;
    }
  }

  .fish-card__text {
    color: $text-color-secondary;
    @include font(12px, 16px);
  }

  .fish-card__text-item {
    & + & {
      margin-top: 4px;
    }
  }
</style>
