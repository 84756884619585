<template>
  <div class="profile-description">
    <collapse :text=text :length="333" />
  </div>
</template>

<script>
import {defineComponent} from "vue";
import Collapse from "components/Collapse";

export default defineComponent({
  name: "ProfileDescription",
  components: {
    Collapse,
  },
  props: [
      'text'
  ]
});
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.profile-description {
  background: $color-surface-grey;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 32px;

  @media #{$tablet-widths} {
    padding: 32px;
    margin-bottom: 48px;
  }

  @media #{$desktop-widths} {
    padding: 40px;
  }

  .collapsed-text {
    white-space: pre-line;
    @include font(16px, 24px);
    color: $text-color-primary;
  }

  .collapsed-bottom {
    margin-top: 12px;

    @media #{$tablet-widths} {
      margin-top: 16px;
    }

    .btn {
      width: 100%;

      @media #{$tablet-widths} {
        width: auto;
      }
    }
  }
}
</style>
