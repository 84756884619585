<template v-if="report">
  <div @click="$router.push({name: 'report-page', params:{id:report?.slug ?? '#'}})" class="report-card">
    <div class="report-card__top">
      <div class="report-card__avatar">
        <img v-if="avatar" :src="avatar" alt="" loading="lazy">
        <span v-if="!avatar">Р</span>
      </div>
      <div class="report-card__info">
        <div class="report-card__author">{{name}}</div>
        <div class="report-card__date">{{date}}</div>
      </div>
    </div>
    <div class="report-card__cover">
      <q-img :src="cover" v-bind:style="{ aspectRatio: ratio }" alt="" v-if="cover" loading="lazy"/>
      <q-img src="../assets/images/report/report-default.webp" alt="" v-else />
    </div>
    <div class="report-card__flex-wrapper">
      <div class="report-card__text-wrapper">
        <div class="report-card__title">{{title}}</div>
        <div class="report-card__text">{{text}}</div>
      </div>

      <q-btn
          v-if="isGuide"
          class="btn btn-white btn-rounded btn-rounded-s" :ripple="false"
          @click.stop="$auth.user().type === 'guide' ? $router.push({name: 'edit-report', params:{reportId: report?.slug}}) : null"
      >
        <q-icon name="o_edit"/>
      </q-btn>
    </div>

    <div class="report-card__bottom">
      <div class="tag">
        <q-icon name="o_textsms"/>
        <span>{{comments}}</span>
      </div>
      <div
          v-for="fish in report.general.fishes"
          v-bind:key="fish"
          class="tag"
      >
        <span>{{fish.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReportCard",
  props: [
      'isGuide',
      'name',
      'date',
      'fishes',
      'comments',
      'title',
      'text',
      'cover',
      'avatar',
      'ratio',
      'report'
  ],
})
</script>

<style lang="scss">
  @import "src/css/variables";
  @import "src/css/mixins";

  .report-card__flex-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .report-card {
    cursor: pointer;
    position: relative;
    border-radius: $border-radius;
    overflow: hidden;
    text-decoration: none;
    display: block;

    @media #{$tablet-widths} {
      //max-width: 428px;
    }

    &:hover {
      @media #{$desktop-widths} {
        .report-card__title {
          color: $color-primary-500;
        }

        .report-card__cover img {
          transform: scale(1.125);
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      height: 100px;
      background: linear-gradient(180deg, rgba(0, 1, 41, 0.6) 0%, rgba(0, 1, 41, 0) 100%);
    }
  }

  .report-card__top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    z-index: 2;
  }

  .report-card__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    background: $color-orange;
    color: $color-white;
    @include font(16px, 24px);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  .report-card__info {
    color: $color-white;
  }

  .report-card__author {
    @include font(16px, 24px);
    font-weight: 600;
  }

  .report-card__date {
    @include font(12px, 16px);
  }

  .report-card__cover {
    margin-bottom: 16px;
    border-radius: 16px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      transform: scale(1);
      transition: transform .75s ease-in;
    }
  }

  .report-card__title {
    cursor: pointer;
    @include title();
    @include font(22px, 28px);
    margin-bottom: 4px;
    color: $text-color-primary;
    text-decoration: none;
    @include transition(color);

    @media #{$tablet-widths} {
      @include font(26px, 32px);
      margin-bottom: 8px;
    }
  }

  .report-card__text {
    @include font(12px, 16px);
    color: $text-color-secondary;
    margin-bottom: 16px;

    @media #{$tablet-widths} {
      @include font(16px, 24px);
    }
  }

  .report-card__bottom {
    display: flex;
    align-items: center;
  }
</style>
