<template>
  <div class="guide-card premium">
    <img
      class="guide-card__photo" :src="props.image ? props.image : require('src/assets/images/guide/guide-card-photo.webp')"
      alt="" width="278" height="208" loading="lazy"
    >
    <div class="guide-card__info">
      <div class="guide-card__name">{{ props.title }}</div>
      <div class="guide-card__text">{{ props.text }}</div>

      <slot name="actions" v-if="slots.actions"/>
      <router-link v-else to="/account/subscriptions" class="btn btn-secondary btn-s btn-light border-light">Оформить Premium</router-link>
    </div>
  </div>
</template>

<script setup>
import {useSlots} from "vue";

const slots = useSlots();
const props = defineProps({
  title: {type: String, default: 'Хочу сюда'},
  text: {type: String, default: 'Оплатите Premium-подписку, чтобы Ваш профиль выводился на главной странице'},
  image: {type: String, default: require('src/assets/images/guide/guide-card-photo.webp')}
})
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.guide-card {

  &.premium {
    border: none;

    .guide-card__info {
      background: $color-primary-500;
      text-align: center;
    }

    .guide-card__name {
      color: $color-white;
    }

    .guide-card__text {
      color: $text-color-dark-secondary;
      margin-bottom: 20px;
    }

    .guide-card__photo {
      height: 208px;
      object-fit: cover;
    }
  }
}
</style>
