<template>
  <q-dialog v-model="show_dialog">
    <div class="popup popup-subscription">
      <div class="popup-close">
        <q-icon name="close" v-close-popup />
      </div>
      <div class="popup-content">
        <div class="popup-subscription__icon" v-bind:class="{'success': type==='success', 'error': type==='error'}">
          <q-icon :name="type==='success' ? 'check' : 'close'" />
        </div>
        <h3 class="popup-subscription__text">{{ title }}</h3>
        <div class="popup-subscription__small-text" v-for="(t, i) in text" :key="i">{{
            t
          }}</div>
        <q-btn :ripple="false" class="btn btn-primary btn-m" label="Ок" v-close-popup />
      </div>
    </div>
  </q-dialog>
</template>

<script>
import {defineComponent, ref} from "vue";

export default defineComponent({
  name: 'AlertModal',
  data: () => ({
    show_dialog: false,
    title: "",
    text: [],
    type: "success",
  }),
  methods: {
    async open(type = 'success', title = '', text = []) {
      this.title = title
      this.text = text
      this.type = type
      this.show_dialog = true;
    },
  }
})
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";
.popup-subscription {
  width: 428px;
  max-width: 100%;

  .popup-content {
    display: flex;
    flex-direction: column;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
}

.popup-subscription__icon {
  margin: 0 auto 32px;
  @include size(64px);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;

  &.success {
    background: $color-success;
  }

  &.error {
    background: $color-error;
  }

  i {
    @include font(24px);
  }
}

.popup-subscription__text {
  text-align: center;
}

.popup-subscription__small-text {
  margin-top: 12px;
  text-align: center;
  color: $text-color-secondary;
  @include font(16px,24px);

  + .btn {
    margin-top: 20px;
  }
}
</style>