<template>
  <section class="available-dates">
    <Datepicker
        class="picker"
        :menu-class-name="'picker__menu' + ' ' + menuClassName"
        calendar-class-name="picker__calendar"
        ref="multiCalendar"
        v-model="date"
        :disabled-dates="reserved"
        range
        :multi-calendars="availableWidth"

        placeholder="Дата"
        :format="format"

        :inline="!isInput"
        :month-change-on-scroll="false"
        auto-apply
        hide-offset-dates
        :enable-time-picker="enableTimePicker"

        locale="ru"
        month-name-format="long"
        :day-names="['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']"

        @update:model-value="(d) => {emit('date-update', d)}"
    >
      <template #time-picker>
        <slot name="time-picker"></slot>
      </template>
    </Datepicker>
  </section>
</template>

<script setup>
import {ref} from "vue";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const emit = defineEmits(['date-update']);
const props = defineProps({
  reserved: {
    type: Array,
  },
  isInput: {
    type: Boolean,
    default: false
  },
  menuClassName: {
    type: String,
    default: ' '
  },
  enableTimePicker: {
    type: Boolean,
    default: false,
  },
});

const availableWidth = !process.env.SERVER ? ref(window.innerWidth >= 600) : ref(1024);

const multiCalendar = ref(null);
const date = ref({from: '', to: ''});

let todayDate = new Date();
function availableDates(date) {
  return props.reserved.value.every(d => date !== d) && date > todayDate
}

const format = (date) => {
  let dates = [];
  if (!date.length) dates = [date]
  else dates = date;
  const outputDates = dates.map(d => `${zeroPad(d.getDate())}/${zeroPad(d.getMonth() + 1)}/${zeroPad(d.getFullYear())}`);

  return outputDates.join(' - ');
}
function zeroPad(num) {
  return num < 10 ? '0' + num : num
}


function setPrevMonthView() {
  const newDate = new Date(todayDate.setMonth(todayDate.getMonth() - 1));
  multiCalendar.value.setMonthYear({ month: newDate.getMonth(), year: newDate.getFullYear() })
}
function setNextMonthView() {
  const newDate = new Date(todayDate.setMonth(todayDate.getMonth() + 1));
  multiCalendar.value.setMonthYear({ month: newDate.getMonth(), year: newDate.getFullYear() })
}
function setDate(newDate) {
  date.value = newDate;
}
defineExpose({
  setPrevMonthView, setNextMonthView, setDate
})
</script>

<style scoped lang="scss">
</style>
