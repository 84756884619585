<template>
<div class="banners">
  <q-banner v-if="($auth.canAny(['guide']) && $auth.user().status === 'new')" inline-actions  class="banner">
    <p>Заполните профиль для того чтобы пройти модерацию</p>
    <template v-slot:action>
      <q-btn
        class="btn btn-s btn-secondary"
        @click="$router.push({name: 'account-info'})"
        flat label="Заполнить" />
    </template>
  </q-banner>
  <q-banner v-if="($auth.canAny(['guide']) && $auth.user().status === 'moderation' && showModerationBanner)" inline-actions  class="banner">
    <p>Ваш профиль на модерации, после модерации вы сможете оплатить подписку</p>
    <template v-slot:action>
      <q-btn
        class="btn btn-s btn-secondary"
        @click="showModerationBanner = false"
        flat label="Ок" />
    </template>
  </q-banner>
  <q-banner v-if="($auth.canAny(['guide']) && !$auth.canAny(['site_placement']) && $auth.user().status === 'accepted')" inline-actions  class="banner">
    <p>Оплатите размещение на сайте</p>
    <template v-slot:action>
      <q-btn
        class="btn btn-s btn-secondary"
        @click="$router.push({name: 'account-subscriptions'})"
        flat label="Выбрать план" />
    </template>
  </q-banner>
</div>
</template>

<script>
export default {
  name: "HeaderBanners",
  data() {
    return {
      showModerationBanner: true,
    };
  },
}
</script>

<style scoped lang="scss">
@import "src/css/variables";
@import "src/css/mixins";
.banners {
  width: 100%;
}
.banner {
  padding: 8px 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;

  background: $color-primary-500;

  :deep(.q-banner__content) {
    display: contents;
  }

  p {
    font-family: $font;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: center;
  }

  .btn {
    color: white;

    &:hover {
      color: $color-primary-500;
    }
  }
}
</style>
