<template>
  <q-dialog v-model="bookingPopup">
    <div class="popup popup-booking">
      <div class="popup-close">
        <q-icon name="close" @click="close" v-close-popup/>
      </div>
      <div class="popup-content">
        <h3 class="popup-booking__name">Бронирование</h3>
        <div class="popup-booking__guide">
          <q-avatar class="avatar" size="40px" :class="{'empty' : !booking.profile.user.avatar}">
            <img style="height: 100%; width: auto; max-width: unset;" :src="booking.profile.user.avatar" alt=""
                 v-if="booking.profile.user.avatar">
            <span v-else>{{ booking.profile.user.full_name.charAt(0).toUpperCase() }}</span>
          </q-avatar>
          <div class="popup-booking__guide-info">
            <div class="popup-booking__guide-name">{{ booking.profile.user.full_name }}</div>
            <div class="popup-booking__guide-rating" v-if="booking.profile.stats.rating">
              <q-icon name="star" v-for="n in booking.profile.stats.star_rating" :key="n"/>
              <q-icon name="o_star_border" v-for="n in 5-booking.profile.stats.star_rating" :key="n"/>
            </div>
          </div>
        </div>
        <q-form class="booking-form form">
          <div class="form__item">
            <q-input v-model="dateRange">
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <q-date v-model="date" range mask="YYYY-MM-DD" :options="blockedDates">
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Закрыть" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
          <div class="booking-form__options">
            <q-checkbox
                v-if="booking.profile.services.boat.active"
                v-model="form.transport" :val="true" label="Лодка" class="checkbox"/>
            <q-checkbox
                v-if="booking.profile.services.training.active"
                v-model="form.services.training" val="training" label="Обучение" class="checkbox"/>
            <q-checkbox
                v-if="booking.profile.services.placement.active"
                v-model="form.services.placement" val="placement" label="Размещение" class="checkbox"/>
            <q-checkbox
                v-if="booking.profile.services.gear.active"
                v-model="form.services.gear" val="gear" label="Снасти" class="checkbox"/>
          </div>
          <div class="booking-form__bottom">
            <q-btn class="btn btn-secondary btn-m" @click="close" label="Отменить"/>
            <q-btn class="btn btn-primary btn-m" @click="reserve" label="Забронировать"/>
          </div>
        </q-form>
      </div>
    </div>
  </q-dialog>
</template>

<script>

import moment from "moment";
import { useQuasar } from 'quasar'

export default {
  name: "BookingGuideModal",
  setup () {
    const q = useQuasar()
    return {
      q,
    }
  },
  data() {
    return {
      bookingPopup: false,
      booking: {},
      errors: {},
      water: {},
      date: { from: '', to: '' },
      reserved: [],
      form: {
        reserve_at: [],
        transport: false,
        services: {
          training: false,
          placement: false,
          gear: false,
        }
      }
    };
  },
  computed: {
    dateRange: function () {
      if(this.date?.from) {
        return moment(this.date.from).format('DD.MM.YYYY') + ' - ' + moment(this.date.to).format('DD.MM.YYYY')
      } else if(this.date) {
        return moment(this.date).format('DD.MM.YYYY')
      }
      return '';
    }
  },
  mounted() {
    this.date = {
      from: moment().format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    }
  },
  methods: {
    async open(options = {}, water = {}) {
      this.bookingPopup = true
      this.booking = options
      // console.log(this.booking)
      this.water = water
      if (water.id) {
        this.form.guide_profile_water_id = water.id
      }

      await this.$api.get(`client/guide/${this.booking.profile.user.id}/reserved-days`).then(res => {
        this.reserved = res.data;
      })

      await this.$api.get(`client/guide/${this.booking.profile.user.id}/price-per-day`).then(res => {
        //цена за день
      })

    },
    async close() {
      this.bookingPopup = false
    },
    blockedDates (date) {
      return moment.now() < moment(date) && this.reserved.indexOf(date) === -1
    },
    reserve() {
      if (typeof ym !== undefined) {
        ym(45019072, 'reachGoal', 'target3-3')
      }
      let data = this.form;
      data.reserve_at = [];
      if(this.date?.from) {
        data.reserve_at.push(this.date?.from)
        data.reserve_at.push(this.date?.to)
      } else {
        data.reserve_at.push(this.date)
      }
      this.$api.post(`client/guide/${this.booking.profile.user.id}/reserve`, data).then(res => {
        if (typeof ym !== undefined) {
          ym(45019072, 'reachGoal', 'target3-2')
        }
        this.close();
        this.$emit('successReserve')
        // надо перебрасывать на чат с гидом. просто на страницу чата
      }).catch(e => {
        this.errors = e.response.data.errors
        if(this.errors?.reserve_at) {
          this.q.notify({
            type: 'negative',
            timeout: 2000,
            message: this.errors?.reserve_at
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.avatar {
  margin-right: 20px;
  background: #F2BD00;
  border-radius: 50%;
  color: #FFFFFF;
}
</style>
