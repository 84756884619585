<template>
  <div class="tour-card" :class="{'sharing' : isSharing}">
    <div class="tour-card__hot" v-if="isHot">
      <q-icon name="o_local_fire_department" />
      <span>Горящий -{{hotDiscount}}%</span>
    </div>
    <div class="tour-card__cover">
      <img v-if="cover" :src="cover" alt="">
      <img v-else :src="require(`../assets/images/tour-card/tour-card-cover.webp`)" alt="">
    </div>
    <div class="tour-card__info">
      <router-link to="#" @click.stop.prevent="this.$emit('emitDetails')" class="tour-card__link">
        <h4 class="tour-card__name">{{name}}</h4>
      </router-link>
      <div class="tour-card__place">{{place}}</div>
      <div class="tour-card__features">
        <div class="tour-card__features-title">Включено:</div>
        <div class="tour-card__features-items">{{features}}</div>
      </div>
      <div class="tour-card__date">{{dates}}</div>
    </div>
    <div class="tour-card__slots" v-if="isSharing">
      <div class="tour-card__members" v-if="tour.clients && tour.clients.length > 0">
        <div class="tour-card__member" v-for="client in tour.clients" :key="client.id">
          <img v-if="client.avatar" :src="client.avatar"  alt="">
          <span v-else>{{client.full_name.charAt(0)}}</span>
        </div>
      </div>
      <div class="tour-card__slots-count" v-if="tour.share_places_left">{{ tour.share_places_left }}</div>
    </div>
    <div class="tour-card__bottom">
      <div class="tour-card__price" v-if="!isSharing">
        <div class="tour-card__price-full" v-if="oldPrice !== newPrice.split(' ').join('')">{{oldPrice}}</div>
        <div class="tour-card__price-value">{{newPrice}}</div>
      </div>
      <div class="tour-card__price" v-if="isSharing">
        <div class="tour-card__price-single">
          <div class="tour-card__price-single-wrapper">
            <div class="tour-card__price-type">общая</div>
            <span>{{singlePrice}}</span>
          </div>
        </div>
        <div class="tour-card__price-sharing">
          <div class="tour-card__price-type">на человека</div>
          <span>{{sharingPrice}}</span>
        </div>
      </div>
<!--      <div class="tour-card__price-btn" @click="reserve(tour.id)" v-if="$auth.canAny(['fisherman', 'guest'])">-->
<!--        <q-btn class="btn btn-primary btn-s" :ripple=false>-->
<!--          <q-icon name="o_calendar_today" />-->
<!--          <span>Забронировать</span>-->
<!--        </q-btn>-->
<!--      </div>-->
      <div class="tour-card__bottom-btns">
        <div @click.stop="this.$emit('emitDetails')" class="tour-card__price-btn">
          <q-btn class="btn btn-primary btn-s" :ripple=false>
  <!--          <q-icon name="o_calendar_today" />-->
            <span>Открыть</span>
          </q-btn>
        </div>
        <div class="tour-card__options" v-if="isEdit">
          <q-btn class="btn btn-rounded btn-secondary icon-primary btn-rounded-s" :ripple="false" @click="options = !options">
            <q-icon name="o_more_horiz" />
          </q-btn>
          <div class="tour-card__options-block" v-if="options">
            <div class="tour-card__options-item" @click.stop="this.$emit('emitEdit')" >
              <q-icon name="o_create" />
              <span>Редактировать</span>
            </div>
            <div class="tour-card__options-item" @click.stop="this.$emit('emitDelete')">
              <q-icon name="o_delete" />
              <span>Удалить</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "TourCard",
  emits: ['emitDetails', 'emitEdit', 'emitDelete'],
  props: [
      'isEdit',
      'isHot',
      'hotDiscount',
      'isSharing',
      'name',
      'place',
      'oldPrice',
      'newPrice',
      'singlePrice',
      'sharingPrice',
      'cover',
      'features',
      'dates',
      'tour'
  ],
  methods: {
    reserve(id) {
      this.$api.post(`client/tour/${this.tour.id}/reserve`).then(res => {
        this.$emit('update')
      })
    }
  },
  data () {
    return {
      options: false
    }
  },
});
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.tour-card {
  width: 280px;
  border: $border;
  border-radius: $border-radius;
  position: relative;

  &.sharing {
    width: 100%;
  }

  @media #{$tablet-widths} {
    width: 312px;
  }
}

.tour-card__hot {
  position: absolute;
  left: -1px;
  top: -1px;
  background: $color-red;
  padding: 8px 12px 8px 8px;
  display: flex;
  align-items: center;
  color: $color-white;
  border-radius: 16px 0 16px 0;
  @include font(12px);
  font-weight: 600;

  i {
    @include font(20px);
    margin-right: 4px;
  }
}

.tour-card__cover {
  width: calc(100% + 2px);
  margin: -1px -1px 16px -1px;
  height: 140px;
  border-radius: 16px 16px 0 0;
  overflow: hidden;

  img {
    @include size(100%);
    object-fit: cover;
  }
}

.tour-card__link {
  text-decoration: none;
  color: $text-color-primary;
  @include transition(color);

  @media #{$desktop-widths} {
    &:hover {
      color: $color-primary-500;
    }
  }
}

.tour-card__info {
  padding: 0 16px 20px;
  border-bottom: $border;
}

.tour-card__name {
  margin-bottom: 4px;
}

.tour-card__place {
  color: $text-color-secondary;
  @include font(16px, 24px);
  margin-bottom: 16px;
}

.tour-card__features {
  margin-bottom: 16px;
}

.tour-card__features-title {
  color: $text-color-primary;
  @include font(16px, 24px);
  font-weight: 600;
}

.tour-card__features-items {
  height: 48px;
  overflow: auto;
  color: $text-color-secondary;
  @include font(16px, 24px);
}

.tour-card__date {
  color: $text-color-primary;
  @include font(16px, 24px);
  font-weight: 600;
}

.tour-card__slots {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  border-bottom: $border;
}

.tour-card__members {
  display: flex;
  align-items: center;
  padding-right: 16px;
}

.tour-card__member {
  @include size(36px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $color-white;
  background: $color-green;
  color: $color-white;

  & + & {
    margin-left: -10px;
  }
}

.tour-card__slots-count {
  @include font(14px, 24px);
  margin-left: auto;
  color: $text-color-secondary;
}

.tour-card__bottom {
  padding: 16px 16px 20px;
}

.tour-card__price {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
}

.tour-card__price-full {
  color: $text-color-disable;
  text-decoration: line-through;
  @include font(20px, 28px);
  font-weight: 600;

  & + .tour-card__price-value {
    margin-left: 12px;
  }
}

.tour-card__price-value {
  color: $color-primary-500;
  @include font(20px, 28px);
  font-weight: 600;
}

.tour-card__price-btn {
  .btn {
    width: 100%;
  }
}

.tour-card__price-single {
  width: calc(50% - 14px);
  display: flex;
  justify-content: flex-end;

  span {
    color: $text-color-primary;
    font-weight: 600;
    @include font(20px, 28px);
  }

  & + .tour-card__price-sharing {
    margin-left: 28px;
  }
}

.tour-card__price-sharing {
  width: calc(50% - 14px);

  span {
    color: $color-primary-500;
    font-weight: 600;
    @include font(20px, 28px);
  }
}

.tour-card__price-type {
  color: $text-color-tertiary;
  @include font(16px, 24px);
}

.tour-card__price-btn {
  flex-grow: 1;
}

.tour-card__options {
  position: relative;
  margin-left: 12px;
}

.tour-card__companion-name {
  @include font(16px, 24px);
  color: $text-color-primary;
  font-weight: 600;
  margin-right: 12px;

  @media #{$tablet-widths} {
    @include font(20px, 28px);
  }
}

.tour-card__options-block {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background: $color-white;
  border-radius: 8px;
  box-shadow: $shadow;
  padding: 8px 0;
  z-index: 10;
}

.tour-card__options-item {
  padding: 8px 12px;
  background: transparent;
  color: $text-color-primary;
  font-weight: 600;
  cursor: pointer;
  @include font(16px, 24px);
  white-space: nowrap;
  transition: $transition;
  transition-property: color, background;

  &:hover {
    background: $color-surface-grey;
    color: $color-primary-500;

    i {
      color: $color-primary-500;
    }
  }

  i {
    @include font(24px);
    margin-right: 12px;
    color: $text-color-primary;
    @include transition(color);
  }
}

.tour-card__bottom-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

</style>
