<template>
  <section class="gallery">
    <div class="wrapper">
      <h2 class="gallery__title">Фото и видео</h2>
      <div class="gallery-carousel">
        <div class="gallery-carousel__navigation next swiper-button">
          <q-icon name="arrow_forward"/>
        </div>
        <div class="gallery-carousel__navigation prev swiper-button">
          <q-icon name="arrow_back"/>
        </div>
        <q-no-ssr>
          <swiper

              :slides-per-view="'auto'"
              :space-between="16"
              :free-mode="{enabled: true, sticky: false}"
              :navigation="{
                  'prevEl': '.gallery-carousel__navigation.prev',
                  'nextEl': '.gallery-carousel__navigation.next'
                }"
              :breakpoints="{
                  1023  : {
                    'slidesPerView': slidesDesktop || 3,
                    'spaceBetween': 20
                  },
                  1439  : {
                    'slidesPerView': slidesDesktopL || 4,
                    'spaceBetween': 20
                  }
                }"
          >
            <swiper-slide :lazy="true" class="slide" v-for="(slide) in [...slides].sort((a, b) => b.id - a.id)" :key="slide">
              <fancybox
                  class="gallery-item"
                  v-if="httpSrc && !slide.youtube_id"
                  :href="slide.src_full"
                  data-fancybox="gallery">
                <img :src="slide.src" loading="lazy">
              </fancybox>
              <fancybox
                  class="gallery-item"
                  v-else-if="!slide.youtube_id"
                  :href="require(`../assets/images/gallery/${slide.src_full}`)"
                  data-fancybox="gallery">
                <img :src="require(`../assets/images/gallery/${slide.src}`)" loading="lazy">
              </fancybox>
              <fancybox
                  class="gallery-item"
                  v-else-if="slide.youtube_id"
                  data-fancybox="gallery"
                  :href="`https://www.youtube.com/embed/${slide.youtube_id}`"

              >
                <iframe
                    :src="`https://www.youtube.com/embed/${slide.youtube_id}`"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
              </fancybox>
              <div v-if="isEdit" class="delete-screen">
                <div @click="deleteMedia(slide.id, $event)" class="delete-screen__btn">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M17 6H22V8H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V8H2V6H7V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H16C16.2652 2 16.5196 2.10536 16.7071 2.29289C16.8946 2.48043 17 2.73478 17 3V6ZM18 8H6V20H18V8ZM9 11H11V17H9V11ZM13 11H15V17H13V11ZM9 4V6H15V4H9Z"
                        fill="white" fill-opacity="0.7"/>
                  </svg>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide  :lazy="true" v-for="slide in templateSlides" :key="slide">
              <img class="placeholder" src="../assets/images/gallery/gallery-placeholder.png" loading="lazy">
            </swiper-slide>
            <swiper-slide style="order: -1; display: flex; column-gap: 20px" class="slide" v-if="isEdit">
              <div class="slide slide_short">
                <q-uploader
                    ref="fileUploader"

                    :factory="uploadFactory"
                    auto-upload

                    accept=".jpeg, .jpg, .png"
                    class="uploader"

                    @added="addHandler"
                    @removed="removeHandler"
                    @finish="finishHandler"
                >
                  <template v-slot:header="scope">
                    <div v-if="!imgCounter" @click="scope.pickFiles" id="start">
                      <q-uploader-add-trigger/>
                      <div class="uploader__add-block">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M13 19.1L15 17.1L20.5 22.6L24 19.1L27 22.1V13H13V19.1ZM13 21.929V27H16.1L19.086 24.015L15 19.929L13 21.929ZM18.929 27H27V24.929L24 21.929L18.929 27ZM12 11H28C28.2652 11 28.5196 11.1054 28.7071 11.2929C28.8946 11.4804 29 11.7348 29 12V28C29 28.2652 28.8946 28.5196 28.7071 28.7071C28.5196 28.8946 28.2652 29 28 29H12C11.7348 29 11.4804 28.8946 11.2929 28.7071C11.1054 28.5196 11 28.2652 11 28V12C11 11.7348 11.1054 11.4804 11.2929 11.2929C11.4804 11.1054 11.7348 11 12 11ZM23.5 18C23.1022 18 22.7206 17.842 22.4393 17.5607C22.158 17.2794 22 16.8978 22 16.5C22 16.1022 22.158 15.7206 22.4393 15.4393C22.7206 15.158 23.1022 15 23.5 15C23.8978 15 24.2794 15.158 24.5607 15.4393C24.842 15.7206 25 16.1022 25 16.5C25 16.8978 24.842 17.2794 24.5607 17.5607C24.2794 17.842 23.8978 18 23.5 18Z"
                              fill="#000129"/>
                        </svg>
                        <div class="uploader__add-block-text"><span>Нажмите на ссылку</span>, чтобы выбрать фотографию&nbsp;или
                          просто перетащите её сюда
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:list="scope">
                    <div class="photo-list__link" v-for="file in scope.files" :key="file.__key"
                         :data-photo-id="file.__key">
                      <q-item-label v-if="file.__status === 'idle'" caption>
                        <q-spinner-facebook class="uploader__spinner" size="54px" color="light-blue" />
                      </q-item-label>
                    </div>
                  </template>
                </q-uploader>
              </div>
              <div
                  v-if="isEdit"
                  class="slide slide_short"
                  @click="showVideoPopup = true"
              >
                <div class="uploader__add-block add-video">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M24.394 20L18 15.737V24.263L24.394 20ZM27.376 20.416L16.777 27.482C16.7017 27.5321 16.6142 27.5608 16.5239 27.5652C16.4336 27.5695 16.3437 27.5492 16.264 27.5065C16.1843 27.4639 16.1176 27.4003 16.0711 27.3228C16.0246 27.2452 16 27.1564 16 27.066V12.934C16 12.8436 16.0246 12.7548 16.0711 12.6772C16.1176 12.5997 16.1843 12.5361 16.264 12.4935C16.3437 12.4508 16.4336 12.4305 16.5239 12.4348C16.6142 12.4392 16.7017 12.4679 16.777 12.518L27.376 19.584C27.4445 19.6297 27.5006 19.6915 27.5395 19.7641C27.5783 19.8367 27.5986 19.9177 27.5986 20C27.5986 20.0823 27.5783 20.1633 27.5395 20.2359C27.5006 20.3085 27.4445 20.3703 27.376 20.416Z"
                        fill="#000129"/>
                  </svg>

                  <div class="uploader__add-block-text"><span>Нажмите на ссылку</span>, чтобы загрузить видео с Youtube
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </q-no-ssr>
      </div>
    </div>
    <q-dialog
        v-if="isEdit"
        v-model="showVideoPopup"
    >
      <div class="popup popup-edit">
        <div class="popup-close">
          <q-icon name="close" v-close-popup/>
        </div>
        <div class="popup-content">
          <h3 class="popup-edit__name">Видео</h3>
          <q-form
              class="form"
              autocorrect="off"
              autocapitalize="off"
              autocomplete="off"
              spellcheck="false"
          >
            <div class="form__item">
              <q-input
                  type="text"
                  v-model="videoLink"
                  label="Ссылка" class="input"/>
            </div>
            <div class="form__bottom space-between popup-edit__bottom">
              <q-btn class="btn btn-secondary btn-m" :ripple="false" v-close-popup label="Отменить"/>
              <q-btn class="btn btn-primary btn-m" @click="saveVideo" :ripple="false" label="Сохранить"/>
            </div>
          </q-form>
        </div>
      </div>
    </q-dialog>
  </section>
</template>

<script>
import {defineComponent} from "vue";
import {Fancybox} from "@fancyapps/ui";


import {useProfile} from "src/stores/profile";


const store = useProfile();

export default defineComponent({
  name: 'Gallery',
  components: {
  },
  emits: ['mediaUpdated'],
  props: {
    httpSrc: {
      type: Boolean,
      default: false,
    },
    slidesDesktop: {required: false},
    slidesDesktopL: {required: false},
    slides: {required: false},
    water: {required: false},
    isEdit: {required: false},
  },
  setup() {
    const store = useProfile()
    return {store}
  },
  data: () => ({
    imgCounter: 0,
    showVideoPopup: false,
    videoLink: '',
  }),
  computed: {
    urlPart: function () {
      if(this.$auth.canAny(['fisherman'])) return 'client';
      if(this.$auth.canAny(['guide'])) return 'guide';
      return 'guide';
    },
    templateSlides: function () {
      return (3 - this.slides.length > 0) && !this.isEdit ? 3 - this.slides.length : 0;
    },
    addSlides: function () {
      if ((3 - this.slides.length > 0) && this.isEdit) {
        return 3 - this.slides.length
      } else if (this.isEdit) {
        return 1
      } else {
        return 0
      }
    },
  },
  methods: {
    async saveVideo() {
      let data = new FormData()
      data.append('_method', 'patch');
      data.append('profile[files][0][youtube_id]', this.videoLink);
      await this.$api.post(`${this.urlPart}/profile/media`, data).then(response => {
        this.showVideoPopup = false;
        this.videoLink = '';

        this.$emit('mediaUpdated');
      }).catch(error => {
        if (error) {
          console.log(error);
        }
      })
    },
    async deleteMedia(id, event) {
      await this.$api.delete(`${this.urlPart}/profile/media/${id}`).then((res) => {
        if (!event.target.closest('.slide')) return
        event.target.closest('.slide').style.display = 'none';
        this.$emit('mediaUpdated');
      }).catch((err) => {
        console.log(err)
      })
    },
    addHandler(files) {
      this.imgCounter += files.length;
    },
    removeHandler() {
      this.imgCounter--;
    },
    finishHandler() {
      this.$refs.fileUploader.removeQueuedFiles()
    },
    uploadFactory(file) {
      let data = new FormData()
      data.append('profile[files][0][file]', file[0]);
      data.append('_method', 'patch');
      return new Promise((resolve, reject) => {
        this.$api.post(`${this.urlPart}/profile/media`, data).then(response => {

          this.$emit('mediaUpdated');
          resolve(null);
        }).catch(error => {
          if (error) {
            console.log(error);
          }
        })
      })
    },
  }
})

</script>

<style lang="scss" scoped>
@import "src/css/variables";
@import "src/css/mixins";
@import "@fancyapps/ui/dist/fancybox.css";

.swiper-slide.slide {
  margin-right: 16px;

  @media #{$tablet-widths} {
    margin-right: 20px;
  }
  @media #{$desktop-widths} {
    margin-right: 20px;
  }
}
.uploader__spinner {
  color: $color-primary-500 !important;
}
.slide_short {
  width: 150px;
}

.swiper-slide {
  &:hover {
    .delete-screen {
      opacity: 1;
    }
  }
}

.delete-screen {
  transition: opacity .2s;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  &__btn {
    cursor: pointer;
    padding: 12px;
    display: grid;
    place-content: center;

    width: 48px;
    height: 48px;
    background: rgba(0, 0, 0, .2);
    border-radius: 0px 16px;
  }
}

#start {
  transition: .2s;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  max-height: 700px;

  background: transparent;

  > svg {
    fill: $text-color-primary;
    margin-bottom: 24px;
  }

  > h4 {
    color: $text-color-primary;
    margin-bottom: 8px;
    text-align: center;
  }

  > p {
    color: $text-color-primary;
    text-align: center;
  }
}

.uploader {
  box-shadow: none;
  overflow: hidden;
  width: 100%;
  height: 100%;

  border: 2px dashed $color-border;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: transparent;
  color: #191927;
  transition: color 0.3s ease-in, background 0.3s ease-in;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  :deep() {
    .q-uploader__list {
      padding: 0;
      z-index: 2;
      flex: unset;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px #9773FF;
      }
    }

    .q-uploader__header {
      height: 100%;
      position: absolute;
      inset: 0;
      z-index: 1;
      background: transparent;

      &::before {
        opacity: 0;
      }
    }

    .q-uploader__dnd, .q-uploader__overlay {
      display: none;
    }
  }

  &__add-block {
    width: 100%;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 40px 16px;
    background: transparent;
    color: $text-color-primary;
    transition: color $transition, background $transition;
    @include font(12px, 16px);
    text-align: center;

    @media #{$tablet-widths} {
      padding: 40px 16px;
      height: 388px;
    }

    span {
      color: $color-primary-500;
      cursor: pointer;
    }

    i {
      @include font(40px);
      margin-bottom: 12px;
      color: $text-color-tertiary;
      @include transition(color);
    }
  }

  &__add-block-text {
    max-width: 360px;
  }
}

.add-video {
  cursor: pointer;
  height: 100%;
  border: 2px dashed $color-border;
  border-radius: 16px;
}

.photo-list {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 8px;

  &__link {
    //max-width: fit-content; last children
    position: relative;
    display: block;
    flex-grow: 1;
  }
}

.photo {
  border-radius: 12px;
  display: block;
  height: 100%;

  &:hover {
    .photo__content {
      opacity: 1;
    }
  }

  :deep() {
    > div {
      padding-bottom: 0 !important;
    }

    .q-img__container {
      position: relative;

      & > img {
        border-radius: 12px;
        width: 100%;
      }
    }
  }

  &__skeleton {
    position: absolute;
    inset: 0;

    &::after {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0), #342F4A, rgba(255, 255, 255, 0));
    }
  }

  &__fill-status {
    position: absolute;
    right: unset;
    top: unset;
    left: 0;
    bottom: 0;
    z-index: 4;
    padding: 9px 12px;
    border-radius: 0px 12px;
  }

  &__loader {
    position: absolute;
    z-index: 1;
    inset: 0;
    display: grid;
    place-content: center;

    fill: white;
  }

  &__content {
    transition: $transition;
    opacity: 0;

    position: absolute;
    inset: 0;

    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    border-radius: 12px;
  }

  &__bottom {
    align-self: flex-end;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 8px;
  }
}


.gallery {
  margin-bottom: 40px;
  overflow: hidden;

  @media #{$desktop-widths} {
    margin-bottom: 100px;
  }
}

.gallery__title {
  margin-bottom: 24px;

  @media #{$desktop-widths} {
    margin-bottom: 48px;
  }
}

.gallery-carousel {
  position: relative;

  .swiper {
    overflow: visible;

    @media #{$desktop-widths} {
      overflow: hidden;
    }
  }

  .swiper-slide {
    width: auto;
    border-radius: $border-radius;
    overflow: hidden;
    @include size(312px, 237px);

    img, iframe {
      @include size(100%);
      object-fit: cover;

      &.placeholder {
        object-fit: initial;
      }
    }
  }
}

.gallery-carousel__navigation {
  display: none;

  @media #{$desktop-widths} {
    display: flex;
    position: absolute;
    bottom: calc(100% + 48px);

    &.next {
      right: 0;
    }

    &.prev {
      right: 68px;
    }
  }
}

.add-slide {
  border: 2px dashed #E5E6F2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  height: 232px;
  cursor: pointer;

  i {
    @include font(24px)
  }
}

.gallery-item {
  position: relative;
  display: block;
  @include size(100%);

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
}
</style>
