<template>
    <q-dialog v-model="show_dialog">
      <q-card style="width: 600px; max-width: 60vw">
        <q-card-section>
          <q-btn round flat dense icon="close" class="float-right" color="grey-8" v-close-popup></q-btn>
          <div class="text-h6">{{modalTitle}}</div>
        </q-card-section>
        <q-separator inset></q-separator>
        <q-card-section class="q-pt-none">
          <q-form class="q-gutter-md">
            <q-list>
              <q-item>
                <q-item-section>
                  <q-item-label class="q-pb-xs">Название</q-item-label>
                  <q-input dense outlined
                           v-model="editedItem.name"
                           :error-message="errors.name"
                           :error="!!errors.name" />
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="q-pb-xs">Описание</q-item-label>
                  <q-input autogrow dense outlined v-model="editedItem.description" />
                </q-item-section>
              </q-item>
            </q-list>
          </q-form>
        </q-card-section>
        <q-card-section>
          <q-card-actions align="right">
            <q-btn
              flat
              label="Отмена"
              color="warning"
              dense
              v-close-popup
            ></q-btn>
            <q-btn
              flat
              label="Сохранить"
              color="primary"
              dense
              @click="save"
            ></q-btn>
          </q-card-actions>
        </q-card-section>
      </q-card>
    </q-dialog>
</template>


<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FishTypeModal",
  watch: {
    show_dialog(v) {
      this.errors = {}
    }
  },
  computed: {
    modalTitle() {
      if(this.editedItem.id) return 'Редактировать';
      return 'Создать';
    }
  },
  data: () => ({
    show_dialog: false,
    editedItem: {},
    errors: {},
  }),
  methods:{
    async open(options) {
      this.show_dialog = true
      this.editedItem = options
    },
    editRow(props) {
      this.show_dialog = true
      this.editedItem = props.row;
    },
    save() {
      if(this.editedItem.id) {
        this.update()
      } else {
        this.store()
      }
    },
    async update() {
      await this.$api.patch(`admin/reference/fish-classification/${this.editedItem.id}`, this.editedItem)
        .then((res) => {
          this.show_dialog = false
        })
        .catch(e => {
          this.errors = e.response.data.errors
        });
    },
    async store() {
      await this.$api.post(`admin/reference/fish-classification`, this.editedItem)
        .then((res) => {
          this.show_dialog = false
          this.$emit('update');
        })
        .catch(e => {
          this.errors = e.response.data.errors
        });
    },
    async delete(props) {
      await this.$api.delete(`admin/reference/fish-classification/${props.id}`);
      this.$emit('update');
    }
  },
});
</script>
