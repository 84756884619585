<template>
  <svg class="preloader" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="preloader__spinner" d="M50 2C50 0.895431 50.8959 -0.00417567 51.9996 0.0399906C60.5241 0.381117 68.8319 2.89879 76.1249 7.36799C83.9872 12.186 90.364 19.0844 94.5503 27.3005C98.7366 35.5165 100.569 44.7303 99.8459 53.923C99.1224 63.1156 95.8709 71.9292 90.4509 79.3893C85.0308 86.8493 77.6534 92.6652 69.1342 96.194C60.615 99.7227 51.2859 100.827 42.1783 99.3844C33.0707 97.9419 24.5394 94.0089 17.5276 88.0203C11.0235 82.4652 6.06177 75.3421 3.10312 67.3402C2.72005 66.3042 3.29878 65.1741 4.34929 64.8328C5.3998 64.4915 6.52396 65.0683 6.91062 66.103C9.63894 73.4038 14.1822 79.9027 20.1254 84.9787C26.5762 90.4882 34.425 94.1066 42.804 95.4337C51.183 96.7608 59.7658 95.7449 67.6034 92.4985C75.4411 89.252 82.2283 83.9014 87.2148 77.0381C92.2012 70.1749 95.1926 62.0664 95.8582 53.6091C96.5238 45.1518 94.8377 36.6752 90.9863 29.1164C87.1349 21.5576 81.2682 15.2111 74.0349 10.7786C67.3709 6.69483 59.786 4.38219 51.9995 4.04347C50.896 3.99546 50 3.10457 50 2Z" fill="url(#paint0_linear_1782_19129)"/>
    <path class="preloader__anchor" fill-rule="evenodd" clip-rule="evenodd" d="M51.3332 42.0001C51.3332 44.4852 49.6335 46.5733 47.3332 47.1654V56.0001C47.3332 58.5774 49.4225 60.6667 51.9998 60.6667C54.5772 60.6667 56.6665 58.5774 56.6665 56.0001V52.6667H53.9998V50.0001H59.3332V56.0001C59.3332 60.0502 56.0499 63.3334 51.9998 63.3334C47.9497 63.3334 44.6665 60.0502 44.6665 56.0001V47.1654C42.3662 46.5733 40.6665 44.4852 40.6665 42.0001C40.6665 39.0546 43.0543 36.6667 45.9998 36.6667C48.9454 36.6667 51.3332 39.0546 51.3332 42.0001ZM45.9998 44.6667C47.4726 44.6667 48.6665 43.4728 48.6665 42.0001C48.6665 40.5273 47.4726 39.3334 45.9998 39.3334C44.5271 39.3334 43.3332 40.5273 43.3332 42.0001C43.3332 43.4728 44.5271 44.6667 45.9998 44.6667Z" fill="#2329C7"/>
    <defs>
      <linearGradient id="paint0_linear_1782_19129" x1="54" y1="-4.5" x2="-13" y2="75.5" gradientUnits="userSpaceOnUse">
        <stop stop-color="#2329C7"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "PreloaderSpinner"
}
</script>

<style scoped>

</style>