<template>
    <q-dialog v-model="show_dialog">
      <q-card style="width: 600px; max-width: 60vw">
        <q-card-section>
          <q-btn round flat dense icon="close" class="float-right" color="grey-8" v-close-popup></q-btn>
          <div class="text-h6">{{modalTitle}}</div>
        </q-card-section>
        <q-separator inset></q-separator>
        <q-card-section class="q-pt-none">
          <q-tabs
            v-model="tab"
            dense
            align="justify"
            narrow-indicator
          >
            <q-tab name="main" label="Основное" />
            <q-tab name="seo" label="SEO" />
          </q-tabs>

          <q-separator />

          <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="main">
              <div class="text-h6">Основное</div>
              <q-form class="q-gutter-md">
                <q-list>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Активна</q-item-label>
                      <q-toggle
                        :true-value="1"
                        :false-value="0"
                        v-model="editedItem.active" />
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Доступна для участия в турнире</q-item-label>
                      <q-toggle
                        :true-value="1"
                        :false-value="0"
                        v-model="editedItem.allow_competition" />
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Классификация рыбы</q-item-label>
                      <q-select
                        :behavior="$q.platform.is.ios === true ? 'menu' : 'default'"
                        dense
                        outlined
                        :options="classifications"
                        v-model="editedItem.fish_classification_id"
                        @input-value="filterClassifications"
                        option-value="id"
                        option-label="name"
                        use-input
                        emit-value
                        map-options
                        hint="Text autocomplete"
                      >
                        <template v-slot:no-option>
                          <q-item>
                            <q-item-section class="text-grey">
                              No results
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Название</q-item-label>
                      <q-input dense outlined
                               v-model="editedItem.name"
                               :error-message="errors.name"
                               :error="!!errors.name" />
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Описание</q-item-label>
                      <q-editor
                        v-model="editedItem.description"
                        :error-message="errors.description"
                        :error="!!errors.description"
                        :dense="$q.screen.lt.md"
                        :toolbar="[
                          [
                            {
                              label: $q.lang.editor.align,
                              icon: $q.iconSet.editor.align,
                              fixedLabel: true,
                              list: 'only-icons',
                              options: ['left', 'center', 'right', 'justify']
                            },
                            {
                              label: $q.lang.editor.align,
                              icon: $q.iconSet.editor.align,
                              fixedLabel: true,
                              options: ['left', 'center', 'right', 'justify']
                            }
                          ],
                          ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
                          ['token', 'hr', 'link', 'custom_btn'],
                          ['print', 'fullscreen'],
                          [
                            {
                              label: $q.lang.editor.formatting,
                              icon: $q.iconSet.editor.formatting,
                              list: 'no-icons',
                              options: [
                                'p',
                                'h1',
                                'h2',
                                'h3',
                                'h4',
                                'h5',
                                'h6',
                                'code'
                              ]
                            },
                            {
                              label: $q.lang.editor.fontSize,
                              icon: $q.iconSet.editor.fontSize,
                              fixedLabel: true,
                              fixedIcon: true,
                              list: 'no-icons',
                              options: [
                                'size-1',
                                'size-2',
                                'size-3',
                                'size-4',
                                'size-5',
                                'size-6',
                                'size-7'
                              ]
                            },
                            {
                              label: $q.lang.editor.defaultFont,
                              icon: $q.iconSet.editor.font,
                              fixedIcon: true,
                              list: 'no-icons',
                              options: [
                                'default_font',
                                'arial',
                                'arial_black',
                                'comic_sans',
                                'courier_new',
                                'impact',
                                'lucida_grande',
                                'times_new_roman',
                                'verdana'
                              ]
                            },
                            'removeFormat'
                          ],
                          ['quote', 'unordered', 'ordered', 'outdent', 'indent'],

                          ['undo', 'redo'],
                          ['viewsource']
                        ]"
                        :fonts="{
                          arial: 'Arial',
                          arial_black: 'Arial Black',
                          comic_sans: 'Comic Sans MS',
                          courier_new: 'Courier New',
                          impact: 'Impact',
                          lucida_grande: 'Lucida Grande',
                          times_new_roman: 'Times New Roman',
                          verdana: 'Verdana'
                        }"
                      />
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Дополнительное описание</q-item-label>
                      <q-editor
                        v-model="editedItem.extra_description"
                        :error-message="errors.extra_description"
                        :error="!!errors.extra_description"
                        :dense="$q.screen.lt.md"
                        :toolbar="[
                          [
                            {
                              label: $q.lang.editor.align,
                              icon: $q.iconSet.editor.align,
                              fixedLabel: true,
                              list: 'only-icons',
                              options: ['left', 'center', 'right', 'justify']
                            },
                            {
                              label: $q.lang.editor.align,
                              icon: $q.iconSet.editor.align,
                              fixedLabel: true,
                              options: ['left', 'center', 'right', 'justify']
                            }
                          ],
                          ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
                          ['token', 'hr', 'link', 'custom_btn'],
                          ['print', 'fullscreen'],
                          [
                            {
                              label: $q.lang.editor.formatting,
                              icon: $q.iconSet.editor.formatting,
                              list: 'no-icons',
                              options: [
                                'p',
                                'h1',
                                'h2',
                                'h3',
                                'h4',
                                'h5',
                                'h6',
                                'code'
                              ]
                            },
                            {
                              label: $q.lang.editor.fontSize,
                              icon: $q.iconSet.editor.fontSize,
                              fixedLabel: true,
                              fixedIcon: true,
                              list: 'no-icons',
                              options: [
                                'size-1',
                                'size-2',
                                'size-3',
                                'size-4',
                                'size-5',
                                'size-6',
                                'size-7'
                              ]
                            },
                            {
                              label: $q.lang.editor.defaultFont,
                              icon: $q.iconSet.editor.font,
                              fixedIcon: true,
                              list: 'no-icons',
                              options: [
                                'default_font',
                                'arial',
                                'arial_black',
                                'comic_sans',
                                'courier_new',
                                'impact',
                                'lucida_grande',
                                'times_new_roman',
                                'verdana'
                              ]
                            },
                            'removeFormat'
                          ],
                          ['quote', 'unordered', 'ordered', 'outdent', 'indent'],

                          ['undo', 'redo'],
                          ['viewsource']
                        ]"
                        :fonts="{
                          arial: 'Arial',
                          arial_black: 'Arial Black',
                          comic_sans: 'Comic Sans MS',
                          courier_new: 'Courier New',
                          impact: 'Impact',
                          lucida_grande: 'Lucida Grande',
                          times_new_roman: 'Times New Roman',
                          verdana: 'Verdana'
                        }"
                      />
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Способы ловли</q-item-label>
                      <q-select
                        :behavior="$q.platform.is.ios === true ? 'menu' : 'default'"
                        label="Способы ловли"
                        :loading="methodLoader"
                        dense
                        outlined
                        :options="methods"
                        v-model="editedItem.methods"
                        @input-value="filterMethods"
                        option-value="id"
                        option-label="name"
                        use-input
                        emit-value
                        map-options
                        multiple
                        use-chips
                        clearable
                        @filter="filterFn"
                        :error-message="errors.methods" :error="!!errors.methods"
                        hint="Выберите рыбу относящуюся к водоему"
                      >
                        <template v-slot:no-option>
                          <q-item>
                            <q-item-section class="text-grey">
                              Рыба не найдена
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Превью</q-item-label>
                      <q-img v-if="editedItem.preview_uploaded" :src="editedItem.preview_uploaded.medium"></q-img>

                      <q-uploader
                        ref="fileUploader"
                        :factory="uploadFactory"
                        @removed="removeImg"
                        accept=".jpeg, .jpg, .png"
                        style="width: 100%"
                        auto-upload
                        :error-message="errors['preview.file']"
                        :error="!!errors['preview.file']"
                      >
                      </q-uploader>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-expansion-item
                      v-if="editedItem.images && editedItem.images.data.length > 0"
                      v-model="expandedMedia"
                      icon="folder"
                      label="Загруженные изображения и видео"
                    >
                      <div class="" v-if="editedItem.images.data.length > 0">
                        <q-card>
                          <q-card-section>Фото и видео:</q-card-section>
                          <q-card-section v-for="img in editedItem.images.data" :key="img.id">
                            <q-img v-if="img.crops" :src="img.crops.medium"></q-img>
                            <iframe v-else
                                    style="width: 100%; height: 320px"
                                    :src="`https://www.youtube.com/embed/${img.youtube_id}`"
                                    title="YouTube video player" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>
                            </iframe>
                            <q-btn
                              @click="deleteDbMedia(img.id)"
                              flat
                              label="Удалить"
                              color="warning"
                              dense
                            ></q-btn>
                          </q-card-section>
                        </q-card>
                      </div>
                    </q-expansion-item>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-list bordered separator v-if="medias.length > 0">
                        <q-item v-for="(m ,i) in medias" :key="i">
                          <q-item-section v-if="m.type === 'youtube'">
                            <q-item-label>{{ m.youtube_id }}</q-item-label>
                          </q-item-section>
                          <q-item-section v-if="m.type === 'file'">
                            <q-item-label>{{ m.file.name }}</q-item-label>
                          </q-item-section>
                          <q-item-section side >
                            <q-btn dense round flat color="grey" @click="deleteMedia(i)"  icon="delete"></q-btn>
                          </q-item-section>
                        </q-item>
                      </q-list>
                      <q-card>
                        <q-card-section>
                          <q-radio v-model="media.type" val="file" label="Файл" />
                          <q-radio v-model="media.type" val="youtube" label="ID Youtube" />
                          <q-uploader v-if="media.type == 'file'"
                                      ref="mediaUploader"
                                      :factory="uploadFactoryMedia"
                                      @removed="removeImgMedia"
                                      accept=".jpeg, .jpg, .png, .mp4, .mov"
                                      no-thumbnails
                                      auto-upload
                                      style="width: 100%"
                                      :error-message="errors['preview.file']"
                                      :error="!!errors['preview.file']"
                          >
                          </q-uploader>
                          <q-input dense outlined
                                   v-if="media.type == 'youtube'"
                                   v-model="media.youtube_id"
                          />
                          <q-btn
                            flat
                            label="Добавить"
                            color="primary"
                            dense
                            @click="addMedia"
                          ></q-btn>
                        </q-card-section>
                      </q-card>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-form>
            </q-tab-panel>

            <q-tab-panel name="seo">
              <div class="text-h6">SEO</div>
              <q-form class="q-gutter-md">
                <q-list>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Meta Title</q-item-label>
                      <q-input dense outlined
                               v-model="editedItem.meta_title"
                               :error-message="errors.meta_title"
                               :error="!!errors.meta_title" />
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Meta Tags</q-item-label>
                      <q-input dense outlined
                               v-model="editedItem.meta_tags"
                               :error-message="errors.meta_tags"
                               :error="!!errors.meta_tags" />
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Meta Description</q-item-label>
                      <q-input autogrow dense outlined
                               :error-message="errors.meta_description"
                               :error="!!errors.meta_description"
                               v-model="editedItem.meta_description"
                      />
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-form>
            </q-tab-panel>
          </q-tab-panels>


        </q-card-section>
        <q-card-section>
          <q-card-actions align="right">
            <q-btn
              flat
              label="Отмена"
              color="warning"
              dense
              v-close-popup
            ></q-btn>
            <q-btn
              flat
              label="Сохранить"
              color="primary"
              dense
              @click="save"
            ></q-btn>
          </q-card-actions>
        </q-card-section>
      </q-card>
    </q-dialog>
</template>


<script>
import {defineComponent, ref} from "vue";
import {makeFormData} from "boot/helpers";

export default defineComponent({
  name: "FishModal",
  mounted() {
    this.fetchClassifications();
    this.fetchMethods();
  },
  watch: {
    show_dialog(v) {
      this.errors = {}
    }
  },
  computed: {
    modalTitle() {
      if(this.editedItem.id) return 'Редактировать';
      return 'Создать';
    }
  },
  data: () => ({
    show_dialog: false,
    editedItem: {},
    tab: 'main',
    errors: {},
    model: null,
    methodLoader: false,
    methods: [],
    classifications: [],
    preview: false,
    expandedPreview: false,
    expandedMedia: false,
    medias: [],
    media: {
      type: 'file',
      youtube_id: null,
      file: null,
    },
  }),
  methods:{
    addMedia() {
      if(this.media.type === 'file') this.$refs.mediaUploader.reset()
      this.medias.push(Object.assign({}, this.media))
      this.media = {
        type: 'file',
        file: null,
        youtube_id: null
      }

    },
    deleteMedia(i) {
      this.medias.splice(i, 1);
    },
    deleteDbMedia(i) {
      this.$api.delete('admin/media/'+i).then(res => {
        this.editedItem.images.data = this.editedItem.images.data.filter(m => m.id !== i)
      })
    },
    removeImgMedia(file) {
      this.media.file = false;
    },
    uploadFactoryMedia(file) {
      this.media.file = file[0];
    },
    uploadFactory(file) {
      this.preview = file[0];
    },
    removeImg(file) {
      this.preview = false;
    },
    filterClassifications (val) {
      this.fetchClassifications(val)
    },
    async fetchClassifications(q){
      await this.$api.get('admin/reference/fish-classification',{ params: { filters: {name: q} } }).then(res => {
        this.classifications = res.data.data;
      })
    },
    filterMethods (val) {
      this.fetchMethods(val)
    },
    async fetchMethods(q){
      this.methodLoader = true;
      await this.$api.get(`admin/reference/fishing-method`, { params: { filters: {name: q} } }).then(res => {
        this.methods = res.data.data;
        this.methodLoader = false;
      }).catch(e => {
        this.methodLoader = false;
      })
    },
    async open(options) {
      if(options.id) {
        this.methods = options.general.methods;
      }
      this.show_dialog = true
      this.editedItem = options
    },
    editRow(props) {
      this.show_dialog = true
      this.editedItem = props.row;
    },
    save() {
      if(this.editedItem.id) {
        this.update()
      } else {
        this.store()
      }
    },
    async update() {
      let data = makeFormData(this.editedItem);
      if(this.preview) {
        data.append('preview[file]', this.preview);
      }
      if(this.medias.length > 0) {
        this.medias.forEach((m,i) => {
          if(m.type === 'youtube') {
            data.append(`files[${i}][youtube_id]`, m.youtube_id);
          }
          if(m.type === 'file') {
            data.append(`files[${i}][file]`, m.file);
          }
        });
      }
      data.append('_method', 'patch')
      await this.$api.post(`admin/reference/fish/${this.editedItem.id}`, data)
        .then((res) => {
          this.show_dialog = false
        })
        .catch(e => {
          this.errors = e.response.data.errors
        });
    },
    filterFn (val, update, abort) {
      update(() => {})
    },
    async store() {
      let data = makeFormData(this.editedItem);
      if(this.preview) {
        data.append('preview[file]', this.preview);
      }
      if(this.medias.length > 0) {
        this.medias.forEach((m,i) => {
          if(m.type === 'youtube') {
            data.append(`files[${i}][youtube_id]`, m.youtube_id);
          }
          if(m.type === 'file') {
            data.append(`files[${i}][file]`, m.file);
          }
        });
      }
      await this.$api.post(`admin/reference/fish`, data)
        .then((res) => {
          this.show_dialog = false
          this.$emit('update');
        })
        .catch(e => {
          this.errors = e.response.data.errors
        });
    },
    async delete(props) {
      await this.$api.delete(`admin/reference/fish/${props.id}`);
      this.$emit('update');
    }
  },
});
</script>
