<template>
  <router-link :to="isCity ? {name: 'city-page', params: {slug: slug}} : {name: 'water-page', params: {slug: slug}}" class="place-card">
    <div class="place-card__cover">
      <img :src="cover" alt="" loading="lazy">
    </div>
    <div class="place-card__info">
      <div class="place-card__title">{{title}}</div>
      <div class="place-card__subtitle">{{subtitle}}</div>
    </div>
  </router-link>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PlaceCard",
  props: [
      'isCity',
    'title',
    'subtitle',
    'cover',
    'slug'
  ],
})
</script>

<style lang="scss">
  @import "src/css/variables";
  @import "src/css/mixins";

  .place-card {
    text-decoration: none;
    color: $color-white;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    display: block;

    &:hover {
      .place-card__cover img {
        transform: scale(1.125);
      }
    }

    & + & {
      margin-top: 16px;

      @media #{$tablet-widths} {
        margin-top: 20px;
      }

      @media #{$desktop-widths} {
        margin-top: 0;
      }
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 90px;
      background: linear-gradient(180deg, rgba(0, 1, 41, 0) 0%, rgba(0, 1, 41, 0.6) 100%);
    }
  }

  .place-card__cover {
    border-radius: 16px;
    overflow: hidden;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1);
      transition: transform .75s ease-in;
    }
  }

  .place-card__info {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 16px;
    z-index: 2;

    @media #{$tablet-widths} {
      padding: 20px;
    }
  }

  .place-card__title {
    @include title();
    @include font(22px, 28px);
    margin-bottom: 2px;

    @media #{$tablet-widths} {
      @include font(26px, 32px);
    }
  }

  .place-card__subtitle {
    @include font(12px, 16px);

    @media #{$tablet-widths} {
      @include font(16px, 24px);
    }
  }
</style>
