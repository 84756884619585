<template>
  <section class="main-reports">
    <div class="main-reports-wrapper">
      <h2 class="main-reports__h2">Отчеты</h2>
        <div class="row main-reports__container">
          <div class="col-xs-12 col-sm-auto col-md-4 main-reports__cell"
               v-for="report in reports"
               :key="report.id">
            <report-card
                :report="report"
                :name="report.author.full_name"
                :date="report.created_at_iso"
                :fishes="report.general.fishes"
                :comments="report.comments_count"
                :title="report.title"
                :text="report.water ? report.water : ''  + ' ' + report.city ? report.city : ''"
                :cover="report.preview.medium"
                :avatar="report.author.avatar"
            />
          </div>
        </div>
      <router-link to="/reports" class="btn btn-m btn-secondary main-reports__btn">Смотреть все</router-link>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import ReportCard from "components/ReportCard";

export default defineComponent({
  name: "MainReports",
  components: {
    ReportCard
  },
  props: [
      'reports'
  ]
})
</script>

<style lang="scss">
  @import "src/css/variables";
  @import "src/css/mixins";

  .main-reports {
    padding-bottom: 40px;

    @media #{$tablet-widths} {
      padding-bottom: 60px;
    }

    @media #{$desktop-widths} {
      padding-bottom: 100px;
    }
  }

  .main-reports-wrapper {
    @include wrapper();

    @media #{$tablet-widths} {
      position: relative;
    }
  }

  .main-reports__h2 {
    margin-bottom: 24px;
  }

  .main-reports__container {
    margin-bottom: 26px;
    justify-content: center;

    @media #{$tablet-widths} {
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow-x: auto;
      width: calc(100% + 52px);
      margin: 0 -26px;
      padding-left: 26px;
      @include scrollbars(2px, transparent)
    }

    @media all and (min-width: 768px) {
      width: 100%;
      padding-left: 0;
      margin: 0;
    }

    @media #{$desktop-widths} {
      overflow: auto;
    }
  }

  .main-reports__cell {
    & + & {
      padding-top: 20px;

      @media #{$tablet-widths} {
        padding-top: 0;
        padding-left: 20px;
      }
    }
  }

  .main-reports__btn {
    width: 100%;

    @media #{$tablet-widths} {
      position: absolute;
      width: auto;
      right: 26px;
      top: 0;
    }

    @media #{$desktop-widths} {
      right: 38px;
    }

    @media #{$desktop-l-widths} {
      right: 58px;
    }
  }
</style>