<template>
  <div class="collapsed">
    <div class="collapsed-text">
      <template v-if="needToCollapse && collapsed">{{ collapsedText }}</template>
      <template v-else>{{ text }}</template>
    </div>
    <div class="collapsed-bottom" v-if="needToCollapse">
      <q-btn class="btn btn-secondary btn-s" @click=handler :ripple=false>
        <template v-if="collapsed">Читать</template>
        <template v-else>Свернуть</template>
      </q-btn>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "Collapse",
    props: {
        text: {
          type: String,
          default: ""
        },
      length: {
        type: Number,
        default: 333
      }
    },
    data: function () {
        return {
            collapsed: true
        };
    },
    computed: {
        collapsedText () {
           let trimmedString = this.text.substring(0, this.length)
           trimmedString = trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
           return trimmedString + "..."
        },
        textLength () {
            return this.text.length
        },
        needToCollapse () {
            return this.textLength > this.length;
        }
    },
    methods: {
      collapse: function () {
        this.collapsed = !this.collapsed;
      },
      setContainerHeight: function () {
        let height = 0;
        const cells = document.querySelectorAll('.review-cell');
        const container = document.querySelector('.reviews-grid');
        let maxCellHeight = 0;

        if (cells && container) {
          cells.forEach(cell => {
            if (cell.offsetHeight > maxCellHeight) {
              maxCellHeight = cell.offsetHeight;
            }

            height += cell.offsetHeight;
          })

          if (window.innerWidth >= 600 && cells.length >= 2) {
            container.style.height = height / 1.8 > maxCellHeight ? height / 1.8 + 'px' : maxCellHeight + 'px';
          } else {
            container.style.height = null;
          }
        }
      },
      handler: function () {
        this.collapse();
        setTimeout(()=> {
          this.setContainerHeight()
        }, 300)
      }
    }
})
</script>