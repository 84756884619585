<template>
  <div class="gallery">
    <div class="gallery__head">
      <slot v-if="slots.title" name="title">

      </slot>
      <h2 v-else class="gallery__title">{{props.title}}</h2>

      <div class="navigation">
        <div class="prev swiper-button">
          <q-icon name="arrow_back"/>
        </div>
        <div class="next swiper-button">
          <q-icon name="arrow_forward"/>
        </div>
      </div>
    </div>

    <swiper
      class="carousel"
      :slides-per-view="'auto'"
      :space-between="16"
      :free-mode="{enabled: true, sticky: false}"
      :navigation="{
      'prevEl': '.navigation .prev',
      'nextEl': '.navigation .next'
    }"
      :breakpoints="{
      1023  : {
        'slidesPerView': slidesDesktop || 3,
        'spaceBetween': 20
      },
      1439  : {
        'slidesPerView': slidesDesktopL || 4,
        'spaceBetween': 20
      }
    }"
    >
      <swiper-slide :lazy="true" class="slide" v-for="(slide) in [...slides].sort((a, b) => b.id - a.id)" :key="slide">

        <!-- if image src     -->
        <fancybox
          class="gallery-item"
          v-if="slide.crops?.medium"
          :href="slide.crops.medium"
          data-fancybox="gallery">
          <img :src="slide.crops?.large" loading="lazy">
          <div v-if="props.actions" class="gallery-item__cover">
            <q-btn @click.stop="emit('delete-slide', slide)" class="btn btn-white btn-rounded btn-rounded-s" :ripple="false">
              <q-icon name="o_delete"/>
            </q-btn>
          </div>
        </fancybox>

        <!-- if youtube video     -->
        <fancybox
          class="gallery-item"
          v-else-if="slide.youtube_id"
          data-fancybox="gallery"
          :href="`https://www.youtube.com/embed/${slide.youtube_id}`"
        >
          <iframe
            :src="`https://www.youtube.com/embed/${slide.youtube_id}`"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
          <div v-if="props.actions" class="gallery-item__cover">
            <q-btn @click.stop="emit('delete-slide', slide)" class="btn btn-white btn-rounded btn-rounded-s" :ripple="false">
              <q-icon name="o_delete"/>
            </q-btn>
          </div>
        </fancybox>
      </swiper-slide>
      <swiper-slide style="order: -1; display: flex; column-gap: 20px" class="slide" v-if="showUploaders">
        <div class="slide slide_short">
          <q-uploader
            ref="fileUploaderRef"

            @added="addFileHandler"
            auto-upload

            accept=".jpeg, .jpg, .png"
            class="uploader"
          >
            <template v-slot:header="scope">
              <div @click="scope.pickFiles" class="empty-template">
                <q-uploader-add-trigger/>
                <div class="uploader__add-block">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13 19.1L15 17.1L20.5 22.6L24 19.1L27 22.1V13H13V19.1ZM13 21.929V27H16.1L19.086 24.015L15 19.929L13 21.929ZM18.929 27H27V24.929L24 21.929L18.929 27ZM12 11H28C28.2652 11 28.5196 11.1054 28.7071 11.2929C28.8946 11.4804 29 11.7348 29 12V28C29 28.2652 28.8946 28.5196 28.7071 28.7071C28.5196 28.8946 28.2652 29 28 29H12C11.7348 29 11.4804 28.8946 11.2929 28.7071C11.1054 28.5196 11 28.2652 11 28V12C11 11.7348 11.1054 11.4804 11.2929 11.2929C11.4804 11.1054 11.7348 11 12 11ZM23.5 18C23.1022 18 22.7206 17.842 22.4393 17.5607C22.158 17.2794 22 16.8978 22 16.5C22 16.1022 22.158 15.7206 22.4393 15.4393C22.7206 15.158 23.1022 15 23.5 15C23.8978 15 24.2794 15.158 24.5607 15.4393C24.842 15.7206 25 16.1022 25 16.5C25 16.8978 24.842 17.2794 24.5607 17.5607C24.2794 17.842 23.8978 18 23.5 18Z"
                      fill="#000129"/>
                  </svg>
                  <div class="uploader__add-block-text"><span>Нажмите на ссылку</span>, чтобы выбрать фотографию&nbsp;или
                    просто перетащите её сюда
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:list>

            </template>
          </q-uploader>
        </div>
        <div class="slide slide_short" @click="youtubeDialog = true">
          <div class="uploader__add-block add-video">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M24.394 20L18 15.737V24.263L24.394 20ZM27.376 20.416L16.777 27.482C16.7017 27.5321 16.6142 27.5608 16.5239 27.5652C16.4336 27.5695 16.3437 27.5492 16.264 27.5065C16.1843 27.4639 16.1176 27.4003 16.0711 27.3228C16.0246 27.2452 16 27.1564 16 27.066V12.934C16 12.8436 16.0246 12.7548 16.0711 12.6772C16.1176 12.5997 16.1843 12.5361 16.264 12.4935C16.3437 12.4508 16.4336 12.4305 16.5239 12.4348C16.6142 12.4392 16.7017 12.4679 16.777 12.518L27.376 19.584C27.4445 19.6297 27.5006 19.6915 27.5395 19.7641C27.5783 19.8367 27.5986 19.9177 27.5986 20C27.5986 20.0823 27.5783 20.1633 27.5395 20.2359C27.5006 20.3085 27.4445 20.3703 27.376 20.416Z"
                fill="#000129"/>
            </svg>

            <div class="uploader__add-block-text"><span>Нажмите на ссылку</span>, чтобы загрузить видео с Youtube
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <add-youtube-link-dialog
      v-model="youtubeDialog"
      @save="addLinkHandler"
    />
  </div>
</template>

<script setup>
import {computed, ref, useSlots} from "vue";
import AddYoutubeLinkDialog from "components/dialogs/AddYoutubeLinkDialog.vue";

const slots = useSlots();
const emit = defineEmits(['update:model-value', 'added-files', 'added-link', 'delete-slide'])
const props = defineProps({
  modelValue: {},
  title: {type: String, default: ''},
  slidesDesktop: {required: false},
  slidesDesktopL: {required: false},
  showUploaders: {type: Boolean, default: true},
  actions: {type: Boolean, default: false}
})

const youtubeDialog = ref(false);
const fileUploaderRef = ref(null);

const slides = computed({
  get() {return props.modelValue},
  set(value) {
    emit('update:model-value', value)
  }
})

function addLinkHandler(link) {
  emit('added-link', link)
}
function addFileHandler(files) {
  emit('added-files', files)
}

</script>

<style scoped lang="scss">

@import "src/css/variables";
@import "src/css/mixins";
@import "@fancyapps/ui/dist/fancybox.css";

.carousel {
  width: 100%;
}
.navigation {
  display: flex;
  gap: 12px;

  .swiper-button {
    width: 46px;
    height: 46px;
    @media #{$tablet-widths} {
      width: 56px;
      height: 56px;
    }
  }
}

.swiper-slide.slide {
  margin-right: 16px;

  @media #{$tablet-widths} {
    margin-right: 20px;
  }
  @media #{$desktop-widths} {
    margin-right: 20px;
  }
}
.uploader__spinner {
  color: $color-primary-500 !important;
}
.slide_short {
  width: 150px;
}

.empty-template {
  transition: .2s;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  max-height: 700px;

  background: transparent;

  > svg {
    fill: $text-color-primary;
    margin-bottom: 24px;
  }

  > h4 {
    color: $text-color-primary;
    margin-bottom: 8px;
    text-align: center;
  }

  > p {
    color: $text-color-primary;
    text-align: center;
  }
}

.uploader {
  box-shadow: none;
  overflow: hidden;
  width: 100%;
  height: 100%;

  border: 2px dashed $color-border;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: transparent;
  color: #191927;
  transition: color 0.3s ease-in, background 0.3s ease-in;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  :deep() {
    .q-uploader__list {
      padding: 0;
      z-index: 2;
      flex: unset;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px #9773FF;
      }
    }

    .q-uploader__header {
      height: 100%;
      position: absolute;
      inset: 0;
      z-index: 1;
      background: transparent;

      &::before {
        opacity: 0;
      }
    }

    .q-uploader__dnd, .q-uploader__overlay {
      display: none;
    }
  }

  &__add-block {
    width: 100%;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 40px 16px;
    background: transparent;
    color: $text-color-primary;
    transition: color $transition, background $transition;
    @include font(12px, 16px);
    text-align: center;

    @media #{$tablet-widths} {
      padding: 40px 16px;
      height: 388px;
    }

    span {
      color: $color-primary-500;
      cursor: pointer;
    }

    i {
      @include font(40px);
      margin-bottom: 12px;
      color: $text-color-tertiary;
      @include transition(color);
    }
  }

  &__add-block-text {
    max-width: 360px;
  }
}

.add-video {
  cursor: pointer;
  height: 100%;
  border: 2px dashed $color-border;
  border-radius: 16px;
}

.gallery__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 12px;

  @media #{$desktop-widths} {
    margin-bottom: 24px;
  }
}

.gallery {
  width: 100%;
  position: relative;
  overflow: hidden;

  .swiper {
    overflow: visible;

    @media #{$desktop-widths} {
      overflow: hidden;
    }
  }

  .swiper-slide {
    width: auto;
    border-radius: $border-radius;
    overflow: hidden;
    @include size(312px, 237px);

    img, iframe {
      @include size(100%);
      object-fit: cover;

      &.placeholder {
        object-fit: initial;
      }
    }
  }
}

.gallery-item {
  position: relative;
  display: block;
  @include size(100%);

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  &__cover {
    padding: 10px;
    transition: .2s;
    position: absolute;
    inset: 0;
    opacity: 1;
    background-color: rgba(0, 0, 0, .1);

    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
}
</style>
