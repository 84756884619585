<template>
  <div class="wrapper">
    <div class="water-description" v-html="description"></div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'WaterDescription',
  props: ['description']
})
</script>

<style lang="scss">
  @import "src/css/variables";
  @import "src/css/mixins";

  .water-description {
    white-space: pre-line;
    padding: 16px;
    @include font(16px, 24px);
    color: $text-color-primary;
    background: $color-surface-grey;
    border-radius: $border-radius;
    margin-bottom: 16px;

    @media #{$tablet-widths} {
      padding: 40px;
      margin-bottom: 20px;
    }

    @media #{$desktop-widths} {
      @include font(20px, 28px)
    }

    pre {
      white-space: pre-line;
    }
  }
</style>
