<template>
  <q-dialog
    v-model="showVideoPopup"
  >
    <div class="popup popup-edit">
      <div class="popup-close">
        <q-icon name="close" v-close-popup/>
      </div>
      <div class="popup-content">
        <h3 class="popup-edit__name">Видео</h3>
        <q-form
          class="form"
          autocorrect="off"
          autocapitalize="off"
          autocomplete="off"
          spellcheck="false"
        >
          <div class="form__item">
            <q-input
              type="text"
              v-model="videoLink"
              label="Ссылка" class="input"/>
          </div>
          <div class="form__bottom space-between popup-edit__bottom">
            <q-btn class="btn btn-secondary btn-m" :ripple="false" v-close-popup label="Отменить"/>
            <q-btn class="btn btn-primary btn-m" @click="saveVideo" :ripple="false" label="Сохранить"/>
          </div>
        </q-form>
      </div>
    </div>
  </q-dialog>
</template>

<script setup>
import {computed, ref} from "vue";

const emit = defineEmits(['update:model-value']);
const props = defineProps({
  modelValue: {type: Boolean, default: false}
})

const showVideoPopup = computed({
  get() {return props.modelValue},
  set(value) {
    emit('update:model-value', value);
  }
})

const videoLink = ref('');

function saveVideo() {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = videoLink.value.match(regExp);

  const videoId = (match && match[7].length == 11) ? match[7] : false;

  emit('save', videoId);
  showVideoPopup.value = false;
  videoLink.value = '';
}
</script>

<style scoped>

</style>
