<template>
  <div class="profile-reports">
    <div class="profile-reports__content" v-if="!isEmpty">
      <h2 class="profile-reports__h2">Отчеты</h2>
      <!--      <q-btn class="profile-reports__btn btn btn-s btn-secondary" label="Написать отчет" :ripple="false" />-->
      <router-link label="Написать отчет" :ripple="false"
                   v-if="(isFisherman && isOwner) || (guideId)"
                   @click.prevent="createReport"
                   to="">
        <q-btn class="profile-reports__btn btn btn-s btn-secondary" label="Написать отчет" :ripple="false"/>
      </router-link>

      <masonry-wall v-if="reports.length" :items="reports" :ssr-columns="1" :column-width="428" :gap="20">
        <template #default="{ item }">
          <report-card
              :is-guide="isGuide"
              :report="item"
              :name="item.author.full_name"
              :date="item.created_at_iso"
              :fishes="item.general.fishes"
              :comments="item.comments_count"
              :title="item.title"
              :text="item.water ? item.water : ''  + ' ' + item.city ? item.city : ''"
              :cover="item.preview.medium"
              :avatar="item.author.avatar"
              ratio="4/3"
          />
        </template>
      </masonry-wall>

      <div v-if="false" class="column reports-grid">
        <div class="report-cell" tabindex="0" v-for="report in reports" :key="report.id">
          <report-card
              :is-guide="isGuide"
              :report="report"
              :name="report.author.full_name"
              :date="report.created_at_iso"
              v-bind:fishes="report.general.fishes"
              :comments="report.comments_count"
              :title="report.title"
              :text="report.water ? report.water : ''  + ' ' + report.city ? report.city : ''"
              :cover="report.preview.medium"
              :avatar="report.author.avatar"
              ratio="4/3"
          />
        </div>
      </div>
    </div>

    <div class="reports-empty" v-if="isEmpty">
      <img class="reports-empty__img" src="../assets/images/icons/report.svg" alt=""/>
      <h2 class="reports-empty__title">Отчёты</h2>
      <div class="reports-empty__text">
        <span v-if="!isEdit">У гида еще нет отчётов</span>
        <span v-if="isEdit">Напишите свой первый отчёт о рыбалке, поделитесь впечатлениями</span>
      </div>
      <template v-if="$auth.isAuthenticated()">
        <q-btn
            v-if="(isFisherman && isOwner) || (guideId)"
            @click="createReport"
            class="btn btn-primary btn-m">
          <q-icon name="add"/>
          <span>Написать</span>
        </q-btn>
      </template>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import ReportCard from "components/ReportCard";
import MasonryWall from '@yeger/vue-masonry-wall';

export default defineComponent({
  name: "ProfileReports",
  components: {
    ReportCard,
    MasonryWall,
  },
  props: [
    'isGuide',
    'isEmpty',
    'isEdit',
    'reports',
    'isFisherman',
    'guideId',
    'isOwner',
  ],
  methods: {
    createReport() {
      this.sendYM('target6-1')
      if (!this.isFisherman) {
        this.$router.push({name: 'create-report', params: {guideId: this.guideId}})
      } else {
        this.$router.push({name: 'create-report-fisherman', params: {guideId: this.guideId}})
      }
    },
    sendYM(name) {
      if (typeof ym !== undefined) {
        ym(45019072, 'reachGoal', name)
      }
    },
    setContainerHeight: function () {
      let height = 0;
      const cells = document.querySelectorAll('.report-cell');
      const container = document.querySelector('.reports-grid');
      let maxCellHeight = 0;

      if (cells && container) {
        cells.forEach(cell => {
          if (cell.offsetHeight > maxCellHeight) {
            maxCellHeight = cell.offsetHeight;
          }

          height += cell.offsetHeight;
        })

        if (window.innerWidth >= 600 && cells.length >= 2) {
          container.style.height = height / 1.97 > maxCellHeight ? height / 1.97 + 'px' : maxCellHeight + 'px';
        } else {
          container.style.height = null;
        }
      }
    }
  },
  watch: {
    reports(to, from) {
      // this.setContainerHeight();
      // window.addEventListener('resize', this.setContainerHeight);
    },
  },
  async mounted() {
    // this.setContainerHeight();
    // window.addEventListener('resize', this.setContainerHeight);
  }
});
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.reports-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: $border;
  border-radius: $border-radius;
  padding: 20px;

  @media #{$desktop-widths} {
    padding: 32px 32px 48px;
  }
}

.reports-empty__img {
  margin-bottom: 16px;
}

.reports-empty__title {
  margin-bottom: 8px;
}

.reports-empty__text {
  margin-bottom: 24px;
  text-align: center;
  color: $text-color-secondary;
  @include font(16px, 24px);
  width: 380px;
  max-width: 100%;

  @media #{$tablet-widths} {
    @include font(18px, 26px);
  }

  @media #{$desktop-widths} {
    @include font(20px, 28px);
  }
}

.profile-reports {
  margin-bottom: 40px;
  position: relative;

  @media #{$tablet-widths} {
    margin-bottom: 48px;
  }

  @media #{$desktop-widths} {
    margin-bottom: 80px;
  }
}

.profile-reports__h2 {
  margin-bottom: 24px;

  @media #{$tablet-widths} {
    margin-bottom: 32px;
  }
}

.profile-reports__btn {
  position: absolute;
  right: 0;
  top: 0;

  @media #{$tablet-widths} {
    height: 56px;
  }
}

.reports-grid {
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;
  overflow: hidden;

  @media #{$tablet-widths} {
    width: calc(100% + 20px);
    margin-right: -20px;
  }
}

/* Force new columns */
.reports-grid::before,
.reports-grid::after {
  content: "";
  flex-basis: 100%;
  width: 0;
  order: 2;
}

.report-cell {
  width: 100%;
  padding-bottom: 20px;

  @media #{$tablet-widths} {
    width: calc(100% / 2);
    padding-right: 20px;
  }
}

/* Re-order items into rows */
@media #{$tablet-widths} {
  .report-cell:nth-child(2n+1) {
    order: 1;
  }
  .report-cell:nth-child(2n+2) {
    order: 2;
  }
}
</style>
