<template>
  <main class="main">
    <router-view></router-view>
  </main>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppMain",
});
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
</style>
