<template>
  <div class="wrapper">
    <div class="water-info">
      <div class="water-info__map">
        <yandex-map
            v-if="isCity"
            :settings="{
              apiKey: 'f525433f-aa77-44e2-8511-2ab9843f8527',
              lang: 'ru_RU',
              coordorder: 'longlat',
              enterprise: false,
              version: '2.1',
            }"
            :coords="water.general.geo.region_location.coordinates"
            zoom="9"
            style="width: 100%; height: 100%;"
            :controls="['zoomControl', 'fullscreenControl']"
        >
          <ymap-marker
              :marker-id="1"
              marker-type="placemark"
              :coords="water.general.geo.region_location.coordinates"
              :icon="markerIcon"
          ></ymap-marker>
        </yandex-map>
        <map-component v-else :info="water.guides_on_map" />
      </div>

      <div class="water-info__aside">
        <div class="water-trophies" v-if="water.trophies.length">
          <div v-if="water.trophies" class="water-trophies__title">Лучшие трофеи</div>
          <template v-for="(item) in water.trophies.slice(0, 3)" :key="item.id">
            <router-link v-if="item.guide.id" :to="{name: 'profile', params: {idOrSlug: item.guide.username ?? item.guide.id}}" class="water-trophies__item">
              <div class="water-trophies__item-photo">
                <img v-if="item.guide.avatar" :src="item.guide.avatar">
                <span v-else>А</span>
              </div>
              <div class="water-trophies__item-info">
                <div class="water-trophies__item-name">{{item.guide.full_name}}</div>
                <div class="water-trophies__item-fish">{{item.fish}} - {{item.weight_kilo}} кг</div>
              </div>
            </router-link>
          </template>
        </div>

        <div class="water-fishes" v-if="isWater && water.general.fishes.length">
          <div class="water-fishes__title">Рыба</div>
          <div class="water-fishes__links">
            <router-link v-for="(fish) in water.general.fishes" :key="fish.id" to="#" class="tag">
              <span>{{fish.name}}</span>
            </router-link>
          </div>
        </div>

        <div class="water-fishes" v-if="isFish && getFishingMethods">
          <div class="water-fishes__title">На что ловить</div>
          <div class="water-fishes__info">{{getFishingMethods}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import MapComponent from "components/MapComponent";
import {yandexMap} from "vue-yandex-maps";

export default defineComponent({
  name: 'WaterInfo',
  components: {
    MapComponent,
    yandexMap,
  },
  props: [
      'isCity',
      'isWater',
      'isFish',
      'water'
  ],
  data: () => ({
    markerIcon: {
      layout: 'default#imageWithContent',
      imageHref: 'icons/blue-map-marker-man.webp',
      imageSize: [32, 40],
      imageOffset: [0, 0],
      content: '',
      contentOffset: [35, 15],
      contentLayout: '<div style="width: 32px; height: 42px;"></div>'
    },
  }),
  computed: {
    getFishingMethods() {
      const res = this.water.general.methods.map(item => item.name)
      return res.join(', ')
    }
  }
})
</script>

<style lang="scss">
  @import "src/css/variables";
  @import "src/css/mixins";

  .water-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @media #{$desktop-widths} {
      flex-direction: row;
      margin-bottom: 100px;
    }
  }

  .water-info__map {
    height: 360px;
    border: $border;
    border-radius: $border-radius;
    overflow: hidden;
    margin-bottom: 16px;

    @media #{$desktop-widths} {
      height: 444px ;
      width: 100%;
      margin-bottom: 0;
    }
  }

  .water-info__aside {
    @media #{$desktop-widths} {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      flex: 0 0 316px;
    }
  }

  .water-trophies {
    border: $border;
    border-radius: $border-radius;
    margin-bottom: 16px;
    padding: 16px;

    @media #{$desktop-widths} {
      padding: 20px;
      margin-bottom: 20px;
    }
  }

  .water-trophies__title {
    @include font(16px, 24px);
    font-weight: 600;
  }

  .water-trophies__item {
    display: flex;
    align-items: center;
    padding: 16px 0 4px;
    text-decoration: none;

    &:hover {
      .water-trophies__item-name {
        color: $color-primary-500;
      }
    }

    & + & {
      border-top: $border;
    }
  }

  .water-trophies__item-photo {
    @include size(40px);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-green;
    margin-right: 12px;

    img {
      @include size(100%);
      object-fit: cover;
    }

    span {
      color: $color-white;
      @include font(16px, 24px);
      font-weight: 600;
    }
  }

  .water-trophies__item-name {
    color: $text-color-tertiary;
    @include font(16px, 24px);
    @include transition(color);
  }

  .water-trophies__item-fish {
    color: $text-color-primary;
    @include font(16px, 24px);
  }

  .water-fishes {
    border: $border;
    border-radius: $border-radius;
    padding: 16px 16px 8px;

    @media #{$desktop-widths} {
      flex-grow: 1;
      padding: 20px 20px 12px;
      min-height: 152px;
    }
  }

  .water-fishes__title {
    @include font(16px, 24px);
    font-weight: 600;
    margin-bottom: 16px;
  }

  .water-fishes__links {
    display: flex;
    flex-wrap: wrap;

    .tag {
      margin-bottom: 8px;
    }
  }
</style>
