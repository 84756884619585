<template>
  <div class="account-aside">
    <div class="account-links">
      <router-link :to="{name: 'account-subscriptions'}" class="account-links__item" v-if="$auth.canAny(['guide'])" v-bind:class="{'active': $route.name === 'account-subscriptions'}">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.49189 8.06499L4.77789 19H19.2219L20.5079 8.06499L16.4979 10.738L11.9999 4.44099L7.50189 10.738L3.49189 8.06499ZM2.80089 5.19999L6.99989 7.99999L11.1859 2.13999C11.2784 2.01036 11.4005 1.9047 11.5421 1.83179C11.6837 1.75889 11.8406 1.72086 11.9999 1.72086C12.1591 1.72086 12.3161 1.75889 12.4577 1.83179C12.5993 1.9047 12.7214 2.01036 12.8139 2.13999L16.9999 7.99999L21.1999 5.19999C21.3588 5.09424 21.5447 5.0362 21.7356 5.03273C21.9265 5.02926 22.1144 5.0805 22.2771 5.18041C22.4398 5.28032 22.5705 5.42471 22.6537 5.59653C22.737 5.76834 22.7693 5.96039 22.7469 6.14999L21.1039 20.117C21.0752 20.3602 20.9583 20.5845 20.7753 20.7473C20.5922 20.91 20.3558 21 20.1109 21H3.88889C3.64395 21 3.40755 20.91 3.22451 20.7473C3.04148 20.5845 2.92454 20.3602 2.89589 20.117L1.25289 6.14899C1.23069 5.95947 1.26317 5.76756 1.34651 5.59591C1.42984 5.42425 1.56055 5.28003 1.7232 5.18025C1.88584 5.08048 2.07364 5.02932 2.26442 5.03281C2.45521 5.03631 2.641 5.09432 2.79989 5.19999H2.80089ZM11.9999 15C11.4695 15 10.9607 14.7893 10.5857 14.4142C10.2106 14.0391 9.99989 13.5304 9.99989 13C9.99989 12.4696 10.2106 11.9608 10.5857 11.5858C10.9607 11.2107 11.4695 11 11.9999 11C12.5303 11 13.039 11.2107 13.4141 11.5858C13.7892 11.9608 13.9999 12.4696 13.9999 13C13.9999 13.5304 13.7892 14.0391 13.4141 14.4142C13.039 14.7893 12.5303 15 11.9999 15Z" fill="inherit"/>
        </svg>
        <span>Подписки</span>
      </router-link>
      <router-link :to="{name: 'account-info'}" class="account-links__item" v-bind:class="{'active': $route.name === 'account-info'}">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM19 20V4H5V20H19ZM8 7H16V9H8V7ZM8 11H16V13H8V11ZM8 15H13V17H8V15Z" fill="inherit"/>
        </svg>
        <span>Информация</span>
      </router-link>
<!--      <router-link :to="{name: 'booking'}" class="account-links__item" v-bind:class="{'active': $route.name === 'booking'}">
        <q-icon name="map" size="24"></q-icon>
        <span>Бронирования</span>
      </router-link>-->
      <router-link :to="{name: 'account-settings'}" class="account-links__item" v-bind:class="{'active': $route.name === 'account-settings'}">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 1L21.5 6.5V17.5L12 23L2.5 17.5V6.5L12 1ZM12 3.311L4.5 7.653V16.347L12 20.689L19.5 16.347V7.653L12 3.311ZM12 16C10.9391 16 9.92172 15.5786 9.17157 14.8284C8.42143 14.0783 8 13.0609 8 12C8 10.9391 8.42143 9.92172 9.17157 9.17157C9.92172 8.42143 10.9391 8 12 8C13.0609 8 14.0783 8.42143 14.8284 9.17157C15.5786 9.92172 16 10.9391 16 12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16ZM12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14Z" fill="inherit"/>
        </svg>
        <span>Настройки</span>
      </router-link>

    </div>
    <router-link v-if="$auth.canAny(['guide'])" :to="{name: 'profile', params: {idOrSlug: $auth.user().username ?? $auth.user().id}}" class="account__back">
      <q-icon name="arrow_back" />
      <span>Назад в профиль</span>
    </router-link>
    <router-link v-if="$auth.canAny(['fisherman'])" :to="{name: 'client-profile', params: {idOrSlug: $auth.user().username ?? $auth.user().id}}" class="account__back">
      <q-icon name="arrow_back" />
      <span>Назад в профиль</span>
    </router-link>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'AccountLinks',
  // mounted() {
  //   this.$api.post('client/tour/3/reserve')
  // },
})
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

</style>