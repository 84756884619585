<template>
  <section v-if="guides.length" class="guides-inner">
    <div class="wrapper">
      <div style="display:flex;align-items: center;justify-content: space-between; position: relative">
        <h2 class="guides-inner__h2">С кем порыбачить</h2>
        <router-link
            :to="{name: 'guides-search', query: {search: search}}"
            class="btn btn-m btn-secondary btn-absolute">Смотреть всех</router-link>
      </div>

      <div class="row q-col-gutter-md">
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" v-for="guide in guidesToSee" :key="guide.id">
          <guide-card :isChecked="guide.premium_abilities.verified" :card="guide" />
        </div>
      </div>
      <div v-if="curPage !== lastPage" class="guides-inner__bottom">
        <q-btn @click="showNextGuides" class="btn btn-secondary btn-m" :ripple=false>Загрузить ещё</q-btn>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import GuideCard from "components/GuideCard";

export default defineComponent( {
  name: "GuidesInner",
  components: {
    GuideCard
  },
  data: () => ({
    guidesToSee: [],
    curPage: 1,
    lastPage: 1,
    guidesPerPage: 20,
  }),
  props: {
    guides: {
      type: Array,
    },
    search: {
      type: String,
    },
  },
  methods: {
    showNextGuides() {
      if (this.curPage !== this.lastPage) {
        this.curPage++;
        this.guidesToSee = [...this.guidesToSee, ...this.guides.slice((this.curPage-1) * this.guidesPerPage, this.curPage * this.guidesPerPage - 1)];
      }
    }
  },
  mounted() {
    this.lastPage = Math.ceil(this.guides.length / this.guidesPerPage)
    this.guidesToSee = this.guides.slice(0, this.curPage * this.guidesPerPage)
    console.log(this.guides)
  }
})
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";
.guides-inner .guide-card {
  width: 100%;
}

.guides-inner__h2 {
  margin-bottom: 24px;

  @media #{$desktop-widths} {
    margin-bottom: 48px;
  }
}

.guides-inner__bottom {
  display: flex;
  justify-content: center;

  .btn {
    width: 100%;

    @media #{$tablet-widths} {
      width: auto;
    }
  }
}
.btn-absolute {
  position: absolute;
  top: 0;
  right: 0;
}
</style>