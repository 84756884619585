<template>
  <yandex-map
      v-if="info?.length"
      :settings="settings"
      :coords="getCoords[0]"
      zoom="9"
      style="width: 100%; height: 100%;"
      :controls="['zoomControl', 'fullscreenControl']"
  >
    <template v-if="getCoords">
      <ymap-marker
          v-for="(n, idx) in getCoords"
          :key="idx"
          :marker-id="idx"
          marker-type="placemark"
          :coords="n"
          :icon="markerIcon"
          :balloon-template="balloonTemplate(idx)"
      ></ymap-marker>
    </template>
  </yandex-map>
  <yandex-map
      v-else
      :settings="settings"
      :coords="[55.755819, 37.617644]"
      zoom="9"
      style="width: 100%; height: 100%;"
      :controls="['zoomControl', 'fullscreenControl']"
  >
    <template v-if="getCoords">
      <ymap-marker
          v-for="(n, idx) in getCoords"
          :key="idx"
          :marker-id="idx"
          marker-type="placemark"
          :coords="n"
          :icon="markerIcon"
          :balloon-template="balloonTemplate(idx)"
      ></ymap-marker>
    </template>
  </yandex-map>
</template>

<script>
import {defineComponent} from "vue";
import {yandexMap, ymapMarker} from 'vue-yandex-maps';

export default defineComponent({
  name: "MapComponent",
  components: {
    yandexMap,
    ymapMarker,
  },
  props: {
    info: {
      type: Array,
    }
  },
  data() {
    return {
      settings: {
        apiKey: 'f525433f-aa77-44e2-8511-2ab9843f8527',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1',
      },
      markerIcon: {
        layout: 'default#imageWithContent',
        imageHref: 'icons/blue-map-marker-man.webp',
        imageSize: [32, 40],
        imageOffset: [0, 0],
        content: '',
        contentOffset: [35, 15],
        contentLayout: '<div style="width: 32px; height: 42px;"></div>'
      },
    }
  },
  computed: {
    getCoords() {
      const res = this.info.map(item => item.point.coordinates.reverse());
      return res ? res : [[55.755819, 37.617644]];
    },
  },
  methods: {
    balloonTemplate(idx) {
      return `
        <div class="balloon">
          <div class="balloon-image">
            <img src="https://i.ibb.co/YPwQtQQ/Group-23.png" class="balloon-check">
            <img src="${this.info[idx].guide.avatar ? this.info[idx].guide.avatar : 'https://i.ibb.co/yyF4ycD/Avatar.png'}" class="balloon-photo">
          </div>
          <div class="balloon-info">
            <a href="#" class="balloon-name">${this.info[idx].guide.full_name}</a>
<!--            <div class="balloon-price">5000 ₽/День</div>-->
            <div class="balloon-rating">
              <i class="q-icon notranslate material-icons-outlined balloon-star" aria-hidden="true" role="presentation">${this.info[idx].guide.guide_profile.stats.rating === 1 ? 'star' : 'star_outline' }</i>
              <i class="q-icon notranslate material-icons-outlined balloon-star" aria-hidden="true" role="presentation">${this.info[idx].guide.guide_profile.stats.rating === 2 ? 'star' : 'star_outline' }</i>
              <i class="q-icon notranslate material-icons-outlined balloon-star" aria-hidden="true" role="presentation">${this.info[idx].guide.guide_profile.stats.rating === 3 ? 'star' : 'star_outline' }</i>
              <i class="q-icon notranslate material-icons-outlined balloon-star" aria-hidden="true" role="presentation">${this.info[idx].guide.guide_profile.stats.rating === 4 ? 'star' : 'star_outline' }</i>
              <i class="q-icon notranslate material-icons-outlined balloon-star" aria-hidden="true" role="presentation">${this.info[idx].guide.guide_profile.stats.rating === 5 ? 'star' : 'star_outline' }</i>
            </div>
          </div>
        </div>
      `
    }
  }
})
</script>
