<template>
  <div class="rewards-card">
    <div class="rewards-card__title">Награды</div>
    <div class="rewards-card__container">
      <div class="rewards-card__item"><img src="../assets/images/icons/reward.svg" alt=""><span>Самый просматриваемый гид месяца</span></div>
      <div class="rewards-card__item"><img src="../assets/images/icons/reward.svg" alt=""><span>Самый просматриваемый гид месяца</span></div>
      <div class="rewards-card__item"><img src="../assets/images/icons/reward.svg" alt=""><span>Самый просматриваемый гид месяца</span></div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "RewardsCard",
});
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.rewards-card {
  width: calc(100% - 40px);
  margin: 0 auto;
  border-bottom: $border;
  padding: 20px 0;

  @media #{$tablet-widths} {
    width: calc(100% - 52px);
    display: flex;
    align-items: flex-start;
  }

  @media #{$desktop-widths} {
    display: flex;
    align-items: flex-start;
    width: 296px;
    margin: 0;
    padding: 20px;
    border: $border;
    border-radius: 16px;
    flex-wrap: wrap;
  }
}

.rewards-card__container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media #{$desktop-widths} {
    width: 100%;
    justify-content: space-between;
  }
}

.rewards-card__item {
  display: flex;
  width: 277px;
  align-items: center;
  @include font(12px, 16px);
  color: $text-color-primary;

  @media #{$desktop-widths} {
    width: 100%;
  }

  img {
    @include size(40px);
    margin-right: 12px;
  }
}

.rewards-card__item + .rewards-card__item {
  margin-top: 12px;
}

.rewards-card__title {
  display: none;
  color: $text-color-primary;
  font-weight: 600;
  @include font(16px, 24px);

  @media #{$tablet-widths} {
    display: block;
    min-width: 143px;
  }

  @media #{$desktop-widths} {
    width: 100%;
    margin: 0 0 16px 0;
  }
}
</style>
