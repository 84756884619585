import { defineStore } from 'pinia'

export const useWaterStore = defineStore('water-store', {
  state: () => ({
    water: {},
  }),
  getters: {
  },
  actions: {
  },
})
