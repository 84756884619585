import script from "./AddYoutubeLinkDialog.vue?vue&type=script&setup=true&lang=js"
export * from "./AddYoutubeLinkDialog.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QIcon,QForm,QInput,QBtn});qInstall(script, 'directives', {ClosePopup});
