<template>
  <div class="review-grade">
    <div class="review-grade__name">{{name}}</div>
    <div class="review-grade__value">{{grade}}</div>
    <q-linear-progress :value="gradeValue" size="4px" class="review-grade__item" rounded color="primary" track-color="grey" />
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "ReviewGrade",
    props: [
      'name',
      'grade',
    ],
    computed: {
        gradeValue () {
            return this.grade / 5;
        },
    }
})
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.review-grade {
  color: $text-color-primary;
  @include font(16px, 24px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .q-linear-progress__track {
    opacity: 1;
  }
}

.review-grade__name {
  margin-bottom: 8px;

  @media #{$desktop-widths} {
    order: 1;
    margin-bottom: 0;
    width: 90px;
    margin-right: 24px;
  }
}

.review-grade__value {
  margin-bottom: 8px;

  @media #{$desktop-widths} {
    order: 3;
    margin-bottom: 0;
    margin-left: 20px;
  }
}

.review-grade__item {
  width: 100%;

  @media #{$desktop-widths} {
    order: 2;
    width: auto;
    flex-grow: 1;
  }
}

</style>