import { defineStore } from 'pinia'

export const useCityStore = defineStore('city-store', {
  state: () => ({
    city: {},
  }),
  getters: {
  },
  actions: {
  },
})
