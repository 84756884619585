<template>
  <section class="water-top">
    <div class="wrapper">
      <h1 class="water-top__h1">{{ title }}</h1>
      <div class="water-top__location" v-if="location">{{ location }}</div>
    </div>
  </section>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'WaterTop',
  props: [
      'title',
      'location'
  ]
})
</script>

<style lang="scss">
  @import "src/css/variables";
  @import "src/css/mixins";

  .water-top {
    margin-top: 48px;
    height: 360px;
    padding-bottom: 40px;
    display: flex;
    align-items: flex-end;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 20px;

    @media #{$tablet-widths} {
      margin-top: 56px;
      padding-bottom: 60px;
    }

    @media #{$desktop-widths} {
      margin-top: 72px;
      margin-bottom: 60px;
    }
  }

  .water-top__h1 {
    margin-top: auto;
    margin-bottom: 4px;
    color: $color-white;
  }

  .water-top__location {
    @include font(16px, 24px);
    color: $color-white;

    @media #{$tablet-widths} {
      @include font(20px, 28px);
    }
  }
</style>