<template>
  <div class="status-card">
    <q-circular-progress
        show-value
        :value='remaining'
        size="60px"
        :thickness="0.1"
        :color="icon.color"
        center-color="transparent"
        track-color="secondary"
        class="status-card__avatar"
    >
      <q-avatar size="48px" :color="icon.color">
        <q-icon :name="icon.name" />
      </q-avatar>
    </q-circular-progress>

    <div class="status-card__info">
      <h4>{{status}}</h4>
      <div class="status-card__info-points">До след.уровня {{points}} баллов</div>
    </div>

    <div class="status-card__text">Баллы начисляются за оставленные отзывы и отчёты</div>

  </div>
</template>

<script>
import {defineComponent} from "vue";


export default defineComponent({
  name: 'StatusCard',
  props: [
      'status',
      'points'
  ],
  computed: {
    remaining: function () {
      return '' + Math.floor((20 - this.points) / 20 * 100);
    },
    icon: function () {
      switch (this.status) {
        case 'Рыбак-Новичок':
          return {
            color: 'positive',
            name: 'phishing'
          }
        case 'Рыбак-Активный':
          return {
            color: 'info',
            name: 'o_star_border'
          }
        case 'Рыбак-Опытный':
          return {
            color: 'dark',
            name: 'o_emoji_events'
          }
        case 'Рыбак-Профи':
          return {
            color: 'warning',
            name: 'workspace_premium'
          }
        default:
          return {};
      }
    }
  }
})
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.status-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: $border;
  border-bottom: $border;
  padding: 20px;

  @media #{$desktop-widths} {
    padding: 24px;
    border: $border;
    border-radius: $border-radius;

  }
}

.status-card__avatar {
  margin-bottom: 12px;

  i {
    font-size: 24px!important;
    line-height: 24px!important;
    color: $color-white;
  }
}

.status-card__info {
  margin-bottom: 16px;
  text-align: center;
}

.status-card__info-points {
  @include font(16px, 24px);
  color: $text-color-secondary;
}

.status-card__text {
  @include font(12px, 16px);
  color: $text-color-secondary;
  text-align: center;
  border-top: $border;
  padding-top: 16px;
  width: 100%;
}
</style>
