<template>
    <q-dialog v-model="show_dialog">
      <q-card style="width: 900px; max-width: 60vw">
        <q-card-section>
          <q-btn round flat dense icon="close" class="float-right" color="grey-8" v-close-popup></q-btn>
          <div class="text-h6">{{modalTitle}}</div>
          <q-avatar v-if="!editedItem.avatar" size="32px" color="orange">{{editedItem.name.charAt(0)}}</q-avatar>
          <q-avatar size="32px"  v-else>
            <img :src="editedItem.avatar">
          </q-avatar>
        </q-card-section>
        <q-separator inset></q-separator>
        <q-card-section class="q-pt-none">
          <q-form class="q-gutter-md">
            <q-tabs
                v-model="tab"
                dense
                class="text-grey"
                active-color="primary"
                indicator-color="primary"
                align="justify"
                narrow-indicator
            >
              <q-tab name="general" label="Общее" />
              <q-tab name="guide" v-if="editedItem.guide_profile.profile_id" label="Профиль гида" />
            </q-tabs>
            <q-separator />

            <q-tab-panels v-model="tab" animated>
              <q-tab-panel name="general">
                <q-list>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Удален: {{editedItem.trashed ? 'Да' : 'Нет'}}</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">ФИО: {{editedItem.fio}}</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Имя пользователя: {{editedItem.username}}</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Email: {{editedItem.email}}</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Телефон: {{editedItem.phone}}</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Роли: {{editedItem.roles.map(r => r.name_ru).join(', ')}}</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Город: {{editedItem.location}}</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Статус</q-item-label>
                      <q-select
                          :behavior="$q.platform.is.ios === true ? 'menu' : 'default'"
                          dense
                          outlined
                          clearable
                          :options="options.statuses"
                          v-model="editedItem.status"
                          label="Статус"
                          option-value="value"
                          option-label="label"
                          emit-value
                          map-options
                      ></q-select>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Комментарий</q-item-label>
                      <q-input
                        v-model="editedItem.comment"
                        dense
                        outlined
                        aria-rowcount="3"
                        type="textarea"
                      />
                    </q-item-section>
                  </q-item>
                  <q-item  v-if="editedItem.guide_profile.profile_id">
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Разрешить участие в турнире</q-item-label>
                      <q-toggle
                          :true-value="1"
                          :false-value="0"
                          v-model="editedItem.guide_profile.allow_competition" />
                    </q-item-section>
                  </q-item>
                  <q-item  v-if="editedItem.guide_profile.profile_id">
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Процент предоплаты</q-item-label>
                      <q-input dense outlined
                               v-model="editedItem.guide_profile.prepaid_percent"
                               hint="Число от 0 до 100, если пустое - значение по умолчанию"
                               />
                    </q-item-section>
                  </q-item>
                  <q-item  v-if="editedItem.guide_profile.profile_id">
                    <q-item-section>
                      <q-item-label class="q-pb-xs">Процент при участии в турнире</q-item-label>
                      <q-input dense outlined
                               v-model="editedItem.guide_profile.prepaid_percent_competition"
                               hint="Число от 0 до 100, если пустое - значение по умолчанию"
                      />
                    </q-item-section>
                  </q-item>
                  <!--              <q-item>-->
                  <!--                <q-item-section>-->
                  <!--                  <q-item-label class="q-pb-xs">Комментарий</q-item-label>-->
                  <!--                  <q-input autogrow dense outlined v-model="editedItem.reject_reason" clearable/>-->
                  <!--                </q-item-section>-->
                  <!--              </q-item>-->

                </q-list>
              </q-tab-panel>
              <q-tab-panel name="guide" v-if="editedItem.guide_profile.profile_id">
                <q-splitter
                    v-model="splitter"
                >

                  <template v-slot:before>
                    <q-tabs
                        v-model="guideTab"
                        vertical
                        dense
                        class="text-grey"
                        active-color="primary"
                        indicator-color="primary"
                        align="justify"
                    >
                      <q-tab v-if="$auth.canAny(['admin'])" name="paid" label="Платные услуги" />
                      <q-tab v-if="$auth.canAny(['admin'])" name="personal_prices" label="Персональные цены" />
                      <q-tab name="general" label="Основное" />
                      <q-tab name="media"  label="Изображения" />
                      <q-tab name="settings"  label="Настройки" />
                    </q-tabs>
                  </template>

                  <template v-slot:after>
                    <q-tab-panels
                        v-model="guideTab"
                        animated
                        transition-prev="slide-down"
                        transition-next="slide-up"
                    >
                      <q-tab-panel name="personal_prices">
                        <q-list>
                          <q-item>
                            <q-item-section>
                              <q-item-label class="q-pb-xs">Назначенные цены: </q-item-label>
                              <q-list bordered>
                                <q-item v-ripple v-for="s in editedItem.personal_prices" :key="s.id">
                                  <q-item-section>{{s.name}}</q-item-section>
                                  <q-item-section>{{s.price}}</q-item-section>
                                  <q-item-section avatar>
                                    <q-btn dense round @click="deletePersonalPrice(s.paid_service_id)" flat color="grey" icon="delete"></q-btn>
                                  </q-item-section>
                                </q-item>
                              </q-list>
                            </q-item-section>
                          </q-item>
                          <q-item>
                            <q-item-section>
                              <q-item-label class="q-pb-xs">Добавить цену: </q-item-label>
                              <q-select
                                  :behavior="$q.platform.is.ios === true ? 'menu' : 'default'"
                                  label="Услуга"
                                  :loading="serviceLoader"
                                  dense
                                  outlined
                                  :options="options.services"
                                  v-model="template.service"
                                  @input-value="filterServices"
                                  option-value="id"
                                  option-label="name"
                                  use-input
                                  map-options
                                  @filter="filterFn"
                                  hint="Начните вводить название услуги"
                              >
                                <template v-slot:no-option>
                                  <q-item>
                                    <q-item-section class="text-grey">
                                      Услуга не найдена
                                    </q-item-section>
                                  </q-item>
                                </template>
                              </q-select>
                              <q-input autogrow dense outlined
                                       label="Цена"
                                       hint="Цена"
                                       :error-message="errors.price"
                                       :error="!!errors.price"
                                       v-model="template.price" />
                              <q-btn
                                  @click="addPersonalPrice(template.service.id)"
                                  flat
                                  label="Добавить"
                                  color="warning"
                                  dense
                              ></q-btn>
                            </q-item-section>
                          </q-item>


                        </q-list>
                      </q-tab-panel>
                      <q-tab-panel name="settings">
                        <q-list>
                          <q-item>
                            <q-item-section>
                              <q-select
                                  :behavior="$q.platform.is.ios === true ? 'menu' : 'default'"
                                  dense
                                  outlined
                                  clearable
                                  :options="options.phone_visibility"
                                  v-model="editedItem.guide_profile.phone_visibility"
                                  label="Видимость телефона гида"
                                  option-value="value"
                                  option-label="label"
                                  emit-value
                                  map-options
                                  hint="Для незарегистрированных пользователей"
                              >
                              </q-select>
                            </q-item-section>
                          </q-item>
                        </q-list>
                      </q-tab-panel>
                      <q-tab-panel name="paid">
                        <q-list>
                          <q-item>
                            <q-item-section>
                              <q-item-label class="q-pb-xs">Подключенные услуги: </q-item-label>
                              <q-list bordered>
                                <q-item v-ripple v-for="s in editedItem.paid_services" :key="s.id">
                                  <q-item-section>{{s.name}} до {{s.expire_at}}</q-item-section>
                                  <q-item-section avatar>
                                    <q-btn dense round @click="deleteService(s.id)" flat color="grey" icon="delete"></q-btn>
                                  </q-item-section>
                                </q-item>
                              </q-list>
                            </q-item-section>
                          </q-item>
                          <q-item>
                            <q-item-section>
                              <q-item-label class="q-pb-xs">Подключить услугу: </q-item-label>
                              <q-select
                                  :behavior="$q.platform.is.ios === true ? 'menu' : 'default'"
                                  label="Услуга"
                                  :loading="serviceLoader"
                                  dense
                                  outlined
                                  :options="options.services"
                                  v-model="template.service"
                                  @input-value="filterServices"
                                  option-value="id"
                                  :option-label="(i) => {return i.name + '['+i.period_text+']'}"
                                  use-input
                                  map-options
                                  @filter="filterFn"
                                  hint="Начните вводить название услуги"
                              >
                                <template v-slot:no-option>
                                  <q-item>
                                    <q-item-section class="text-grey">
                                      Услуга не найдена
                                    </q-item-section>
                                  </q-item>
                                </template>
                              </q-select>
                              <q-btn
                                  @click="addService(template.service.id)"
                                  flat
                                  label="Добавить"
                                  color="warning"
                                  dense
                              ></q-btn>
                            </q-item-section>
                          </q-item>


                        </q-list>
                      </q-tab-panel>
                      <q-tab-panel name="general">
                        <q-list>
                          <q-item>
                            <q-item-section>
                              <q-item-label class="q-pb-xs">Описание: </q-item-label>
                              <q-item-section>{{editedItem.guide_profile.description}}</q-item-section>
                            </q-item-section>
                          </q-item>
                          <q-item>
                            <q-item-section>
                              <q-item-label class="q-pb-xs">Спортивные достижения: </q-item-label>
                              <q-item-section>{{editedItem.guide_profile.description}}</q-item-section>
                            </q-item-section>
                          </q-item>
                          <q-item v-if="editedItem.guide_profile.fishes.length > 0">
                            <q-item-section>
                              <q-item-label class="q-pb-xs">Рыба: </q-item-label>
                              <q-item-section>{{editedItem.guide_profile.fishes.map(f => f.name).join(', ')}}</q-item-section>
                            </q-item-section>
                          </q-item>
                          <q-item v-if="editedItem.guide_profile.fishing_methods.length > 0">
                            <q-item-section>
                              <q-item-label class="q-pb-xs">Способы ловли: </q-item-label>
                              <q-item-section>{{editedItem.guide_profile.fishing_methods.map(f => f.name).join(', ')}}</q-item-section>
                            </q-item-section>
                          </q-item>
                          <q-item v-if="editedItem.guide_profile.languages.length > 0">
                            <q-item-section>
                              <q-item-label class="q-pb-xs">Языки: </q-item-label>
                              <q-item-section>{{editedItem.guide_profile.languages.map(f => f.name).join(', ')}}</q-item-section>
                            </q-item-section>
                          </q-item>
                          <q-item v-if="editedItem.guide_profile.prices.length > 0">
                            <q-item-section>
                              <q-item-label class="q-pb-xs">Цены: </q-item-label>
                              <q-item-section>{{editedItem.guide_profile.prices.map(f => f.text).join(', ')}}</q-item-section>
                            </q-item-section>
                          </q-item>
                          <q-item v-if="editedItem.guide_profile.trophies.length > 0">
                            <q-item-section>
                              <q-item-label class="q-pb-xs">Трофеи: </q-item-label>
                              <q-item-section>
                                {{editedItem.guide_profile.trophies.map(f => f.fish + ' ' + f.weight_kilo + ' кг.').join(', ')}}
                              </q-item-section>
                            </q-item-section>
                          </q-item>
                        </q-list>
                      </q-tab-panel>
                      <q-tab-panel name="media">
                        <q-card v-if="editedItem.guide_profile.preview_uploaded.medium">
                          <q-card-section>
                            Изображение в шапке:
                          </q-card-section>
                          <q-item-section>
                            <q-img :src="editedItem.guide_profile.preview_uploaded.medium"></q-img>
                          </q-item-section>
                        </q-card>
                        <div class="q-mt-md" v-if="editedItem.guide_profile.media.length > 0">
                          <q-card>
                            <q-card-section>Фото и видео:</q-card-section>
                            <q-card-section v-for="img in editedItem.guide_profile.media" :key="img.id">
                              <q-img v-if="img.crops" :src="img.crops.medium"></q-img>
                              <iframe v-else
                                      style="width: 100%; height: 320px"
                                      :src="`https://www.youtube.com/embed/${img.youtube_id}`"
                                      title="YouTube video player" frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowfullscreen>
                              </iframe>
                            </q-card-section>
                          </q-card>

                        </div>
                      </q-tab-panel>
                    </q-tab-panels>
                  </template>
                </q-splitter>

              </q-tab-panel>
            </q-tab-panels>
          </q-form>
        </q-card-section>
        <q-card-section>
          <q-card-actions align="right">
            <q-btn
              flat
              label="Отмена"
              color="warning"
              dense
              v-close-popup
            ></q-btn>
            <q-btn
              flat
              label="Сохранить"
              color="primary"
              dense
              @click="save"
            ></q-btn>
          </q-card-actions>
        </q-card-section>
      </q-card>
    </q-dialog>
</template>


<script>
import {defineComponent, ref} from "vue";
import {makeFormData} from "boot/helpers";
import AlertModal from "components/AlertModal";

export default defineComponent({
  name: "UserModal",
  components: {},
  setup () {
    return {
      errors: ref({}),
      medias: ref([]),
      filterFn (val, update, abort) {
        update(() => {})
      },
    }
  },
  mounted() {


  },
  watch: {
    show_dialog(v) {
      this.errors = {}
    }
  },
  computed: {
    modalTitle() {
      if(this.editedItem.id) return 'Редактировать';
      return 'Создать';
    }
  },
  data: () => ({
    show_dialog: false,
    editedItem: {},
    model: null,
    preview: null,
    tab: 'general',
    guideTab: 'general',
    splitter: 20,
    classifications: [],
    options: {},
    template: {
      service: null
    },
    serviceLoader: false,
    media: {
      type: 'file',
      youtube_id: null,
      file: null,
    },
  }),
  methods:{
    filterServices (val) {
      this.fetchServices(val)
    },
    async fetchServices(q){
      this.serviceLoader = true;
      await this.$api.get(`admin/reference/paid-service-search-any`, {params: {filters: {name: q}}}).then(res => {
        this.options.services = res.data;
        this.serviceLoader = false;
      }).catch(e => {
        this.serviceLoader = false;
      })
    },
    async open(options, meta) {
      if(options.id) {

      }
      this.tab = 'general'
      this.options.statuses = meta.statuses
      this.options.phone_visibility = meta.phone_visibility
      this.show_dialog = true
      this.editedItem = options
    },
    editRow(props) {
      this.show_dialog = true
      this.editedItem = props.row;
    },
    deleteService(id) {
      this.$api.delete(`admin/user/${this.editedItem.id}/service/${id}`)
          .then((res) => {
            this.editedItem.paid_services = this.editedItem.paid_services.filter((s) => {
              return s.id != id
            })
            this.$emit('update');
          })
    },
    addService(id) {
      this.$api.post(`admin/user/${this.editedItem.id}/service/${id}`)
          .then((res) => {
            this.editedItem.paid_services.push({'name' : this.template.service.name, 'id' : this.template.service.id})
            this.$emit('update');
          })
    },
    addPersonalPrice(id) {
      this.$api.post(`admin/user/${this.editedItem.id}/personal-price/${id}`, {price: this.template.price})
          .then((res) => {
            this.editedItem.personal_prices.push({'name' : this.template.service.name, 'price': this.template.price, 'id' : this.template.service.id})
            this.$emit('update');
          }).catch(e => {
        this.errors = e.response.data.errors
      });
    },
    deletePersonalPrice(id) {
      this.$api.delete(`admin/user/${this.editedItem.id}/personal-price/${id}`)
          .then((res) => {
            this.editedItem.personal_prices = this.editedItem.personal_prices.filter((s) => {
              return s.paid_service_id != id
            })
            this.$emit('update');
          })
    },
    save() {
      if(this.editedItem.id) {
        this.update()
      } else {
        this.store()
      }
    },
    async update() {
      let data = makeFormData(this.editedItem);
      data.append('_method', 'patch')
      await this.$api.post(`admin/user/${this.editedItem.id}`, data)
        .then((res) => {
          this.show_dialog = false
          this.$emit('update');
        })
        .catch(e => {
          this.errors = e.response.data.errors
        });
    },
    async delete(id) {
      await this.$api.delete(`admin/user/${id}`);
      this.$emit('update');
    },
    async restore(id) {
      await this.$api.post(`admin/user/${id}/restore`).catch(e => {
        this.$emit('alertModal', {type: 'error', title: 'Произошла ошибка', text: [e.response.data.message]})
        this.$emit('update');
      });
    }
  },
});
</script>
