<template>
  <footer class="footer">
    <div class="footer__top-wrapper">
      <div class="footer-top">
        <div class="row q-col-gutter-md">
            <div class="col-xs-12 col-sm-4">
              <router-link :to="{name: 'guides-search'}" class="footer-top__cell">
                <div class="footer-top__cell-count">{{stats.guides_count}}</div>
                <div class="footer-top__cell-title">{{stats.guides_count_plural}}</div>
              </router-link>
            </div>
            <div class="col-xs-6 col-sm-4">
              <router-link :to="{name: 'reports-page'}" class="footer-top__cell">
                <div class="footer-top__cell-count">{{stats.reports_count}}</div>
                <div class="footer-top__cell-title">{{stats.reports_count_plural}}</div>
              </router-link>
            </div>
            <div class="col-xs-6 col-sm-4">
              <a href="#" class="footer-top__cell" @click.prevent>
                <div class="footer-top__cell-count">{{stats.fishermans_count}}</div>
                <div class="footer-top__cell-title">{{stats.fishermans_count_plural}}</div>
              </a>
            </div>
        </div>
      </div>
      <div class="footer-wrapper">
        <ul v-if="false" class="footer-menu">
          <li class="footer-menu__item">
            <router-link to="/sharing">Рыбшеринг</router-link>
          </li>
          <li class="footer-menu__item">
            <router-link to="/hot-tours">Горящие туры</router-link>
          </li>
          <li class="footer-menu__item">
            <router-link to="/tournaments">Турниры</router-link>
          </li>
          <li class="footer-menu__item">
            <router-link to="/school">Школа</router-link>
          </li>
          <li class="footer-menu__item">
            <router-link to="/shop">Магазин</router-link>
          </li>
          <li class="footer-menu__item">
            <router-link to="/company">Компания</router-link>
          </li>
        </ul>

        <div class="footer-contacts">
          <a href="mailto:info@rybalkasgidom.ru" class="footer-email">info@rybalkasgidom.ru</a>

          <div class="footer-socials">
            <a @click="sendYM('target9')" href="https://t.me/rybalkasgidom" target="_blank" class="footer-social">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30.4389 12.6223L27.2702 27.566C27.0309 28.6205 26.4077 28.883 25.5219 28.3865L20.6934 24.8285L18.3639 27.0695C18.1059 27.3275 17.8907 27.5427 17.3934 27.5427L17.7407 22.6258L26.6889 14.54C27.0782 14.1935 26.6042 14.0008 26.0844 14.348L15.0219 21.314L10.2594 19.823C9.22365 19.4998 9.2049 18.7873 10.4754 18.29L29.1032 11.1133C29.9657 10.79 30.7202 11.3053 30.4389 12.623V12.6223Z" fill="white"/>
              </svg>
            </a>
            <a @click="sendYM('target9')" href="https://www.youtube.com/c/RomanBaikov" target="_blank" class="footer-social">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30.0617 14.9398C29.9422 14.4946 29.7078 14.0886 29.382 13.7624C29.0563 13.4363 28.6505 13.2014 28.2055 13.0813C26.5672 12.6406 20 12.6406 20 12.6406C20 12.6406 13.4328 12.6406 11.7945 13.0789C11.3493 13.1986 10.9433 13.4334 10.6175 13.7596C10.2917 14.0859 10.0574 14.4921 9.93828 14.9375C9.5 16.5781 9.5 20 9.5 20C9.5 20 9.5 23.4219 9.93828 25.0602C10.1797 25.9648 10.8922 26.6773 11.7945 26.9188C13.4328 27.3594 20 27.3594 20 27.3594C20 27.3594 26.5672 27.3594 28.2055 26.9188C29.1102 26.6773 29.8203 25.9648 30.0617 25.0602C30.5 23.4219 30.5 20 30.5 20C30.5 20 30.5 16.5781 30.0617 14.9398ZM17.9141 23.1406V16.8594L23.3516 19.9766L17.9141 23.1406Z" fill="white"/>
              </svg>
            </a>
<!--            <a @click="sendYM('target9')" href="#" target="_blank" class="footer-social">-->
<!--              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M19.9978 16.9987C18.3451 16.9987 16.9965 18.3474 16.9965 20C16.9965 21.6526 18.3451 23.0013 19.9978 23.0013C21.6504 23.0013 22.999 21.6526 22.999 20C22.999 18.3474 21.6504 16.9987 19.9978 16.9987ZM28.9993 20C28.9993 18.7572 29.0106 17.5256 28.9408 16.285C28.871 14.844 28.5423 13.5652 27.4886 12.5114C26.4326 11.4555 25.156 11.129 23.715 11.0592C22.4722 10.9894 21.2406 11.0007 20 11.0007C18.7572 11.0007 17.5256 10.9894 16.285 11.0592C14.844 11.129 13.5652 11.4577 12.5114 12.5114C11.4555 13.5674 11.129 14.844 11.0592 16.285C10.9894 17.5278 11.0007 18.7594 11.0007 20C11.0007 21.2406 10.9894 22.4744 11.0592 23.715C11.129 25.156 11.4577 26.4348 12.5114 27.4886C13.5674 28.5445 14.844 28.871 16.285 28.9408C17.5278 29.0106 18.7594 28.9993 20 28.9993C21.2428 28.9993 22.4744 29.0106 23.715 28.9408C25.156 28.871 26.4349 28.5423 27.4886 27.4886C28.5445 26.4326 28.871 25.156 28.9408 23.715C29.0129 22.4744 28.9993 21.2428 28.9993 20ZM19.9978 24.6179C17.4423 24.6179 15.3799 22.5555 15.3799 20C15.3799 17.4445 17.4423 15.3821 19.9978 15.3821C22.5532 15.3821 24.6156 17.4445 24.6156 20C24.6156 22.5555 22.5532 24.6179 19.9978 24.6179ZM24.8048 16.2715C24.2081 16.2715 23.7263 15.7897 23.7263 15.193C23.7263 14.5963 24.2081 14.1145 24.8048 14.1145C25.4014 14.1145 25.8832 14.5963 25.8832 15.193C25.8834 15.3347 25.8556 15.475 25.8015 15.6059C25.7474 15.7369 25.6679 15.8558 25.5678 15.956C25.4676 16.0562 25.3486 16.1356 25.2177 16.1897C25.0868 16.2439 24.9464 16.2717 24.8048 16.2715Z" fill="white"/>-->
<!--              </svg>-->
<!--            </a>-->
            <a @click="sendYM('target9')" href="https://vk.com/rybalkasgidom" target="_blank" class="footer-social">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30.4956 14.8849C30.6493 14.3744 30.4956 14 29.7688 14H27.3611C26.7485 14 26.4685 14.3239 26.3148 14.6796C26.3148 14.6796 25.0907 17.6648 23.356 19.6003C22.7961 20.1636 22.5403 20.3414 22.2351 20.3414C22.0825 20.3414 21.8607 20.1636 21.8607 19.653V14.8849C21.8607 14.2723 21.6839 14 21.1734 14H17.3901C17.008 14 16.7775 14.2833 16.7775 14.5533C16.7775 15.133 17.6448 15.267 17.7337 16.9006V20.4446C17.7337 21.2208 17.5943 21.3624 17.2869 21.3624C16.4711 21.3624 14.4862 18.3663 13.3081 14.9365C13.0787 14.2701 12.847 14.0011 12.2322 14.0011H9.82561C9.13724 14.0011 9 14.325 9 14.6807C9 15.3197 9.81573 18.4816 12.7998 22.6634C14.7892 25.5191 17.5899 27.0671 20.1414 27.0671C21.6707 27.0671 21.8596 26.7234 21.8596 26.1306V23.9721C21.8596 23.2849 22.0056 23.1465 22.4898 23.1465C22.8455 23.1465 23.4581 23.3266 24.8865 24.7022C26.5179 26.3337 26.788 27.066 27.7047 27.066H30.1113C30.7986 27.066 31.1422 26.7223 30.9446 26.0438C30.7283 25.3686 29.9488 24.386 28.9146 23.2234C28.3525 22.5602 27.5126 21.8466 27.2568 21.4898C26.9 21.0298 27.0032 20.8267 27.2568 20.4183C27.2579 20.4194 30.1904 16.2891 30.4956 14.8849Z" fill="white"/>
              </svg>
            </a>
<!--            <a @click="sendYM('target9')" href="#" target="_blank" class="footer-social">-->
<!--              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M25.625 11C26.5547 11 27.3496 11.3301 28.0098 11.9902C28.6699 12.6504 29 13.4453 29 14.375V25.625C29 26.5547 28.6699 27.3496 28.0098 28.0098C27.3496 28.6699 26.5547 29 25.625 29H23.4219V22.0273H25.7539L26.1055 19.3086H23.4219V17.5742C23.4219 17.1367 23.5137 16.8086 23.6973 16.5898C23.8809 16.3711 24.2383 16.2617 24.7695 16.2617L26.1992 16.25V13.8242C25.707 13.7539 25.0117 13.7188 24.1133 13.7188C23.0508 13.7188 22.2012 14.0312 21.5645 14.6562C20.9277 15.2812 20.6094 16.1641 20.6094 17.3047V19.3086H18.2656V22.0273H20.6094V29H14.375C13.4453 29 12.6504 28.6699 11.9902 28.0098C11.3301 27.3496 11 26.5547 11 25.625V14.375C11 13.4453 11.3301 12.6504 11.9902 11.9902C12.6504 11.3301 13.4453 11 14.375 11H25.625Z" fill="white"/>-->
<!--              </svg>-->
<!--            </a>-->
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="footer-bottom__wrapper">
          <div class="footer-copyright">© Рыбалка с гидом, 2017 — {{todayDate.getFullYear()}}</div>
          <div class="footer-documents">
            <router-link v-if="false" to="/agreement" class="footer-documents__item">Пользовательское соглашение</router-link>
            <router-link to="/privacy" class="footer-documents__item">Правовая информация</router-link>
          </div>
          <div v-if="false" class="footer-developer">Сделано в <a href="#">Maincore</a></div>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppFooter",
  data: () => {
    return {
      stats: {},
      todayDate: new Date()
    }
  },
  methods: {
    sendYM(name) {
      if (typeof ym !== undefined) {
        ym(45019072, 'reachGoal', name)
      }
    },
  },
  mounted() {
    this.$api.get('general/stats').then(res => {
      this.stats = res.data;
    })
  }
});
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.footer {
  margin-top: auto;
  background: url("../assets/images/footer/footer-bg-mobile.webp") center / cover no-repeat;

  @media #{$desktop-widths} {
    background-image: url("../assets/images/footer/footer-bg.webp");
  }
}

.footer__top-wrapper {
  @media #{$desktop-widths} {
    width: 1024px;
    max-width: 100%;
    margin: 0 auto;
    padding: 48px 38px 80px;
    display: flex;
    align-items: flex-start;
  }

  @media #{$desktop-l-widths} {
    width: 1440px;
    padding-left: 58px;
    padding-right: 58px;
  }
}

.footer-top {
  max-width: 100%;
  padding: 40px 20px;
  margin: 0 auto;

  @media #{$tablet-widths} {
    width: 768px;
    padding: 48px 26px 40px;
  }

  @media #{$desktop-widths} {
    width: auto;
    margin: 0;
    padding: 0;
    max-width: 452px;
    flex-grow: 1;
  }
}

.footer-top__cell {
  border: $border-dark;
  border-radius: $border-radius;
  padding: 16px;
  color: $color-white;
  display: block;
  text-decoration: none;
  background-color: transparent;
  @include transition(background-color);

  &:hover {
    background-color: $color-primary-400;
  }
}

.footer-top__cell-count {
  font-family: $font-heading;
  letter-spacing: 1px;
  @include font(26px, 32px);
  margin-bottom: 16px;

  @media #{$tablet-widths} {
    @include font(36px, 44px);
    margin-bottom: 60px;
  }
}

.footer-top__cell-title {
  @include font(16px, 24px);
}

.footer-wrapper {
  max-width: 100%;
  padding: 0 20px;
  margin: 0 auto;

  @media #{$tablet-widths} {
    width: 768px;
    padding: 0 26px;
  }

  @media #{$desktop-widths} {
    width: auto;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0;
    flex-grow: 1;
  }
}

.footer-menu {
  display: none;

  @media #{$tablet-widths} {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 42px;
  }

  @media #{$desktop-widths} {
    margin-bottom: 0;
    flex-direction: column;
    padding-left: 40px;
  }

  @media #{$desktop-l-widths} {
    padding-left: 80px;
    max-height: 132px;
  }
}

.footer-menu__item {
  width: calc(100% / 3);
  padding-right: 15px;
  margin-bottom: 12px;

  @media #{$desktop-widths} {
    width: auto;
  }

  @media #{$desktop-l-widths} {
    padding-right: 80px;
  }

  a {
    color: $text-color-dark-secondary;
    text-decoration: none;
    @include transition(color);

    &:hover {
      color: $color-white;
    }

  }
}

.footer-contacts {
  padding-bottom: 24px;

  @media #{$tablet-widths} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media #{$desktop-widths} {
    display: block;
    padding-left: 20px;
    margin-left: auto;
  }
}

.footer-email {
  color: $color-white;
  text-decoration: none;
  font-weight: 600;
  display: inline-block;
  @include font(20px, 28px);
  margin-bottom: 24px;

  @media #{$tablet-widths} {
    margin-bottom: 0;
    margin-right: 16px;
  }

  @media #{$desktop-widths} {
    margin-right: 0;
    margin-bottom: 24px;
    @include transition(color);

    &:hover {
      color: $text-color-dark-secondary;
    }
  }
}

.footer-socials {
  display: flex;
}

.footer-social {
  border-radius: 50%;
  background: transparent;
  @include transition(background);
  width: 40px;
  height: 40px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  & + & {
    margin-left: 30px;

    @media #{$tablet-widths} {
      margin-left: 16px;
    }
  }

  svg {
    @include size(40px);
  }
}

.footer-bottom {
  border-top: $border-dark;
  padding-top: 24px;
  padding-bottom: 24px;
}

.footer-bottom__wrapper {
  @include wrapper();

  @media #{$tablet-widths} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  @media #{$desktop-widths} {
    justify-content: flex-start;
  }
}

.footer-copyright {
  @include font(16px, 24px);
  color: $color-white;
  margin-bottom: 24px;

  @media #{$tablet-widths} {
    width: 100%;
    margin-bottom: 16px;
  }

  @media #{$desktop-widths} {
    width: auto;
    margin-right: 32px;
    margin-bottom: 0;
  }
}

.footer-documents {
  margin-bottom: 24px;

  @media #{$tablet-widths} {
    margin-bottom: 0;
    margin-right: 24px;
  }
}

.footer-documents__item {
  display: inline-block;

  @include font(16px, 24px);
  color: $text-color-dark-secondary;
  text-decoration: none;
  @include transition(color);

  &:hover {
    color: $color-white;
  }

  &:first-child {
    margin-right: 12px;
  }

  & + & {
    margin-top: 8px;

    @media #{$tablet-widths} {
      margin-left: 24px;
      margin-top: 0;
    }
  }
}

.footer-developer {
  @include font(16px, 24px);
  color: $text-color-dark-secondary;

  @media #{$desktop-widths} {
    margin-left: auto;
  }

  a {
    color: inherit;
    text-decoration: none;
    @include transition(color);

    &:hover {
      color: $color-white;
    }
  }
}

</style>
