<template>
  <div class="profile-info">
      <h2 class="profile-info__h2">Информация</h2>
      <div class="profile-info__top">
        <div v-if="!profile.languages?.length && !profile.fishing_methods?.length && !profile.fishes?.length &&
!profile.fisherman_achievements && !profile.car && !profile.gear && !profile.prices?.length && !profile.trophies?.length">
          Пользователь ничего о себе не рассказал :(
        </div>
        <div class="profile-info__item" v-if="profile.languages && profile.languages.length > 0">
          <div class="profile-info__item-title">
            Знание языков
          </div>
          <div class="profile-info__item-value">
            {{profile.languages.map(e => e.name).join(", ")}}
          </div>
        </div>
        <div class="profile-info__item" v-if="profile.fishing_methods && profile.fishing_methods.length > 0" >
          <div class="profile-info__item-title">
            Способы ловли
          </div>
          <div class="profile-info__item-value">
            {{profile.fishing_methods.map(e => e.name).join(", ")}}
          </div>
        </div>
        <div class="profile-info__item" v-if="profile.fishes && profile.fishes.length > 0">
          <div class="profile-info__item-title">
            Виды рыб
          </div>
          <div class="profile-info__item-value">
            {{profile.fishes.map(e => e.name).join(", ")}}
          </div>
        </div>
        <div class="profile-info__item" v-if="profile.fisherman_achievements">
          <div class="profile-info__item-title">
            Спортивные достижения
          </div>
          <div class="profile-info__item-value">
            {{ profile.fisherman_achievements }}
          </div>
        </div>
        <div class="profile-info__item" v-if="profile.car">
          <div class="profile-info__item-title">
            Автомобиль
          </div>
          <div class="profile-info__item-value">
            {{ profile.car }}
          </div>
        </div>
        <div class="profile-info__item" v-if="profile.gear">
          <div class="profile-info__item-title">
            Снаряжение
          </div>
          <div class="profile-info__item-value">
            {{ profile.gear }}
          </div>
        </div>
        <div class="profile-info__item" v-if="profile.prices && profile.prices.length > 0">
          <div class="profile-info__item-title">
            Расценки
          </div>
          <div class="profile-info__item-value">
            {{profile.prices.map(e => e.text).join(", ")}}
          </div>
        </div>
      </div>
      <div class="profile-info__bottom">
        <div class="profile-info__item" v-if="profile.trophies && profile.trophies.length > 0">
          <div class="profile-info__item-title">
            Лучшие трофеи
          </div>
          <div class="profile-trophies">
            <div class="profile-trophies__item" v-for="trophy in profile.trophies" :key="trophy">
              <q-icon name="o_emoji_events" />
              <div class="profile-trophies__item-name">{{trophy.fish}}</div>
              <div class="profile-trophies__item-weight">{{trophy.weight_kilo}} кг.</div>
            </div>
          </div>
        </div>

        <div class="profile-info__item">
          <div class="profile-info__item-title">
            Статистика
          </div>
          <div class="profile-statistics">
            <div class="profile-statistics__item">
              <div class="profile-statistics__item-name">Лучший трофей:</div>
              <div class="profile-statistics__item-value">{{profile.stats.best_trophy ? profile.stats.best_trophy+' кг.' : '-'}}</div>
            </div>
            <div class="profile-statistics__item">
              <div class="profile-statistics__item-name">Отзывов:</div>
              <div class="profile-statistics__item-value">{{profile.stats.reviews_count  ?? 0}}</div>
            </div>
            <div class="profile-statistics__item">
              <div class="profile-statistics__item-name">Отчетов:</div>
              <div class="profile-statistics__item-value">{{profile.stats.reports_count ?? 0}}</div>
            </div>
            <div class="profile-statistics__item" v-if="isGuide">
              <div class="profile-statistics__item-name">Заходов на страницу:</div>
              <div class="profile-statistics__item-value">{{stats.profile ?? 0}}</div>
            </div>
            <div class="profile-statistics__item" v-if="isGuide">
              <div class="profile-statistics__item-name">Нажатий по кнопке "Показать телефон":</div>
              <div class="profile-statistics__item-value">{{stats.phone ?? 0}}</div>
            </div>
            <div class="profile-statistics__item" v-if="isGuide">
              <div class="profile-statistics__item-name">Бронирований предложений:</div>
              <div class="profile-statistics__item-value">{{profile.stats.booking_count ?? 0}}</div>
            </div>
            <div class="profile-statistics__item" v-if="isGuide">
              <div class="profile-statistics__item-name">Бронирований туров:</div>
              <div class="profile-statistics__item-value">{{stats.tour ?? 0}}</div>
            </div>
            <div class="profile-statistics__item" v-if="isGuide">
              <div class="profile-statistics__item-name">Бронирований:</div>
              <div class="profile-statistics__item-value">{{stats.booking ?? 0}}</div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "ProfileInfo",
  props: [
      'profile',
      'isGuide'
  ],
  data: () => {
    return {
      stats: {}
    }
  },
  mounted() {
    if(this.isGuide) {
      this.$api.get('guide/profile/stats').then(res => {
        this.stats = res.data
      })
    }
  }
});
</script>

<style lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.profile-info {
  margin-bottom: 20px;

  @media #{$tablet-widths} {
    margin-bottom: 48px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  @media #{$desktop-widths} {
    margin-bottom: 36px;
  }
}

.profile-info__h2 {
  margin-bottom: 24px;

  @media #{$tablet-widths} {
    width: 100%;
    margin-bottom: 32px;
  }
}

.profile-info__top {
  margin-bottom: 32px;

  @media #{$tablet-widths} {
    width: calc(50% - 28px);
    margin-right: 56px;
    margin-bottom: 0;
  }

  @media #{$desktop-widths} {
    width: calc(100% - 410px);
    margin-right: 98px;
  }
}

.profile-info__bottom {
  @media #{$tablet-widths} {
    width: calc(50% - 28px);
  }

  @media #{$desktop-widths} {
    width: 312px;
  }
}

.profile-info__item {
  @include font(16px, 24px);

  & + & {
    margin-top: 20px;
  }
}

.profile-info__item-title {
  margin-bottom: 4px;
  color: $text-color-primary;
  font-weight: 600;
}

.profile-info__item-value {
  color: $text-color-secondary;
}

.profile-trophies {
  margin-bottom: 20px;
}

.profile-trophies__item {
  padding: 12px 0;
  display: flex;
  align-items: center;
  @include font(16px, 24px);
  color: $text-color-primary;

  & + & {
    border-top: $border;
  }

  i {
    @include font(24px);
    color: $color-primary-500;
    margin-right: 16px;
  }
}

.profile-trophies__item-name {
  padding-right: 16px;
}

.profile-trophies__item-weight {
  font-weight: 600;
  margin-left: auto;
}

.profile-statistics__item {
  @include font(16px, 24px);
  padding: 12px 0;
  display: flex;
  align-items: center;

  & + & {
    border-top: $border;
  }
}

.profile-statistics__item-name {
  color: $text-color-tertiary;
  padding-right: 16px;
}

.profile-statistics__item-value {
  font-weight: 600;
  color: $text-color-primary;
  margin-left: auto;
}
</style>
