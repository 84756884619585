<template>
  <q-dialog v-model="show" @hide="emit('hide')">
    <div class="popup popup-subscription">
      <div class="popup-close">
        <q-icon name="close" v-close-popup/>
      </div>
      <div class="popup-content">
        <div class="popup-subscription__icon" :class="props.color">
          <q-icon :name="props.icon"/>
        </div>
        <h3 class="popup-subscription__text">{{props.text}}</h3>
        <slot name="actions" v-if="slots.actions"></slot>
        <q-btn v-else :ripple="false" class="btn btn-primary btn-m" label="ок" v-close-popup/>
      </div>
    </div>
  </q-dialog>
</template>

<script setup>
import {computed, useSlots} from "vue";

const slots = useSlots();
const emit = defineEmits(['update:model-value', 'hide'])
const props = defineProps({
  modelValue: {type: Boolean, default: false},
  text: {type: String, default: ''},
  icon: {type: String, default: 'check'},
  color: {type: String, default: 'success'}
})

const show = computed({
  get() {return props.modelValue},
  set(value) {
    emit('update:model-value', value)
  }
})
</script>

<style scoped lang="scss">
@import "src/css/variables";
@import "src/css/mixins";

.popup-subscription {
  width: 428px;
  max-width: 100%;

  .popup-content {
    display: flex;
    flex-direction: column;
    .btn {
      margin-top: 32px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
}

.popup-subscription__icon {
  margin: 0 auto 32px;
  @include size(64px);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;

  &.success {
    background: $color-success;
  }

  &.error {
    background: $color-error;
  }

  i {
    @include font(24px);
  }
}

.popup-subscription__text {
  text-align: center;
}

.popup-subscription__small-text {
  margin-top: 12px;
  text-align: center;
  color: $text-color-secondary;
  @include font(16px,24px);

  + .btn {
    margin-top: 20px;
  }
}
</style>
