<template>
  <router-link v-if="card" :to="{name: !isFisherman ? 'profile' : 'client-profile', params: {idOrSlug: card.user.username ? card.user.username : card.user.id}}" class="guide-card" :class="{ horizontal: isHorizontal }">

    <div class="guide-card__photo-wrapper">
      <img
        v-if="card.user.preview"
        class="guide-card__photo"
        :src="card.user.preview" width="278" height="208" loading="lazy"
      >
      <img v-else class="guide-card__photo" src="../assets/images/guide/guide-card-photo.webp" alt="default photo">
      <div class="guide-card__check" v-if="isChecked">
        <q-icon name="check"></q-icon>
        <span>Проверенный</span>
      </div>
    </div>

    <div class="guide-card__content">
      <div class="guide-card__info">
        <div class="guide-card__name">{{card.user.full_name}}</div>
        <div v-if="card?.prices" class="guide-card__text">
          <span v-if="card.prices[0]">{{card.prices[0]['text']}}</span>
          <span v-if="!card.prices[0]"><br></span>
        </div>
        <div class="guide-card__text location">
          <span v-if="card.user.location">{{card.user.location}}</span>
          <span v-if="!card.user.location">
            <br>
            <br>
          </span>
        </div>
      </div>
      <div v-if="card.stats" class="guide-card__bottom">
        <router-link :to="{name: 'profile', params: {idOrSlug: card.user.username ? card.user.username : card.user.id}}" class="tag">
          <q-tooltip class="tooltip" anchor="top middle" self="bottom middle" :offset="[0, 4]">
            Рейтинг
          </q-tooltip>
          <q-icon name="o_star_border" />
          <span>{{ card.stats.rating }}</span>
        </router-link>
        <router-link  :to="{name: 'profile', params: {idOrSlug: card.user.username ? card.user.username : card.user.id}}" class="tag">
          <q-tooltip class="tooltip" anchor="top middle" self="bottom middle" :offset="[0, 4]">
            Отзывы
          </q-tooltip>
          <q-icon name="o_textsms" />
          <span>{{ card.stats.reviews_count }}</span>
        </router-link>
        <router-link  :to="{name: 'profile', params: {idOrSlug: card.user.username ? card.user.username : card.user.id}}" class="tag" v-if="isChecked">
          <q-tooltip class="tooltip" anchor="top middle" self="bottom middle" :offset="[0, 4]">
            Водоёмы
          </q-tooltip>
          <q-icon name="o_place" />
          <span>+{{ card.stats.waters_count }}</span>
        </router-link>
      </div>
    </div>
  </router-link>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "GuideCard",
  props: {
    isChecked: {
      type: Boolean,
    },
    isHorizontal: {
      type: String,
    },
    isFisherman: {
      type: Boolean,
      default: false,
    },
    card: {
      type: Object,
    },
  }
});
</script>

<style lang="scss">
  @import "src/css/variables";
  @import "src/css/mixins";

  .guide-card {
    width: 280px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    border: $border;
    border-radius: $border-radius;
    overflow: hidden;
    box-shadow: 0 0 0 transparent;
    position: relative;
    @include transition(box-shadow);
    text-decoration: none;
    background: $color-white;

    @media #{$desktop-widths} {
      &:hover {
        box-shadow: $shadow-darker;
      }
    }

    @media #{$desktop-l-widths} {
      width: 316px;
    }

    &.horizontal {
      padding: 16px;
      border-radius: 0;
      border-width: 0 0 1px 0;

      @media #{$tablet-widths} {
        flex-direction: row;
        align-items: flex-start;
        width: 520px;
        max-width: 100%;
      }

      @media #{$desktop-widths} {
        &:hover {
          .guide-card__name {
            color: $color-primary-500;
          }
        }
      }

      .guide-card__name {
        @media #{$tablet-widths} {
          font-family: $font;
          @include font(16px, 24px);
          margin-bottom: 12px;
          font-weight: 600;
          text-transform: none;
          letter-spacing: initial;
        }
      }

      .guide-card__photo-wrapper {
        @media #{$tablet-widths} {
          width: 221px;
          flex: 0 0 221px;
        }
      }

      .guide-card__photo {
        border-radius: $border-radius;

        @media #{$tablet-widths} {
          width: 221px;
        }
      }

      .guide-card__content {
        @media #{$tablet-widths} {
          padding-left: 16px;
        }
      }

      .guide-card__info {
        @media #{$tablet-widths} {
          padding: 0;
        }
      }

      .guide-card__bottom {
        @media #{$tablet-widths} {
          padding: 22px 0 0 0;
        }
      }
    }
  }

  .guide-card__check {
    position: absolute;
    bottom: 0;
    width: 132px;
    background: $color-primary-500;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 8px;
    color: $color-white;
    display: flex;
    align-items: center;

    i {
      @include font(20px);
      margin-right: 4px;
    }

    span {
      @include font(12px);
    }
  }

  .guide-card__photo-wrapper {
    position: relative;
    width: 100%;
  }

  .guide-card__photo {
    width: 100%;
  }

  .guide-card__info {
    padding: 16px;
  }

  .guide-card__name {
    font-family: $font-heading;
    @include font(22px, 28px);
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $text-color-primary;
    margin-bottom: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media #{$tablet-widths} {
      @include font(26px, 32px);
      margin-bottom: 16px;
    }
  }

  .guide-card__text {
    color: $text-color-secondary;
    @include font(16px, 24px);

    & + & {
      margin-top: 4px;
    }

    &.location {
      height: 48px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .guide-card__bottom {
    padding: 6px 16px 16px;
    display: flex;

    @media #{$tablet-widths} {
      padding-top: 10px;
    }
  }
</style>
